import { useEffect, useState } from "react"
import { Badge, Button, Col, Form, Modal, Row } from "react-bootstrap"
import { BsLayoutThreeColumns } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { modificarEsquema } from '../../redux/actions/esquemaTablas'
import { ReactSortable } from "react-sortablejs";
import { Esquema } from "../../lib/esquemas/tablas"

const PersonalizarColumnas = (props) => {
    const {
        columns,
        screen
    } = props
    const esquema_tablas = useSelector(state => state.esquema_tablas)
    const [ esquemaInicial, setEsquemaInicial ] = useState("")
    const comparar = screen === "ordenes" 
    ? [...Esquema.ORDENES.map(e => ({...e, active: false})), ...(Array.isArray(columns) ? columns.map(e => ({...e, active: true})) : [])]
    : [];
    const actuales = esquema_tablas[screen] ? esquema_tablas[screen] : Esquema.ORDENES
    const [esquemaState, setEsquemaState] = useState([
        ...actuales, 
        ...comparar.filter(e => !actuales.map(sh => sh.key || sh.slug).includes(e.key || e.slug))
    ]);
    const [ pendingSave, setPendingSave ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [buscador, setBuscador] = useState('');
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()

    useEffect(() => {
        const comparar = screen === "ordenes" 
            ? [...Esquema.ORDENES.map(e => ({...e, active: false})), ...(Array.isArray(columns) ? columns.map(e => ({...e, active: true})) : [])]
            : [];
        
        const actuales = esquema_tablas[screen];
        const esquemaInicial = [
            ...actuales, 
            ...comparar.filter(e => !actuales.map(sh => sh.key || sh.slug).includes(e.key || e.slug))
        ];
        
        setEsquemaInicial(esquemaInicial);
        setEsquemaState(esquemaInicial);
    }, []);
    
    const handleClose = () => {
        setBuscador('');
        setOpenModal(false);
    }
    
    
    const handleChange = (e, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(esquema_tablas) )
        const i = instancia_esquema[screen].findIndex(e => (e.key || e.slug) === (column.key || column.slug));
        if(i > -1){
            instancia_esquema[screen][i].active = e.target.checked
        } else {
            instancia_esquema[screen].push({
                ...column,
                active: e.target.checked
            })
        }
        return dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
    }
    
    const refrescarOrden = () => {
        setPendingSave(false);
        const ordenNuevo = esquemaState.map(e => {
            const pos = esquema_tablas[screen].findIndex(info => (info.key || info.slug) === (e.key || e.slug));
            if (pos > -1) {
                e.active = esquema_tablas[screen][pos].active === true ? true : false;
            }
            return e;
        });
        const instancia_esquema = JSON.parse(JSON.stringify(esquema_tablas));
        instancia_esquema[screen] = ordenNuevo;
        setEsquemaInicial(ordenNuevo);
        dispatch(modificarEsquema(instancia_esquema, session.tokenSession));
        setOpenModal(false);
    }
    
    const handleSort = (newList) => {
        setEsquemaState(newList);
        setPendingSave(true);
    }

    const filteredColumns = esquemaState.filter(column => 
        (column.label && column.label.toLowerCase().includes(buscador.toLowerCase())) || 
        (column.nombre && column.nombre.toLowerCase().includes(buscador.toLowerCase()))
    );
    
    return (
        <div>
            <Button size="sm" className='w-100' variant='light' onClick={() => setOpenModal(true)} >
                <BsLayoutThreeColumns /> COLUMNAS
            </Button>
            <Modal show={openModal} onHide={handleClose} centered>
                <Modal.Header closeButton className="pb-0">
                    <div>
                        <Modal.Title>Personaliza las columnas que deseas visualizar</Modal.Title>
                        <p className="mb-0">Puedes mover los elementos para cambiar su posicion</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control 
                        type="text" 
                        placeholder="Buscar columnas..." 
                        value={buscador} 
                        onChange={(e) => setBuscador(e.target.value)} 
                        className="mb-3"
                    />
                    <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
                        <div className="d-flex justify-content-end mb-1 pr-3">
                        {JSON.stringify(esquemaInicial) !== JSON.stringify(esquemaState) ? 
                            <Badge variant="warning">Guarda tus cambios</Badge> : 
                            <Badge variant='success'>Guardado</Badge>
                        }
                        </div>
                        <ReactSortable list={esquemaState} setList={(d) => handleSort(d)}>
                        {filteredColumns.map((column, i) => {
                            let checked = false;
                            const pos = esquema_tablas[screen].findIndex(e => (e.key || e.slug) === (column.key || column.slug));
                            if (pos > -1) {
                                checked = esquema_tablas[screen][pos].active === true ? true : false;
                            } else {
                                const compararIndex = comparar.findIndex(e => (e.key || e.slug) === (column.key || column.slug));
                                if (compararIndex > -1) {
                                    checked = comparar[compararIndex].active === true ? true : false;
                                }
                            }
                            return (
                                <Col key={`column-${i}`} className="mb-3">
                                    <Form.Group className="mb-0" controlId={column.key || column.slug}>
                                        <div className="handle hover d-flex bg-light font align-items-center border px-2 shadow-sm" style={{fontSize:15}}>
                                            <i className="fa-solid fa-arrows-up-down-left-right mr-1 text-secondary"></i>
                                            <Form.Check 
                                                type="switch" 
                                                checked={checked} 
                                                name={column.key ? column.key : column.slug} 
                                                label={column.label ? column.label : column.nombre} 
                                                onChange={(e) => handleChange(e, column)} 
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            );
                        })}
                    </ReactSortable>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button variant="success" className="mt-3 w-100" onClick={() => refrescarOrden()}>GUARDAR</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default PersonalizarColumnas