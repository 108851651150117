import { useDispatch, useSelector } from "react-redux"
import { urlapi } from "../../../lib/backend/data"
import { useCallback, useEffect, useState } from "react"
import { toast } from "react-toastify"
import Skeleton from "react-loading-skeleton"
import { cerrarSesion } from "../../../redux/actions/session"
import { Button, ButtonGroup, Col, Row, Spinner, Table } from "react-bootstrap"
import { debounce } from "@mui/material"
import { FaCheckCircle, FaRegClock } from "react-icons/fa"
import { MdUpdateDisabled } from "react-icons/md"
import { GrUpdate } from "react-icons/gr"
import PedidosYaActivar from "./pedidosya"
import UberActivar from "./uber"
import ErrorBoundary from "../../errorsHandler/errorBoundary"
import { url_images } from "../../../lib/global/data"
import RappiActivar from "./rappi"
import DinamicCourrierActivar from "./dinamic_courrier"
import { default_proveedores } from "../../proveedores_transporte/data"
import FloatingProgressBar from "../../general/floatingProgressBar"

const DespachoOrden = (props) => {
    const [ error, setError ] = useState(null)
    const { viewType, id_orden } = props
    const [ campos, setCampos ] = useState([])
    const [ guardado, setGuardado ] = useState(true)
    const [ loadingPicking, setLoadingPicking ] = useState(false)
    const [ loading, setLoading ] = useState(true)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)

    const confirmar = (e) => {
        let logo = ''
        if(e === "uber"){
            logo = `${url_images}/uber.png`
        } else if(e === "pedidosya"){
            logo = `${url_images}/pedidos-ya.png`
        } else if(e === "rappi"){
        logo = `${url_images}/rappi.png`
        }
        if(!logo) return 
        if(props.onConfirm) props.onConfirm({ proveedor_logo: logo })
    }

    const renderizarCourriersDinamicos = () => {
       return default_proveedores.map(e => {
        if(["pedidosya"].includes(e.slug)) return null
            return  <div className="mb-2"><DinamicCourrierActivar courrier={e.slug} logo={e.logo} autoQuote={true} typeView="line" id_orden={id_orden} onConfirm={() => confirmar(e.slug)} /></div>
        })
    }
    
    const mostrarCampos = () => {
        let titulo_boton = loadingPicking ? "GUARDANDO...": "GUARDAR"
        if(guardado) titulo_boton = "GUARDADO"
        return <div>
            <div className="mb-2"><PedidosYaActivar autoQuote={true} typeView="line" id_orden={id_orden} onConfirm={() => confirmar("pedidosya")} /></div>
            <div className="mb-2"><UberActivar autoQuote={true} typeView="line" id_orden={id_orden} onConfirm={() => confirmar("uber")} /></div>
            <div className="mb-2"><RappiActivar autoQuote={true} typeView="line" id_orden={id_orden} onConfirm={() => confirmar("rappi")}/></div>
            {renderizarCourriersDinamicos()}     
        </div>   
    }

    const isGuardando = loadingGuardando || loadingPicking
    
    return <div>
        <ErrorBoundary message={error} />
        <FloatingProgressBar isVisible={isGuardando} />
        {mostrarCampos()}
    </div>
}

export default DespachoOrden