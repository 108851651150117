import { useState } from "react"
import { Button, Card, Col, FormControl, InputGroup, Row, Modal, Dropdown } from "react-bootstrap"
import ReactGoogleAutocomplete from "react-google-autocomplete"
import { FaAngleRight, FaBox, FaBoxOpen, FaPlus, FaPlusCircle, FaRegEnvelope } from "react-icons/fa"
import { maps_key, tipos_direccion } from "../../../lib/backend/data"
import { FaWeightScale } from "react-icons/fa6"
import { CiRuler } from "react-icons/ci"
import ModalProductos from "../../productos/modal_productos"
import { toast } from "react-toastify"
import { calcularPesoVolumetrico } from "../../../lib/helpers/pedidos/pedidos"
import { MdOutlinePlace } from 'react-icons/md';
import SelectorBodega from "../../bodega/bodegas/selector_bodega"
import { obtenerGeoDatosGoogle } from '../../../lib/helpers/pedidos/pedidos';
import Form from 'react-bootstrap/Form';
import { FiBox } from "react-icons/fi"
import TiposServicioSelectorPersonalizado from "../../ordenes/tipos_servicio/selector_personalizado"
import { BsFillTrashFill } from "react-icons/bs"
import CajasListado from "../../cajas/list"
import { useDispatch, useSelector } from "react-redux";
import data from '../../../lib/backend/data'


const BoxOrdenImportacion = (props) => {
    const {
        order,
        typeForm
    } = props
    const [ pedido, setPedido ] = useState(order)
    const [ showModal, setShowModal ] = useState(false)
    const [ adicionalOptions, setAdicionalOptions ] = useState([{value: "personalizado", label: "personalizado"}])
    const [ showModalNombreCaja , setShowModalNombreCaja ] = useState(false)
    const [ nuevaCajaProducto, setNuevaCajaProducto ] = useState({})
    const [ indiceParaCreacion , setIndiceParaCreacion ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    
    const producto_default = {
        description: "",
        codigo_item: "",
        quantity: "",
        price: "",
        ancho: "",
        alto: "",
        largo: "",
        peso: "",
        _id: ""
    }
    
    if(!pedido) return false

    
    const eliminarProducto = (i) => {
        return setPedido(prev => {
            let actual = {...prev}
            actual.productos.splice(i,1)
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }
    const handleChangeProducto = (e,i) => {
        const { name, value } = e.target
        return setPedido(prev => {
            let actual = {...prev}
            actual.productos[i][name] = parseInt(value)
            actual.productos[i].nombre = "personalizado"
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }
    
    const agregarProducto = (nuevo) => {

        return setPedido(prev => {
            let actual = {...prev}
            if(!actual.productos) actual.productos = []
            const producto_nuevo = nuevo ? nuevo : producto_default
            if(producto_nuevo.codigo_item){
                const i = actual.productos.findIndex(p => p.codigo_item === producto_nuevo.codigo_item)
                if(i > -1 ){
                    toast.error("Producto ya existe")
                } else {
                    actual.productos.unshift(producto_nuevo)
                }
            } else {
                actual.productos.unshift(producto_nuevo)
            }
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }
    
    const handleChange = (e,i) => {
        const { name, value } = e.target
        return setPedido(prev => {
            let actual = {...prev}
            actual[name] = value
            if(props.onChange) props.onChange(actual)
            return {...{}, ...actual}
        })
    }

    const handleChangeOrigen = (data) => {
        if(props.onChangeOrigen) props.onChangeOrigen(data)
        const geo_informacion = obtenerGeoDatosGoogle(data ? data.geodata : {} )

        pedido.orden = {}
        

            let importar_datos = {
                level1: geo_informacion.level1 ?  geo_informacion.level1 : "",
                level2: geo_informacion.level2 ?  geo_informacion.level2 : "",
                pais: geo_informacion.pais ? geo_informacion.pais : "",
                level3: geo_informacion.level3 ? geo_informacion.level3 : "",
                address_1: data.direccion ? data.direccion : "",
            }

            let origen_datos = {
                address_1: data.direccion ? data.direccion : "",
                first_name: data.titulo ? data.titulo : "",
                ...geo_informacion
            }
            
            if(typeof data._id === "string"){
                importar_datos = {
                    ...importar_datos,
                    _id: data._id, 
                    tipo_lugar: "bodega",
                    first_name: data.titulo ? data.titulo : ""
                } 
            }

            if(data.location){
                if(typeof data.location === "object"){
                    if(data.location.coordinates){
                        if(data.location.coordinates.length > 1){
                            pedido.location = data.location
                            importar_datos.lat = data.location.coordinates[1]
                            importar_datos.lng = data.location.coordinates[0]
                        }
                    }
                }
                
            } 
        
        return setPedido(prev => {
            let actual = {...prev}
            actual.geo_datos_origen = importar_datos
            actual.origen = origen_datos
            if(props.onChange) props.onChange(actual)
            return actual
        })                         
    }

    const formularioAvanzadoProducto = () => {
        if(!pedido) return false
        if(!pedido.productos) return false
        if(!Array.isArray(pedido.productos)) return false

        return <div>
            <hr />
            <h5><FaBox /> {pedido.productos.length} Productos</h5>
            <Button size="sm" className="mb-3" variant="light" onClick={() => agregarProducto()}><FaPlusCircle /> AÑADIR NUEVO</Button>
            <ModalProductos onSelected={(data) => agregarProducto(data)} />
            {
                pedido.productos.map((producto,i) => {
                    return <div key={`pe-${pedido.referencia}-prod-${i}`}>
                        <Card  className="shadow mb-3">
                            <div className="p-3">
                            <Row>
                            {/* <Col className="mb-3" md={12}>
                                <label className="form-control-label d-block">Descripción</label>
                                <input className="form-control shadow-sm"   name="description"  onChange={(e) => handleChangeProducto(e,i)}     value={producto.description} /></Col> */}
                            {/* <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Código producto</label>
                                <input className="form-control shadow-sm"   name="codigo_item"  onChange={(e) => handleChangeProducto(e,i)}     value={producto.codigo_item} /></Col> */}
                            <Col md={4}>
                                <h4 className="mb-0" style={{ fontWeight: 700 }}>Descripción</h4>
                                <Row>
                                    <Col className="mb-3" md={4}>
                                        <label className="form-control-label d-block">Tipo de bulto</label>
                                        <select className="form-control d-block">
                                            <option value="caja">Caja</option>
                                            <option value="sobre">Sobre</option>
                                            <option value="paquete">Paquete</option>
                                            <option value="otro">Otro</option>
                                        </select>
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                        <label className="form-control-label d-block">Valor</label>
                                        <input className="form-control shadow-sm"   name="precio"     onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.precio} />
                                    </Col>
                                    <Col className="mb-3" md={4}>
                                        <label className="form-control-label d-block">Cantidad</label>
                                        <input className="form-control shadow-sm"   name="quantity"     onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.quantity} />
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Precio</label>
                                <input className="form-control shadow-sm"   name="price"        onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.price} /></Col> */}
                            <Col md={6}>
                                <h4 className="mb-0" style={{ fontWeight: 700 }}>Tamaño</h4>
                                <Row>
                                <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Ancho</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>CM</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="ancho" onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.ancho} />
                                </InputGroup>
                            </Col>
                            <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Alto</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>CM</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="alto" onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.alto} />
                                </InputGroup>
                            </Col>
                            <Col className="mb-3" md={4}>
                                <label className="form-control-label d-block">Largo</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>CM</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="largo" onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.largo} />
                                </InputGroup>
                            </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <h4 className="mb-0" style={{ fontWeight: 700 }}>Peso</h4>
                                <label className="form-control-label d-block">Peso</label>
                                <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>KG</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="peso" onChange={(e) => handleChangeProducto(e,i)}   type="number" value={producto.peso} />
                                </InputGroup>
                            </Col>
                            
                        </Row>
                            {pedido.productos.length > 1 ? <Button size="sm" variant="link" className="text-danger" onClick={() => eliminarProducto(i)} >ELIMINAR</Button> : false }
                            </div>
                        </Card>
                        
                    </div>
                })
            }
        </div>
    }

    const abrirModalMantenedor = () => {
        return setShowModal(!showModal)
        
    }

    const mostrarMantenedorDeCajas = () => {
        return <Button size="sm" className="mb-3" variant="light" onClick={() => abrirModalMantenedor()}><FaPlusCircle /> AÑADIR NUEVAS CAJAS</Button>
    }

    const mostrarEmailsAdicionales = () => {
        if(!pedido) return false
        if(!pedido.emails_adicionales) return false
        if(!Array.isArray(pedido.emails_adicionales)) return false

        return <div>
            <hr />
            <h5 style={{fontWeight:700}}><FaRegEnvelope style={{ verticalAlign: "middle"}} /> Notificaciones ({pedido.emails_adicionales.length} {pedido.emails_adicionales.length === 1 ? 'Email' : 'Emails'})</h5>
            {
                pedido.emails_adicionales.map((email,i) => <p className="mb-1" key={`${pedido.referencia}-${i}`}><FaAngleRight style={{ verticalAlign: "middle"}} /> {email}</p>)
            }
        </div>
    }

    const crearCajaDesdeProducto = (i) => {
        setShowModalNombreCaja(true)
        setIndiceParaCreacion(i)
    }

    const mostrarGuardarTamano = (pro, i) => {
        if(!pro.ancho || !pro.alto || !pro.largo || !pro.peso) return false
        return <div>
            <Dropdown style={{ width: "fit-content" }} >
            <Dropdown.Toggle variant="link" size="sm" ><FiBox /> GUARDAR TAMAÑO DE CAJA</Dropdown.Toggle>
            <Dropdown.Menu className="p-2">
            <input type="text" name="nombre" placeholder="Nombre de la caja" onChange={(e) => escribirNombreCaja(e,i)} className="form-control w-100 mb-3"/>
            <Button size="sm" className="w-100" variant="success" disabled={loading} onClick={() => enviarDatosCrearCaja(i)} > {loading ? "GUARDANDO..." : "GUARDAR"} </Button>
            </Dropdown.Menu>
        </Dropdown>
        </div>
    }

    const mostrarProductos = () => {
        if(!pedido) return false
        if(!pedido.productos) return false
        if(!Array.isArray(pedido.productos)) return false

        if(typeForm === "advance"){
            return formularioAvanzadoProducto()
        }

        return <div>
            <hr />
            <h5 style={{fontWeight:700}}><FaBoxOpen style={{ verticalAlign: "middle"}} /> Detalle de la carga ({pedido.productos.length} {pedido.productos.length === 1 ? 'Producto' : 'Productos'})</h5>
            <Button size="sm" className="mb-3 mr-3" variant="light" onClick={() => agregarProducto()}><FaPlusCircle /> AÑADIR NUEVO PRODUCTO</Button>
            {/* <ModalProductos onSelected={(data) => agregarProducto(data)} /> */}
            {/* {mostrarMantenedorDeCajas()} */}
            {
                pedido.productos.map((producto,i) => {
                    return <div key={`pe-${pedido.referencia}-prod-${i}`}>
                        {/* <Card className=" mb-3"> */}
                            <div>
                            <Row>
                            <Col md={8} >
                                <h6 className="my-2" style={{fontWeight:700}}> Tamaño
                                    {pedido.productos.length > 1 ? <BsFillTrashFill className="text-danger" onClick={() => eliminarProducto(i)} /> : false }  </h6>
                                <Row>
                             <Col  md={3}>       
                                <TiposServicioSelectorPersonalizado value={{label: producto.nombre, value: producto.nombre}} adicional_options={adicionalOptions} full_payload={true} isMulti={false} condicion_default={{ tipo: "logistica", sub_tipo: "cajas" }} onChangeValue={(val) => handleChangeSelectSpecial(val, i)} />   
                                {/* { producto.nombre === "personalizado" ? <div> <Button size="sm" className="w-100" variant="link" style={{ fontSize: 11 }} onClick={() => crearCajaDesdeProducto(i)}><FaPlusCircle /> GUARDAR TAMAÑO</Button></div> : false} */}
                            </Col>
                                <Col md={3}>       
                                <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text style={{ fontSize: 9 }} >Ancho</InputGroup.Text>
                                </InputGroup.Prepend >
                                    <Form.Control name="ancho"  onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.ancho}  />
                                <InputGroup.Prepend>    
                                    <InputGroup.Text style={{ fontSize: 11 }} >cm</InputGroup.Text>
                                </InputGroup.Prepend>
                                </InputGroup>
                            </Col>
                            <Col md={3}>
                                {/* <label className="form-control-label d-block">Alto</label> */}
                                <InputGroup size="sm">
                                <InputGroup.Prepend>
                                    <InputGroup.Text style={{ fontSize: 11 }} >Alto</InputGroup.Text>
                                </InputGroup.Prepend>
                                    <Form.Control name="alto" onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.alto} />
                                <InputGroup.Prepend>    
                                    <InputGroup.Text style={{ fontSize: 11 }} >cm</InputGroup.Text>
                                </InputGroup.Prepend>
                                </InputGroup>
                                {/* <InputGroup>
                                    <InputGroup.Prepend>
                                    <InputGroup.Text>CM</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl name="alto" onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.alto} />
                                </InputGroup> */}
                            </Col>
                            <Col md={3}>
                                {/* <label className="form-control-label d-block">Largo</label> */}
                                <InputGroup size="sm" >
                                <InputGroup.Prepend>
                                    <InputGroup.Text style={{ fontSize: 11 }} >Largo</InputGroup.Text>
                                </InputGroup.Prepend>
                                    <Form.Control name="largo" onChange={(e) => handleChangeProducto(e,i)}     type="number" value={producto.largo}  />
                                <InputGroup.Prepend>    
                                    <InputGroup.Text style={{ fontSize: 11 }} >cm</InputGroup.Text>
                                </InputGroup.Prepend>
                                </InputGroup>
                            </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <h6 className="my-2" style={{fontWeight:700}}><FiBox /> Cantidad</h6>
                                <InputGroup size="sm">
                                    <Form.Control name="quantity" onChange={(e) => handleChangeProducto(e,i)}   type="number" value={producto.quantity} />
                                </InputGroup>
                            </Col>
                            <Col md={2}>
                                <h6 className="my-2" style={{fontWeight:700}}><i className="fa-solid fa-weight-hanging"></i> Peso</h6>
                                {/* <label className="form-control-label d-block">Peso</label> */}
                                <InputGroup size="sm" >
                                    <Form.Control name="peso" onChange={(e) => handleChangeProducto(e,i)}   type="number" value={producto.peso} />
                                <InputGroup.Prepend>    
                                    <InputGroup.Text style={{ fontSize: 11 }}>Kg</InputGroup.Text>
                                </InputGroup.Prepend>
                                </InputGroup>
                            </Col>    
                            <Col xs={12}>{mostrarGuardarTamano(producto,i)}</Col>                        
                        </Row>
                        <hr></hr>
                            </div>                        
                    </div>
                })
            }
        </div>
    }

    const handleCloseModalNombre = () => {
       return setShowModalNombreCaja(false)
    }

    
    const handleCloseModalMantenedor = () => {
        return setShowModal(false)
     }

    const escribirNombreCaja = (e, i) => {
        const seleccionado = pedido.productos[i] ? pedido.productos[i] : producto_default

        const { value } = e.target
        setNuevaCajaProducto(prev => {
            let actual = prev
            actual = seleccionado
            actual.valor = value
            return actual
        })
    }

    
    const crearNuevaCaja = async (nuevoProveedor) => {
        const requeridos = [
            { value: 'valor', label: 'Nombre'},
            { value: 'ancho', label: 'Ancho' },
            { value: 'alto', label: 'Alto' },
            { value: 'largo', label: 'Largo' },
            { value: 'peso', label: 'Peso' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value])  faltantes.push(campo.label)
                return true
            })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoading(true)
        return fetch(`${data.urlapi}/configuracion/cajas`, {
            method: 'POST',
            body: JSON.stringify(
                {tipo: "logistica",
                 sub_tipo: "cajas",
                 valor: nuevoProveedor.valor,
                detalles: {
                    alto: nuevoProveedor.alto,
                    largo: nuevoProveedor.largo,
                    ancho: nuevoProveedor.ancho,
                    peso: nuevoProveedor.peso
                }}),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const enviarDatosCrearCaja = async () => {
        if(nuevaCajaProducto._id) delete nuevaCajaProducto._id
        await crearNuevaCaja(nuevaCajaProducto)
        adicionalOptions.unshift({...nuevaCajaProducto, label: nuevaCajaProducto.valor, value: nuevaCajaProducto.valor  })
        setAdicionalOptions(adicionalOptions)
        setShowModalNombreCaja(false)
    }

    // const modalNombreCajas = () => {
    //     return <Modal show={showModalNombreCaja}  size="s" onHide={handleCloseModalNombre} centered >
    //     <Modal.Header closeButton>
    //     <Modal.Title></Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //         <div style={{display: "block"}}></div>
    //         <label> Escribe un nombre para esta caja y presiona enviar</label>
    //         <input type="text" name="nombre" onChange={(e) => escribirNombreCaja(e)} className="w-100 mb-3"/>
    //         <Button size="sm" className="mb-3" variant="success" onClick={() => enviarDatosCrearCaja()} > ENVIAR </Button>
    //     </Modal.Body>
    // </Modal>
    // }

    const agregarNuevaCajaDeLaModal = (data) => {
        adicionalOptions.unshift({...data, label: data.valor, value: data.valor  })
       return setAdicionalOptions(adicionalOptions)
    }

    const modalCajasMantenedor = () => {
        return <Modal show={showModal}  size="xl" onHide={handleCloseModalMantenedor} centered >
        <Modal.Header closeButton>
        <Modal.Title><b>MANTENEDOR DE CAJAS</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div></div>
            <CajasListado habilitar_eliminar={false} color_fondo={'#e0f2f1'} onCajaNueva={(data) => agregarNuevaCajaDeLaModal(data)} />
        </Modal.Body>
    </Modal>
    }


    const handleChangeSelectSpecial = (val, i) => {
        if(val.valor){
            return setPedido(prev => {
                let actual = {...prev}
                    actual.productos[i] = {...val.detalles ? val.detalles : val , nombre: val.valor, _id: val._id ? val._id.toString() : ""}
                return {...{}, ...actual}
            })
        } else {
            return setPedido(prev => {
                let actual = {...prev}
                    actual.productos[i] = { peso: 0, alto: 0, ancho: 0, largo: 0, nombre: "personalizado", _id: ""}
                return {...{}, ...actual}
            })
        }  
    }

    const formularioAvanzado = () => {
        return <div>
            <Row>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Referencia</label>
                    <input className="form-control" name="referencia" value={pedido.referencia} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Destinatario</label>
                    <input className="form-control" name="nombre_contacto" value={pedido.nombre_contacto} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Móvil</label>
                    <input className="form-control" name="telefono" value={pedido.telefono} onChange={handleChange} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Email</label>
                    <input className="form-control" name="email_contacto" value={pedido.email_contacto} onChange={handleChange} />
                </Col>
                <Col md={8}>
                <label className="form-control-label d-block" style={{fontWeight:700}}>Dirección</label>
                <ReactGoogleAutocomplete
                        className="form-control"
                        defaultValue={pedido.direccion}
                        options={{
                            types: tipos_direccion,
                            // cokmponentRestrictions: { country: pais },
                        }}                    
                        apiKey={maps_key}
                        onPlaceSelected={(informacion) => {
                            if(!informacion) return false
                            const lat = informacion.geometry.location.lat()
                            const lng = informacion.geometry.location.lng()

                            let pais = ''
                            let codigo_pais = ''
                            let level1 = ''
                            let level2 = ''
                            let level3 = ''

                            let formatted_address = ''
            
                            if(informacion.formatted_address){
                                formatted_address = informacion.formatted_address
                            }
            
                            const i_pais = informacion.address_components.findIndex(a => a.types.includes('country'))
                            if(i_pais > -1){
                                pais = informacion.address_components[i_pais].long_name
                                codigo_pais = informacion.address_components[i_pais].short_name
                            }
            
                            const l1 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_1'))
                            if(l1 > -1) level1 = informacion.address_components[l1].long_name
                            
                            const l2 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_2'))
                            if(l2 > -1) level2 = informacion.address_components[l2].long_name
                            
                            const l3 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_3'))
                            if(l3 > -1) level3 = informacion.address_components[l3].long_name

                            
                            let location = {
                                type: "Point",
                                coordinates: [
                                    lng, lat
                                ]
                            }

                            const geo_datos = {
                                pais,
                                codigo_pais,
                                formatted_address,
                                level1,
                                level2,
                                level3,
                                lat,
                                lng
                            }
                            
                            return setPedido(prev => {
                                let actual = {...prev}
                                actual.direccion = informacion.formatted_address
                                actual.geo_datos = geo_datos
                                actual.location = location
                                if(props.onChange) props.onChange(actual)
                                return actual
                            })                         
                        }}
                        />
                </Col>
                <Col md={4}>
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Observaciones de la dirección</label>
                    <input className="form-control" name="observaciones_direccion" value={pedido.observaciones_direccion} onChange={handleChange} />
                </Col>
            </Row>
            {mostrarProductos()}
            {mostrarEmailsAdicionales()}
        </div>
    }

    if(typeForm === "advance"){
        return formularioAvanzado()
    }

    return <div className="text-primary">
            <Row>
            <Col xs={6}>
                <Row>
                <Col md={3} className='pr-0'><h5 className='d-block' style={{fontWeight:900}}><MdOutlinePlace size={25} /> Origen</h5></Col>
                <Col md={9} className='pl-0'><hr/></Col>
                <Col md={12}><SelectorBodega defaultValue={pedido.origen_direccion_excel} onChange={(data) => handleChangeOrigen(data)} /></Col>
                </Row>
            </Col>
            <Col xs={6}>
                <Row>
                    <Col md={3} className='pr-0'><h5 className='d-block' style={{fontWeight:900}}><MdOutlinePlace size={25} /> Destino</h5></Col>
                    <Col md={9} className='pl-0'><hr/></Col>
                    <Col md={12}>
                <label className="form-control-label d-block" style={{fontWeight:700}}>Dirección</label>
                <ReactGoogleAutocomplete
                        className="form-control"
                        defaultValue={pedido.direccion}
                        options={{
                            types: tipos_direccion,
                            // cokmponentRestrictions: { country: pais },
                        }}                    
                        apiKey={maps_key}
                        onPlaceSelected={(informacion) => {
                            if(!informacion) return false
                            const lat = informacion.geometry.location.lat()
                            const lng = informacion.geometry.location.lng()

                            let pais = ''
                            let codigo_pais = ''
                            let level1 = ''
                            let level2 = ''
                            let level3 = ''

                            let formatted_address = ''
            
                            if(informacion.formatted_address){
                                formatted_address = informacion.formatted_address
                            }
            
                            const i_pais = informacion.address_components.findIndex(a => a.types.includes('country'))
                            if(i_pais > -1){
                                pais = informacion.address_components[i_pais].long_name
                                codigo_pais = informacion.address_components[i_pais].short_name
                            }
            
                            const l1 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_1'))
                            if(l1 > -1) level1 = informacion.address_components[l1].long_name
                            
                            const l2 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_2'))
                            if(l2 > -1) level2 = informacion.address_components[l2].long_name
                            
                            const l3 = informacion.address_components.findIndex(a => a.types.includes('administrative_area_level_3'))
                            if(l3 > -1) level3 = informacion.address_components[l3].long_name

                            
                            let location = {
                                type: "Point",
                                coordinates: [
                                    lng, lat
                                ]
                            }

                            const geo_datos = {
                                pais,
                                codigo_pais,
                                formatted_address,
                                level1,
                                level2,
                                level3,
                                lat,
                                lng
                            }
                            
                            return setPedido(prev => {
                                let actual = {...prev}
                                actual.direccion = informacion.formatted_address
                                actual.geo_datos = geo_datos
                                actual.location = location
                                if(props.onChange) props.onChange(actual)
                                return actual
                            })                         
                        }}
                        />
                </Col>
                </Row>
            </Col>
                {/* <Col md={4}>
                    <label className="form-control-label d-block" style={{fontWeight:700}}>Destinatario</label>
                    <input className="form-control" name="nombre_contacto" value={pedido.nombre_contacto} onChange={handleChange} />
                </Col> */}
                
            </Row>            
            {mostrarProductos()}
            {mostrarEmailsAdicionales()}
            {modalCajasMantenedor()}
    </div>
}

export default BoxOrdenImportacion