import { useCallback, useEffect, useState } from "react";
import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import ErrorBoundary from "../../subComponents/errorsHandler/errorBoundary";
import { urlapi } from "../../lib/backend/data";
import { debounce } from "@mui/material";
import FloatingProgressBar from "../../subComponents/general/floatingProgressBar";

const acciones_especiales = {
    flota: [
        { slug: "crear-vehiculo", label: "Crear vehículo" },
        { slug: "modificar-vehiculo", label: "Modificar vehículo" },
        { slug: "monitoreo-flota", label: "Monitoreo de vehículos" },
        { slug: "informes-rastreo", label: "Informes de rastreo" },
        { slug: "expediciones", label: "Expediciones" },
        { slug: "invitaciones-ruta", label: "Invitaciones de ruta" },
        { slug: "reporte-app-conductor", label: "Reporte de aplicación" },
    ],
    tarifas: [
        { slug: "cotizacion-individual", label: "Cotización individual" },
        { slug: "cotizacion-excel", label: "Cotización excel" },
        { slug: "cotizacion-api", label: "Cotización desde API" },
        { slug: "tarifarios", label: "Tarifario" },
        { slug: "contratos", label: "Contratos" },
    ],
    tickets: [
        { slug: "crear-tickets", label: "Crear tickets" },
        { slug: "responder-tickets", label: "Responder tickets" },
        { slug: "cerrar-tickets", label: "Cerrar tickets" },
    ],
    viajes: [
        { slug: "crear-ordenes", label: "Crear órdenes" },
        { slug: "torre-control", label: "Torre de control" },
        { slug: "visualizar-ordenes", label: "Visualizar órdenes" },
        { slug: "rutas-asignadas", label: "Rutas asignadas" },
        { slug: "reporte-pedidos", label: "Reporte de pedidos" },
        { slug: "reporte-conductores", label: "Reporte de conductores" },
        { slug: "otif-proveedores", label: "Informe de OTIF" },
        { slug: "crear-notificationes-viajes", label: "Crear notificaciones" },
    ],
    planificador: [
        { slug: "generar-planificacion-entregas", label: "Generar planificación de entregas" },
        { slug: "generar-planificacion-visitas", label: "Generar planificación de visitas" },
        { slug: "confirmar-planificacion-visitas", label: "Confirmar planificación de visitas" },
        { slug: "confirmar-planificacion-entregas", label: "Confirmar planificación de entregas" },
    ],
    facturacion: [
        { slug: "informes-facturacion", label: "Informes de facturación" },
        { slug: "facturacion-pendiente", label: "Facturación pendiente" },
        { slug: "metodos-pago", label: "Gestionar métodos de pago" },
        { slug: "gestionar-costos", label: "Gestionar costos" },
    ],
    clientes: [
        { slug: "crear-clientes", label: "Crear clientes" },
        { slug: "modificar-clientes", label: "Modificar clientes" },
        { slug: "eliminar-clientes", label: "Eliminar clientes" },
        { slug: "crear-contacto", label: "Crear contacto" },
        { slug: "modificar-contacto", label: "Modificar contacto" },
        { slug: "eliminar-contacto", label: "Eliminar contacto" },
        { slug: "crear-bodega", label: "Crear bodegas" },
        { slug: "modificar-bodega", label: "Modificar bodegas" },
        { slug: "eliminar-bodega", label: "Eliminar bodegas" },
    ],
    integraciones: [
        { slug: "crear-llave-api", label: "Crear llaves de API" },
        { slug: "crear-webhooks", label: "Crear webhooks" },
        { slug: "modificar-webhooks", label: "Modificar webhooks" },
        { slug: "crear-tracking-embedido", label: "Tracking embedido" },
        { slug: "gestionar-conexion-sin-api", label: "Gestionar conexión sin API" },
        { slug: "gestionar-plugin-conexion", label: "Configurar plugins de conexión" },
    ],

}

const PermisosUsuario = (props) => {
    const {
        user,
        defaultValue
    } = props
    const [ permisos, setPermisos ] = useState([]);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ permisos_otorgados, setPermisosOtorgados ] = useState(Array.isArray(defaultValue) ? defaultValue : [ ]);
    const dispatch = useDispatch()
    const [ loadingSave, setLoadingSave ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession

    useEffect(() => {
        obtenerMatriz()
    }, [])

    const obtenerMatriz = async () => {
        return fetch(`${urlapi}/permisos/modelo`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if (res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setPermisos(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            setLoading(false)
        })
    }

    const actualizarUsuario = async (data) => {
        setError("")
        setLoadingSave(true)
        return fetch(`${urlapi}/usuarios`,{
            method:'PUT',
            body: JSON.stringify({
                ...user,
                permisos: data
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res._id){

            }
            return setLoadingSave(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingSave(false)
        })
    }

    const actualizar = useCallback(debounce((data) => actualizarUsuario(data), 500), []);
    
    const handleCheck = (e) => {
        const { name } = e.target
        const permiso = e.target.getAttribute('permiso')
        // check if permissions are granted

        return setPermisosOtorgados(prev => {
            let actual = [...prev]
            const i = actual.findIndex(per => per.slug === permiso)
            if(i > -1){
    
                if(e.target.checked === true){
                    if(!actual[i].actions.includes(name)) actual[i].actions.push(name)
                } else {
                    const pos = actual[i].actions.findIndex(action => action === name)
                    if(pos > -1) actual[i].actions.splice(pos,1)
                }
    
            } else {
                actual.push({
                    slug: permiso,
                    actions: [ name ]
                })
            }
            actualizar(actual)
            return actual
        })
    }

    if(loading) return <div className="d-block"><Spinner animation="border" /></div>
    return <div>
        <FloatingProgressBar isVisible={loadingSave}  />
        <ErrorBoundary message={error} />
        <h3>Permisos</h3>
        <Row>
        {
            permisos.map(permiso => {
                return <Col key={permiso._id} md={12} className="mb-4">
                    <Card className='p-3 mb-3'>
                    <h5><b>{permiso.titulo}</b></h5>
                    <Row>
                    {
                        acciones_especiales[permiso.slug] ? acciones_especiales[permiso.slug].map((accion,i) => {
                            let checkeado = false
                            const pos = permisos_otorgados.findIndex(per => per.slug === permiso.slug)
                            if(pos > -1){
                                if(permisos_otorgados[pos].actions.includes(accion.slug)) checkeado = true
                            }
                            return <Col md={3} key={permiso.slug}>
                                <Form.Group key={accion.slug} className="mb-3" controlId={`${permiso._id}-${accion.slug}`}>
                                    <Form.Check type="switch" defaultChecked={checkeado} permiso={permiso.slug} name={accion.slug} label={accion.label} onChange={handleCheck} />
                                </Form.Group>
                            </Col>
                        }) : <Col md={12}><h4>Faltan las definiciones del módulo</h4></Col>
                    }
                    </Row>
                    </Card>
                    </Col>
            })
        }
        </Row>
    </div>
}

export default PermisosUsuario;