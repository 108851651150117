import { useState } from "react"
import { Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip, Modal, Badge } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";
import CargaImagenes from "../general/carga_imagenes";
import { LuMousePointerClick } from "react-icons/lu";
import { IoWarning } from "react-icons/io5";
import { Link } from "react-router-dom";
import { rutas } from "../../lib/routes/routes";
import { MdOpenInNew } from "react-icons/md";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const [showMessageInput, setShowMessageInput] = useState(false);
    const [message, setMessage] = useState('');
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [rating, setRating] = useState(proveedor.rating ? proveedor.rating : 0)
    const [showModal, setShowModal] = useState(false)
    const [ archivos, setArchivos ] = useState([])
    const dispatch = useDispatch()

    console.log('proveedor', proveedor)
    

    const sinInformacion = () => {
        return                 <Row className="mx-5 align-items-end mb-3">
                        <Col md={2} className="text-right">
                        <IoWarning className="text-warning" style={{fontSize:50}} />
                        </Col>
                        <Col md={10}>
                        <div className='text-center d-flex'>
                            <h5><LuMousePointerClick className='text-dark' style={{fontSize:25}}/> Seleccione una calificación para mostrar más información.</h5>
                        </div>
                        </Col>
                        </Row>
    }

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Abrir página de la orden
        </Tooltip>
    );

    const View = () => {
        if(!proveedor) return sinInformacion()
        return  <div className="">
        <Card className="p-5 m-5" style={{textAlign: 'center' }}>
            <Row>
                <Col md={12}>
                <div className="d-flex justify-content-between">
                <h2 style={{fontWeight:700}}>Orden <b>
                    <OverlayTrigger
                        placement="right"
                        overlay={renderTooltip}
                    >
                    <Link to={`/${rutas.ordenes.slug}/${proveedor.orden?._id}`} target="_blank">
                        {proveedor.orden?.pedido}
                    </Link>
                    </OverlayTrigger>
                </b>
                </h2>
                <h4 className="mr-2"><Badge style={{ backgroundColor: proveedor.orden?.estado_entrega?.color, color:'white' }}>{proveedor.orden?.estado_entrega?.titulo}</Badge></h4>
                </div>
                <hr/>
                </Col>
                
                { proveedor.orden ? <>
                    <Col md={6} className="text-left mb-3">
                        <span><b className="pr-2">Entregado:</b>{new Date(proveedor.orden?.fecha_finalizado).toLocaleDateString()}</span>
                    </Col>
                    <Col md={6} className="text-left mb-3">
                        <span><b>Cantidad de bultos entregados:</b> {proveedor.orden?.bultos}</span>
                    </Col>
                    <Col md={6} className="text-left mb-3">
                        <span><b>Peso: </b> {proveedor.orden?.peso ? proveedor.orden.peso : "Sin información"}</span>
                    </Col>
                    <Col md={6} className="text-left mb-3">
                        <span><b>Volúmen: </b> {proveedor.orden?.volumen  ? proveedor.orden.volumen : "Sin información"}</span>
                    </Col>
                    {/* <span>Datos de Facturación</span> */}
                    <Col md={6} className="text-left mb-3">
                        <span><b>Nombre:</b> {proveedor.orden.orden?.billing?.first_name} {proveedor.orden.orden?.billing?.last_name}</span>
                    </Col>
                    <Col md={6} className="text-left mb-3">
                        <span><b>Teléfono:</b> {proveedor.orden.orden?.billing?.phone}</span>
                    </Col>
                    <Col md={6} className="text-left mb-3">
                        <span><b className="pr-2">Dirección: </b> {proveedor.orden.orden?.geo_datos?.formatted_address}</span>
                    </Col>
                    </> : false }
        </Row>
        <hr/>
        {/* <label  className="form-control-label"  style={{fontSize: '20px'}}>Rating: {proveedor.rating} / {proveedor?.cantidad_estrellas_esquema}</label> */}
        {/* { proveedor.rating > 0 ? <>
            {
                <div className="d-flex justify-content-center m-0 p-0">
                     {[...Array(proveedor.cantidad_estrellas_esquema)].map((_, index) => (
                <span
                key={index}
                style={{
                    cursor: 'pointer',
                    color: index < rating ? '#ffd700' : '#ccc',
                    fontSize: '3rem'
                }}>
                    ★
                </span>
            ))}

                </div>
            }
            </> : false
        } */}
        <div className="my-3">
        <div className="d-flex justify-content-center m-0 p-0">
            {[...Array(proveedor.cantidad_estrellas_esquema)].map((_, index) => (
                <span
                key={index}
                style={{
                    color: index < rating ? '#ffd700' : '#ccc',
                    fontSize: '3rem'
                }}>
                    ★
                </span>
            ))}
        </div>
        { !proveedor.rating && <p className="text-muted mt-2" style={{fontSize:12}}>No se ha realizado calificación por estrellas.</p> }
        </div>

         { proveedor.categorias && proveedor.categorias.length > 0 ? <div >
            {proveedor.categorias.map((el, i) => {
                return <>
                <h4 key={i} className="mt-3" >{el.titulo}:</h4>
                <h1><b>{el.value}</b></h1>
                </>
            })}
              </div> 
                : false
         }
         { proveedor.comentario ? <div style={{textAlign: 'center'}} className="mb-4 mt-3">
            <h4 className="mt-5">Comentario:</h4>
            <p>"- {proveedor.comentario}"</p>
        </div> 
            : false
        }
        {/* { proveedor.documentos.length > 0 ? <>
        <span>Aqui se muestran los documentos cargados...</span>
        </> 
            : false
        } */}
        { proveedor.documentos.length > 0 ? (
            <div style={{textAlign: 'center'}} className="mb-4 mt-3">
                <h4 className="mt-5">Documentos cargados:</h4>
                <ul>
                    {proveedor.documentos.map((documento, index) => (
                        <li key={index}>
                            <a href={documento.url} target="_blank" rel="noopener noreferrer">
                                {documento.nombre}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        ) : (
            <p className="text-muted mt-2">No hay documentos cargados.</p>
        )}

        </Card>
        </div>
       
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull