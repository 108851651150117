import { useState } from "react"
import { Button, Card, Col, OverlayTrigger, Row, Spinner, Tooltip, Modal } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";
import WhatsappPlantillaSelector from "../ordenes/whatsapp-plantillas/selector";
import SelectorEstados from "../estados_carga/selector";
import TiposServicioSelector from "../ordenes/tipos_servicio/selector";
import TiposServicioSelectorPersonalizado from "../ordenes/tipos_servicio/selector_personalizado";
import { FaRegHandPointer, FaRegTrashAlt } from "react-icons/fa";
import CargaImagenes from "../general/carga_imagenes";
import { BiPlus } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const [showMessageInput, setShowMessageInput] = useState(false);
    const [message, setMessage] = useState('');
    const token = props.token ? props.token : false
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [rating, setRating] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [ archivos, setArchivos ] = useState([])
    const requeridos = [
        { value:'titulo', label: 'Título' },
        { value:'tipo', label: 'Tipo' },
        // { value:'plantilla', label: 'Plantilla' }
    ]
    const dispatch = useDispatch()

    if(rating > proveedor?.cantidad_estrellas) setRating(0)
    if(rating > nuevoProveedor?.cantidad_estrellas) setRating(0)

    const handleRating = (rate) => {
        setRating(rate)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        return setProveedor(prev => {
            let actual = { ...prev } 
            actual[name] = value
            if(name === "cantidad_estrellas"){
                actual[name] = parseInt(value)
            } 
            if(actual.casilla_estrellas === "false" || actual.casilla_estrellas === false ){ 
                actual.cantidad_estrellas = 0
            }
            console.log("proveedor", actual)
            return actual
        })
    }

    const crearNuevo = async () => {
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

        setLoading(true)
        return fetch(`${data.urlapi}/general/calificaciones`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        let faltantes = []
        requeridos.map(campo => {
            if(!proveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoading(true)
        return fetch(`${data.urlapi}/general/calificaciones`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const agregarCategoriaCrear = () => {
        setNuevoProveedor(prev => {
            let actual = { ...prev }
            if(!actual.categorias){
                actual.categorias = []
            }
            actual.categorias.unshift({titulo: "", subCategoria: []})
            console.log("nuevo", actual)
            return actual
        })
    }

    const agregarCategoriaEditar = () => {
        setProveedor(prev => {
            let actual = { ...prev }
            if(!actual.categorias){
                actual.categorias = []
            }
            actual.categorias.unshift({titulo: "", subCategoria: []})
            console.log("nuevo", actual)
            return actual
        })
    }
    const agregarSubCategoriaCrear = (i) => {
        setNuevoProveedor(prev => {
            let actual = { ...prev }
            if(!actual.categorias[i].subCategoria){
                actual.categorias[i].subCategoria = []
            }
            actual.categorias[i].subCategoria.push({titulo: ""})
            console.log("nuevo", actual)
            return actual
        })
    }

    const agregarSubCategoriaEditar = (i) => {
        setProveedor(prev => {
            let actual = { ...prev }
            if(!actual.categorias[i].subCategoria){
                actual.categorias[i].subCategoria = []
            }
            actual.categorias[i].subCategoria.push({titulo: ""})
            console.log("nuevo", actual)
            return actual
        })
    }

    const categoriaCrearTitulo = (e,i) => {
        const {value} = e.target
        setNuevoProveedor(prev => {
            let actual = { ...prev }
                actual.categorias[i].titulo = value
            console.log("cat", actual)
            return actual
        })
    }

    
    const categoriaEditarTitulo = (e,i) => {
        const {value} = e.target
        setProveedor(prev => {
            let actual = { ...prev }
                actual.categorias[i].titulo = value
            console.log("cat", actual)
            return actual
        })
    }

    const subCategoriaCrearTitulo = (e,index,i) => {
        console.log(e)
        console.log(index)
        console.log(i)
        const {value} = e.target
        setNuevoProveedor(prev => {
            let actual = { ...prev }
                actual.categorias[i].subCategoria[index].titulo = value
            console.log("subcat", actual)
            return actual
        })

    }

    const subCategoriaEditarTitulo = (e,index,i) => {
        console.log(e)
        console.log(index)
        console.log(i)
        const {value} = e.target
        setProveedor(prev => {
            let actual = { ...prev }
                actual.categorias[i].subCategoria[index].titulo = value
            console.log("subcat", actual)
            return actual
        })

    }

    const eliminarCategoria = (i) => {
        setNuevoProveedor(prev => {
            let actual = { ...prev }
                actual.categorias.splice(i, 1)
            return actual
        })
    }

    const eliminarCategoriaEditar = (i) => {
        setProveedor(prev => {
            let actual = { ...prev }
                actual.categorias.splice(i, 1)
            return actual
        })
    }

   const eliminarSubCategoria = (index,i) => {
    setNuevoProveedor(prev => {
        let actual = { ...prev }
            actual.categorias[i].subCategoria.splice(index, 1)
        return actual
    })
   }

   const eliminarSubCategoriaEditar = (index,i) => {
    setProveedor(prev => {
        let actual = { ...prev }
            actual.categorias[i].subCategoria.splice(index, 1)
        return actual
    })
   }
    const tipoServicioCrear = (e) => {
        setNuevoProveedor(prev => {
            let actual = { ...prev }
            actual.tipos_servicio = e
            console.log("nuevo", actual)
            return actual
        })
            
        }
    
        const tipoServicioEditar = (e) => {
            setProveedor(prev => {
                let actual = { ...prev }
                actual.tipos_servicio = e
                console.log("editar", actual)
                return actual
            })
                
            }
    

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        setNuevoProveedor(prev => {
            let actual = { ...prev }
            actual[name] = value
            if(name === "cantidad_estrellas"){
                actual[name] = parseInt(value)
            } 
            if(actual.casilla_estrellas === "false"){ 
                actual.cantidad_estrellas = 0
            }
            console.log("nuevo", actual)
            return actual
        });
    
    }

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const agregarArchivo = (url,name) => {
        console.log(url,name)
        return setArchivos(prev => {
            let actual = [...prev]
            actual.unshift({
                url,
                name
            })
            return [...actual]
        })
    }

    const removerArchivo = (i) => {
        setArchivos(prev => {
            let actual  = [...prev]
            actual.splice(i,1)
            return [...actual]
        })
    }

    
    const mostrarModal = (objeto) => {
        return <Modal show={showModal} onHide={handleCloseModal} size="lg" centered  >
                <Modal.Header closeButton className="pb-0">
                    <Modal.Title className="text-primary" style={{fontWeight:900}}>CALIFICACIONES</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-0 text-primary">
                    <p className="text-secondary mb-0 pb-0" style={{fontWeight:700}}>Asi se vera el formulario de calificaciones</p>
                <hr />
                <Row className="d-flex justify-content-center align-items-center">
                <Card className="shadow bg-light p-4 m-3 d-flex justify-content-center align-items-center w-50">
                <p>{objeto?.titulo}</p>
                <p>{objeto?.descripcion}</p>
                { objeto.tipos_servicio && objeto.tipos_servicio.length > 0 ? <div className="mb-0" style={{textAlign: 'center'}}>
                    <label className="form-control-label">Tipos de servicios a evaluar:</label> 
                    <p style={{fontSize: '13px'}}>{objeto.tipos_servicio.map((el) => el.valor).join(', ')}</p>
                </div> 
                    : false
                }
                { objeto.casilla_comentario && objeto.casilla_comentario  !== false &&  objeto.casilla_comentario  !== "false" ? <div style={{textAlign: 'center'}} className="mb-3 ">
                    <label className="form-control-label d-block" >Dejanos tu comentario:</label>
                    <input type="text" />
                </div> 
                    : false
                }
                { objeto.carga_documentos && objeto.carga_documentos  !== false &&  objeto.carga_documentos  !== "false" ? <>
                    <CargaImagenes onUploaded={(url,name) => agregarArchivo(url,name)} />
                </> 
                    : false
                }
                { objeto.casilla_estrellas && objeto.casilla_estrellas  !== false &&  objeto.casilla_estrellas  !== "false" ? <>
                    { objeto.cantidad_estrellas > 0 ? <>
                    {
                        <div className="d-flex justify-content-center m-0 p-0">
                             {[...Array(objeto.cantidad_estrellas)].map((_, index) => (
                        <span
                        key={index}
                        onClick={() => handleRating(index + 1)}
                        style={{
                            cursor: 'pointer',
                            color: index < rating ? '#ffd700' : '#ccc',
                            fontSize: '2rem'
                        }}>
                            ★
                        </span>
                    ))}

                        </div>
                    }
                   
                    <label  className="form-control-label">Rating: {rating} / {objeto.cantidad_estrellas}</label>
                    
                    </> : false  }
                </> : false
                }
                 { objeto.categorias && objeto.categorias.length > 0 ? <div className="mb-3">
                    {objeto.categorias.map((el, i) => {
                        return <>
                        <label key={i} className="form-control-label mt-2">{el.titulo}</label>
                       
                            {el.subCategoria.length > 0 ? <div>
                                <select name={el.titulo} className='form-control'>
                               { el.subCategoria.map((sub, i) => {
                                    return <>
                                    <option key={i} value={sub.titulo}>{sub.titulo}</option>
                                    </>
                                })
                            }  </select></div> : false }
                           
                        </>
                    })}
                    
                </div> 
                    : false
                }
            <Button className="m-3 p-0 w-50" variant="success" onClick={()=>setShowModal(false)}>Enviar</Button>
                </Card>
                </Row>
                </Modal.Body>
        </Modal>
    }
    

    const formularioNuevo = () => {
        return <div>
            <Row>
            <Col md={12}>
                <h4 className="mb-3">Crear nueva calificacion</h4>
                {/* <p>Los campos personalizados se utilizan para guardar información adicional a las órdenes.</p> */}
            </Col>
            <Col md={12} className="mb-3">
            <div className="d-flex m-0 justify-content-between align-items-end" > 
                <span></span>
                <Button className="py-0 d-block" size='sm' variant="outline-secondary" onClick={()=>handleOpenModal()}><BsEyeFill/> Previsualizar</Button></div>
            </Col>
            <Col md={4}className="mb-3">
                <label className="form-control-label d-block">Título</label>
                <input className="form-control" name="titulo" value={nuevoProveedor.titulo} onChange={handleChangeNuevo} />
            </Col>
            <Col md={8} className="mb-3">
                <label className="form-control-label d-block">Descripción</label>
                <input className="form-control" name="descripcion" value={nuevoProveedor.descripcion} onChange={handleChangeNuevo} />
            </Col>
            <Col  className="mb-3">
                <label className="form-control-label d-block">Tipo</label>
                <select className="form-control" name="tipo" value={nuevoProveedor.tipo} onChange={handleChangeNuevo}>
                    <option value="">Seleccione</option>
                    <option value="orden">Orden</option>
                </select>
            </Col>
            <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Habilitar carga de documentos</label>
                <select className="form-control" name="carga_documentos" value={nuevoProveedor.carga_documentos} onChange={handleChangeNuevo}>
                <option value="">Seleccione</option>
                <option value="true">Si</option>
                <option value="false">No</option>
                </select>
            </Col>
            <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Habilitar casilla comentario</label>
                <select className="form-control" name="casilla_comentario" value={nuevoProveedor.casilla_comentario} onChange={handleChangeNuevo}>
                    <option value="">Seleccione</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                </select>
            </Col>
            <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Habilitar casilla estrellas</label>
                <select className="form-control" name="casilla_estrellas" value={nuevoProveedor.casilla_estrellas} onChange={handleChangeNuevo}>
                <option value="">Seleccione</option>
                <option value="true">Si</option>
                <option value="false">No</option>
                </select>
            </Col>
            {(nuevoProveedor.casilla_estrellas === "true") ?  <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Cantidad de estrellas</label>
                <select className="form-control" name="cantidad_estrellas" value={nuevoProveedor.cantidad_estrellas} onChange={handleChangeNuevo}>
                <option value="">Seleccione</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                </select>
            </Col> : false }           
            <Col md={12}>
            <TiposServicioSelectorPersonalizado titulo="Tipo de servicio" condicion_default={{ tipo: "logistica", sub_tipo: "tipos-servicio" }} onChangeValue={(data) => tipoServicioCrear(data)} isMulti={true} full_payload={true} />
            </Col>
            <Col md={12}>
            <Button className="mb-3 mt-3 p-0 w-100" onClick={()=>agregarCategoriaCrear()}><BiPlus/> AÑADIR CATEGORIA</Button>
            {nuevoProveedor.categorias ? nuevoProveedor.categorias.map((cat,i) => { return <Card className="mb-3 p-3 bg-light">
                <div className="d-flex justify-content-end">
                    <Button variant="outline-danger" size="sm" className="py-0" onClick={() => eliminarCategoria(i)}>Eliminar</Button>
                </div>                    
                    <label className="form-control-label d-block">Título categoria</label> 
                    <input type="text" className="form-control" value={cat.titulo} onChange={(e) => categoriaCrearTitulo(e,i)}></input>
                <div className="d-flex justify-content-end">
                    <Button className="py-0 my-3" size="sm" variant="secondary" onClick={()=>agregarSubCategoriaCrear(i)}><BiPlus/> Añadir sub categoria</Button>
                </div>
                <Row className="d-flex justify-content-center justify-content-between align-items-start">
                    {cat.subCategoria.map((cate,index) => { return <Col md={6}>
                        <Card className="p-3 mb-3">
                            <div className="d-flex justify-content-between">
                                <label className="form-control-label d-block" >Título sub categoria</label> 
                                <FaRegTrashAlt className="text-danger" onClick={()=>eliminarSubCategoria(index,i)} />
                            </div>
                            <div className="d-flex justify-content-center">
                                <input type="text" className="form-control mb-3" value={cate.titulo} onChange={(e) => subCategoriaCrearTitulo(e,index,i)}></input>
                            </div>
                        </Card>
                    </Col>})} 
                </Row>
            </Card>}): false}
            
            </Col>

            {mostrarModal(nuevoProveedor)}

                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/general/calificaciones?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
            const items = proveedor.tipos_servicio ? proveedor.tipos_servicio.map(e => e._id) : []
        return <Row>
        <Col md={12} className="mb-3">
            {
                removiendo === true ? <Spinner animation="border" /> : <div className="d-flex m-0 justify-content-between align-items-start" > 
                <Button className="py-0 d-block" size='sm' variant="outline-danger" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
                <Button className="py-0 d-block" size='sm' variant="outline-secondary" onClick={()=>handleOpenModal()}><BsEyeFill/> Previsualizar</Button>
                </div>
            }
        </Col>
        <Col md={4} className="m-0">
            <label className="form-control-label d-block M-0 P-0">Título</label> 
            <input className="form-control" name="titulo" value={proveedor?.titulo} onChange={handleChange} />
        </Col>
        <Col md={8} className="m-0">
            <label className="form-control-label d-block M-0 P-0">Descripción</label> 
            <input className="form-control" name="descripcion" value={proveedor?.descripcion} onChange={handleChange} />
        </Col>
        <Col md={4} className="mb-3">
            <label className="form-control-label d-block">Tipo</label>
            <select className="form-control" name="tipo" value={proveedor?.tipo} onChange={handleChange} >
                <option value="">Seleccione</option>
                <option value="orden">Orden</option>
            </select>
        </Col>
        <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Habilitar carga de documentos</label>
                <select className="form-control" name="carga_documentos" value={proveedor?.carga_documentos} onChange={handleChange}>
                <option value="">Seleccione</option>
                <option value="true">Si</option>
                <option value="false">No</option>
                </select>
            </Col>
            <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Habilitar casilla comentario</label>
                <select className="form-control" name="casilla_comentario" value={proveedor?.casilla_comentario} onChange={handleChange}>
                    <option value="">Seleccione</option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                </select>
            </Col>
            <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Habilitar casilla estrellas</label>
                <select className="form-control" name="casilla_estrellas" value={proveedor?.casilla_estrellas} onChange={handleChange}>
                <option value="">Seleccione</option>
                <option value="true">Si</option>
                <option value="false">No</option>
                </select>
            </Col>
            {(proveedor?.casilla_estrellas !== "true" && proveedor.casilla_estrellas !== true) ? false
            :  <Col md={4} className="mb-3">
                <label className="form-control-label d-block">Cantidad de estrellas</label>
                <select className="form-control" name="cantidad_estrellas" value={proveedor?.cantidad_estrellas} onChange={handleChange}>
                <option value="">Seleccione</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                </select>
            </Col>  } 
            <Col md={12}>
            <TiposServicioSelectorPersonalizado titulo="Tipo de servicio" condicion_default={{ tipo: "logistica", sub_tipo: "tipos-servicio" }} defaultValue={items} onChangeValue={(data) => tipoServicioEditar (data)} isMulti={true} full_payload={true} />
            </Col>
            <Col md={12}>
            <Button className="mb-3 mt-3 p-0 w-100" onClick={()=>agregarCategoriaCrear()}><BiPlus/> AÑADIR CATEGORIA</Button>
            <div>{proveedor.categorias ? proveedor.categorias.map((cat,i) => { return <Card className="mb-3 p-3 bg-light">
                <div className="d-flex justify-content-end">
                    <Button variant="outline-danger" size="sm" className="py-0" onClick={() => eliminarCategoria(i)}>Eliminar</Button>
                </div>                    
                    <label className="form-control-label d-block">Título categoria</label> 
                    <input type="text" className="form-control" value={cat.titulo} onChange={(e) => categoriaCrearTitulo(e,i)}></input>
                <div className="d-flex justify-content-end">
                    <Button className="py-0 my-3" size="sm" variant="secondary" onClick={()=>agregarSubCategoriaCrear(i)}><BiPlus/> Añadir sub categoria</Button>
                </div>
                <Row className="d-flex justify-content-center justify-content-between align-items-start">
                    {cat.subCategoria.map((cate,index) => { return <Col md={6}>
                        <Card className="p-3 mb-3">
                            <div className="d-flex justify-content-between">
                                <label className="form-control-label d-block" >Título sub categoria</label> 
                                <FaRegTrashAlt className="text-danger" onClick={()=>eliminarSubCategoria(index,i)} />
                            </div>
                            <div className="d-flex justify-content-center">
                                <input type="text" className="form-control nw-100 mb-3" value={cate.titulo} onChange={(e) => subCategoriaCrearTitulo(e,index,i)}></input>
                            </div>
                        </Card>
                    </Col>})} 
                </Row>
            </Card>}): false} 
            </div>
            
            </Col>
            {mostrarModal(proveedor)}

        <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
    </Row>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull