import { Button, Col, Dropdown, Row, Spinner } from "react-bootstrap"
import TiposServicioSelector from "../tipos_servicio/selector"
import { FaFilter, FaPlus } from "react-icons/fa"
import Skeleton from "react-loading-skeleton"
import { useCallback, useEffect, useRef, useState } from "react"
import { urlapi } from "../../../lib/backend/data"
import { useDispatch, useSelector } from "react-redux"
import { cerrarSesion } from "../../../redux/actions/session"
import { BiTrash } from "react-icons/bi"
import { modificarEsquema } from "../../../redux/actions/esquemaTablas"
import { debounce } from "@mui/material"
import ProgressBar from "../../general/barraProgreso"

const EditarPeronslizado = (props) => {
    const {
        id_orden,
        type
    } = props
    const available_items = [
        { value: "bultos", label: "Bultos", md: 3 },
        { value: "refrigerado", label: "Bultos refrigerados", md: 3 },
        { value: "congelado", label: "Bultos congelados", md: 3 },
        { value: "tipo_servicio", label: "Tipo de servicio", md: 4 },
        { value: "nota", label: "Notas", md: 12 },
        { value: "peso", label: "Peso", md: 3 },
        { value: "volumen", label: "Volumen", md: 3 },
    ]
    const [ error, setError ] = useState('')
    const [ loading, setLoading ] = useState(true)
    const [ lodingGuardado, setLoadingGuardado ] = useState(false)
    const [ orden, setOrden ] = useState(false)
    const dispatch = useDispatch()
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const interfaz_usuario = useSelector(state => state.esquema_tablas)
    const [ selectedItems, setSelectedItems ] = useState(interfaz_usuario[type]?.fields_quick_edit || [])

    const abortControllerRef = useRef(null);
    
    useEffect(() => {
        getOrder(id_orden)
    }, [])
    
    const guardarCambios = async (payload)=>{
        setLoadingGuardado(true)
        return fetch(`${urlapi}/ordenes/light`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res?._id){

            }
            return setLoadingGuardado(false)
        })
        .catch(error => {
            return setLoadingGuardado(false)
        })
    }

    const getOrder = async (id)=>{
        if(!id) return setLoading(false)
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }

        const controller = new AbortController();
        abortControllerRef.current = controller;

        setLoading(true)
        return fetch(`${urlapi}/ordenes/light?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
            signal: controller.signal
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(res?._id){
                setOrden(res)
            }
            return setLoading(false)
        })
        .catch(error => {
            return setLoading(false)
        })
    }

    const actualizar = useCallback(debounce((data) => guardarCambios(data), 500), []);
    
    const handleChange = (e) => {
        const { name, value } = e.target
        return setOrden(prev => {
            let actual = {...prev}
            actual[name] = value
            actualizar(actual)
            return actual
        })
    }

    const formInputNumber = (name, label, value) => <div>
        <label className="form-control-label d-block">{label}</label>
        <input className="form-control" type="number" name={name} defaultValue={value} onChange={handleChange} />
    </div>
    
    const formInputText = (name, label, value) => <div>
        <label className="form-control-label d-block">{label}</label>
        <input className="form-control" name={name} defaultValue={value} onChange={handleChange} />
    </div>

    const componentes = {
        bultos: formInputNumber("bultos", "Bultos", orden.bultos),
        refrigerado: formInputNumber("refrigerado", "Bultos refrigerados", orden.refrigerado),
        congelado: formInputNumber("congelado", "Bultos congelados    ", orden.congelado),
        peso: formInputNumber("peso", "Peso", orden.peso),
        volumen: formInputNumber("volumen", "Volumen", orden.volumen),
        nota: formInputText("nota", "Observaciones", orden.nota),
        tipo_servicio: <div>
            <label className="form-control-label d-block">Tipo de servicio</label>
            <TiposServicioSelector defaultValue={orden.tiposervicio ? { _id: orden.tiposervicio } : false } onChangeValue={(data) => handleChange({ target: { name: "tiposervicio", value: data?._id } })} />
        </div>
    }

    const removerFiltro = (val) => {
        setSelectedItems(prev => {
            const actual = [ ...prev ]
            return actual.filter(e => e !== val)
        })
    }

    const addFilter = (filtro) => {
        setSelectedItems(prev => {
            let actual = [...prev]
            actual.unshift(filtro)
            const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario))
            if(!instancia_esquema[type]) instancia_esquema[type] = {}
            instancia_esquema[type].fields_quick_edit = actual
            dispatch(modificarEsquema(instancia_esquema, token))
        
            return [ ...actual ]
        })
    }


    const showMoreFilters = () => {
            return <Dropdown className='p-0 mr-2' style={{ display: "inline-block" }}>
            <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-basic"><FaPlus size={13} /> AGREGAR CAMPO</Dropdown.Toggle>
            <Dropdown.Menu>
            {
                available_items.map(e => {
                    return selectedItems.includes(e.value) ? false : <Dropdown.Item key={e.value} onClick={() => addFilter(e.value)}>{e.label}</Dropdown.Item>
                })
            }
            </Dropdown.Menu>
        </Dropdown>
    }

    if(loading) return <Skeleton height={50} />
    if(!orden) return false

    const indexAvailables = available_items.reduce((acc, e) => {
        acc[e.value] = e
        return acc
    },{})

    const iterar_ordenar = selectedItems.map(key => {
        const datos = indexAvailables[key]
        return {
            key,
            md: datos.md,
        }
    }).sort((a,b) => a.md - b.md).map(e => e.key)
    
    return <div className="mb-3">
        {showMoreFilters()}
        {lodingGuardado ? <div className="mt-3"><ProgressBar /></div> : false  }
        <Row className="mt-3">
            {
                iterar_ordenar.map((item,i) => {
                    const datos = indexAvailables[item]
                    return <Col md={datos.md} xs={12} key={`campo-${i}`} className="mb-2">
                        { <div>
                            {componentes[item]}
                            <span className="text-danger hover text-center" style={{ fontSize: 11 }} onClick={() => removerFiltro(item)}><BiTrash /><b>OCULTAR CAMPO</b></span>
                        </div> || <div>
                            <label className="form-control-label d-block">{datos.label}</label>
                            <h5 className="mb-0">Campo no disponible para edición</h5>
                            </div>}
                    </Col>
                })
            }
        </Row>
    </div>
}

export default EditarPeronslizado