import { useCallback, useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Modal, Pagination, Card, Form, Table, OverlayTrigger, Tooltip, Navbar, Nav, Badge, NavDropdown, Dropdown } from 'react-bootstrap'
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import 'react-confirm-alert/src/react-confirm-alert.css'
import Select from 'react-select';
import { DateRange, DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale'
import { Link } from 'react-router-dom';
import { obtenerFechaHoraZonaHorariaLocal, obtenerRangos } from '../../lib/helpers/dates';
import BuscadorVehiculos from '../conductores/buscador';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '../../lib/helpers/router';
import { useLocation, useNavigate } from 'react-router-dom'
import { fechaATexto, fechaATextoSimple, fechaATextoSimpleCorto, formatYMD } from '../../lib/helpers/helpers';
import { calcularEstadoPicking, getDireccion1, getEmail, getNombreDestinatario, getTelefono, obtenerGeoValor } from '../../lib/helpers/pedidos/pedidos';
import Filtros from './filtros';
import { AiOutlineMenu, AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai'
import { estilo_last_mile } from '../../lib/global/styles';
import SelectorZonaAvanzadoFiltro from '../../components/RadioOperacion/selector_zona_avanzado_filtro';
import PersonalizarColumnas from '../interfaz/columnas_personalizar';
import { FiBox, FiSettings } from 'react-icons/fi'
import { BiArrowBack } from 'react-icons/bi'
import SelectorCamposPersonalizados from '../campos-personalizados/selector';
import { modificarEsquema } from '../../redux/actions/esquemaTablas';
import { AiFillEyeInvisible } from 'react-icons/ai'
import { cerrarSesion } from '../../redux/actions/session';
import TablaOrdenesStatic from './visual/tabla_registros_static';
import SelectorDestinatarios from '../destinatarios/selector';
import LoadingOrders from '../general/loadingAnimations/loadingOrders';
import HelperModal from '../general/helperModal';
import TiposServicioSelector from '../ordenes/tipos_servicio/selector';
import DetailFullModalTicket from './ordenes_tickets/modal_ticket';
import { rutas } from '../../lib/routes/routes';
import { FaCheckCircle, FaEdit, FaFileExcel, FaFilter, FaPlus, FaPlusCircle, FaRegClock } from 'react-icons/fa';
import ImprimirMasivo from './imprimirMasivo';
import AsignarRutaMasivo from './asignar_ruta';
import { esimacionFechas } from '../../lib/helpers/logistica';
import GeneradorEtiquetasPersonalizado from './etiquetas/generador_etiquetas';
import GestorProceso from '../general/gestor_proceso';
import TagSinDatos from '../general/tag_sin_datos';
import MetaDatosOrden from './metadata/metadatos_orden';
import ModalPersonalizado from '../general/modalPersonalizado';
import socket from '../../lib/websockets';
import PickingOrden from './picking/picking';
import DespachoOrden from './despacho';
import { canales } from './data';
import { IoClose, IoFilter, IoReload } from 'react-icons/io5';
import ModalFasesTrabajo from '../flujos-trabajo/modalInformativa';
import CamposPersonalizadosSelectores from '../general/campos_personalizados_selectores';
import { IoMdClose } from 'react-icons/io';
import { debounce } from '@mui/material';
import ErrorBoundary from '../errorsHandler/errorBoundary';
import SelectorEstados from '../estados_carga/selector';
import Moment from 'react-moment';
import { DateTime } from 'luxon';
import { FaTruckRampBox } from 'react-icons/fa6';

const OrdenesListadoV2 = (props) => {
    const initialDate = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }
    const {
        hideHeader,
        condicion_default,
        refresh,
        hideTitle,
        typeView,
        gestionadas,
        modal,
        allowWorkSpace
    } = props
    const filterAvailables = [
        { value: "estado", label: "Estado de entrega" },
        { value: "picking", label: "Estado de picking" },
        { value: "vehiculo", label: "Vehículo" },
        { value: "localidad", label: "Localidad" },
        { value: "tipo-logistica", label: "Tipo de logística" },
        { value: "cliente", label: "Cliente" },
        { value: "canal", label: "Canal" },
        { value: "fecha", label: "Fecha" },
        // { value: "campos_personalizados", label: "Campos personalizados" },
    ]
    const [ error, setError ] = useState(null)
    const [ condicion_inicial, setCondicionInicial ] = useState(condicion_default ? condicion_default : {})
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [ equipos, setEquipos ] = useState([])
    const [ loadingEquipos, setLoadingEquipos ] = useState(true)
    const [ key_input, setKeyInput ] = useState(1)
    const [ sinEstado, setSinEstado ] = useState(false)
    const [ patente, setPatente ] = useState('')
    const [ todosLosEstados, setTodosLosEstados ] = useState([])
    const [ tipoEstadoBusqueda, setTipoEstadoBusqueda ] = useState("estados")
    const [ filtersActives, setFiltersActives ] = useState([])
    const opcionesPicking = [{ value: "pendiente", label: "Pendiente" },{ value: "EN PROCESO", label: "En proceso" },{ value: "LISTO", label: "Finalizado" }]
    const [ numeroInterno, setNumeroInterno ] = useState('')
    const [ removiendo, setRemoviendo ] = useState(false)
    const [ openModal, setOpenModal ] = useState(false)
    const [ pedidoSeleccionado, setPedidoSeleccionado ] = useState(false)
    const [ disableStatuses, setDisableStatuses ] = useState(false)
    const [ loadingExcel, setLoadingExcel ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ clienteBusqueda, setClienteBusqueda ] = useState('')
    const [ pedidoBusqueda, setPedidoBusqueda ] = useState('')
    const [ defaultValueEstados, setDefaultValueEstados ] = useState(null)
    const [ idSeleccionado, setIdSeleccionado ] = useState(false)
    const [ campoBusqueda, setCampoBusqueda ] = useState('')
    const [ camposPersonalizados, setCamposPersonalizados ] = useState({})
    const [ camposPersonalizadosOrden, setCamposPersonalizadosOrden ] = useState([])
    const [ showModalBusquedaAvanzada, setShowModalBusquedaAvanzada ] = useState(false)
    const [ filtroEstado, setFiltroEstado ] = useState([])
    const abortControllerRef = useRef(null);
    const [ incluirGestionadas, setIncluirGestionadas ] = useState( gestionadas === true )
    const [ filtroZona, setFiltroZona ] = useState(false)
    const [ tipoCondicion, setTipoCondicion ] = useState('')
    const [ espacioTrabajo, setEspacioTrabajo ] = useState(false)
    const [ customColumns, setCustomColumns ] = useState([])
    const [ filtroSubEstado, setFiltroSubEstado ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const [ fasesTrabajo, setFasesTrabajo ] = useState([])
    const pais = useSelector(state => state.pais)
    const [ total, setTotal ] = useState(0)
    const [ pagina, setPagina ] = useState(1)
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_inicial)
    const token = session.tokenSession
    const trash = props.trash ? props.trash : false
    const [ selectionRange, setSelectionRange ] = useState(initialDate)
    const [ tipoFechaBusqueda, setTipoFechaBusqueda ] = useState('todos')
    const [ estados, setEstados ] = useState([])
    const [ loadingEstados, setLoadingEstados ] = useState(true)
    const [ incluyeEstadoExcel, setIncluyeEstadoExcel ] = useState(false)
	const [ conductor, setConductor ] = useState(false)
	const [ tipoServicio, setTipoServicio ] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [imagenes, setImagenes] = useState([]);
    const hide_title = typeof hideTitle !== "undefined" ? hideTitle : false
    const showFilter = typeof props.showFilter !== "undefined" ? props.showFilter : true
    const showSearch = typeof props.showSearch !== "undefined" ? props.showSearch : true
    const showExporter = typeof props.showExporter !== "undefined" ? props.showExporter : true
    const showAddButton = typeof props.showAddButton !== "undefined" ? props.showAddButton : true
    const upWhenClick = typeof props.upWhenClick !== "undefined" ? props.upWhenClick : true
    const interfaz_usuario = useSelector(state => state.esquema_tablas)
    const [ loadingCamposPersonalizados, setLoadingCamposPersonalizados ] = useState(true)
    const key_search = {
        "estados": "estado_entrega.codigo_estado",
        "subestados": "sub_estado_entrega.codigo_estado"
    }
    const key_placeholder = {
        "estados": "Filtrar por estado",
        "subestados": "Filtrar por sub estado"
    }
    const titulo = typeof props.titulo !== "undefined" ? props.titulo : "Órdenes"
    const screen = "ordenes"
    const dispatch = useDispatch()
    let query = useQuery();
    const location = useLocation()
    const navigate = useNavigate();
    let default_key = 'apikeys'
    if(query.get("tab")) default_key = query.get("tab")
    let identificador = `new_order_${session.data.propietario}`
    const selectRef = useRef(null)
    let identificador_colores_estados = `new_estado_${session.data.propietario}`
    let identificador_proveedor = `new_proveedor_${session.data.propietario}`
    console.log("i-p", identificador_proveedor)

    const getOrders = async (page, query, tipo_condicion, filtros_especiales)=>{
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }

        const controller = new AbortController();
        abortControllerRef.current = controller;

        setLoadingEquipos(true)
        if(query) if(trash === true) query.status = 'trash'
        let condicion = query ? query : condicion_busqueda
        return fetch(`${data.urlapi}/ordenes/list`,{
            method:'POST',
            body: JSON.stringify({
                condicion,
                tipo_condicion: tipo_condicion ? tipo_condicion : tipoCondicion,
                filtro_zona: filtroZona ? filtroZona._id : false,
                pagina: page,
                gestionadas: incluirGestionadas,
                filtros_especiales: filtros_especiales ? filtros_especiales : camposPersonalizados
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
            signal: controller.signal
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            if(Array.isArray(res.campos_personalizados) !== false){
                setCustomColumns(res.campos_personalizados)
            }
            console.log("res", res)
            return setLoadingEquipos(false)
        })
        .catch(error => {
            return setLoadingEquipos(false)
        })
    }

    const obtenerOrdenes = useCallback(debounce((page_, condition, type, filters) => getOrders(page_, condition, type, filters), 0), []);

    const clearFilters = () => {
        setFiltersActives([])
        setFiltroEstado([])
        setConductor(false)
        setFiltroZona(false)
        setTipoServicio(false)
        handleChangeCliente(false)
        setCamposPersonalizados({})
        setSelectionRange(initialDate)
        setTipoFechaBusqueda('todos')
        setCamposPersonalizados({})
        setCondicionBusqueda(condicion_inicial ? condicion_inicial : {})
    }
    const limpiarDatos = (key) => {
        if(key === "estado"){
            setFiltroEstado([])
        } else if(key === "vehiculo"){
            setConductor(false)
        } else if(key === "localidad"){
            setFiltroZona(false)
        } else if(key === "tipo-logistica"){
            setTipoServicio(false)
        } else if(key === "cliente"){
            handleChangeCliente(false)
        } else if(key === "campos_personalizados"){
            setCamposPersonalizados({})
        } else if(key === "canal"){
            setCondicionBusqueda(prev => {
                const actual = { ...prev }
                delete actual.canal
                return actual
            })
        } else if(key === "fecha"){
            setSelectionRange(initialDate)
            setTipoFechaBusqueda('todos')
        } else if( key === "campos_personalizados"){
            setCamposPersonalizados({})
        } else {
          setCamposPersonalizados((prev) => {
            let actual = {...prev}
            delete actual[key]
            return actual
          })
        }
    }


    const handleChangeCamposPersonalizados = (e, name) => {
        const { value } = e.target
        // if(props.onChange) props.onChange(value ? value.toString() : null, name)
        onChangeCustomField(value ? value.toString() : null, name)
    } 

    const removerFiltro = (val) => {
        setFiltersActives(prev => {
            const actual = [ ...prev ]
            return actual.filter(e => e !== val)
        })

        limpiarDatos(val)
    }


    const  renderizarCampoPersonalizado = (name) => {
       let indice = camposPersonalizadosOrden.findIndex(e => e.slug === name)
       if(indice < 0) return "no disponible"

       const campo = camposPersonalizadosOrden[indice]
  
       if(campo.type === "date") return <div key={campo._id} >
            {/* <label className="form-control-label d-block" >{campo.nombre}</label> */}
            <input type="date" className="form-control" name={campo.slug} onChange={(e) => handleChangeCamposPersonalizados(e, campo.slug)} />
        </div>

   const defaultSchema = camposPersonalizados
   const schema = defaultSchema ? defaultSchema : {}
   const opciones = Array.isArray(campo.options) ? campo.options : []
   const slug      = campo.slug.toString().toLowerCase()
   return <div key={campo._id} >
       {/* <label className="form-control-label d-block" >{campo.nombre}</label> */}
       <select className="form-control" name={campo.slug} defaultValue={schema[slug]} onChange={(e) => handleChangeCamposPersonalizados(e, campo.slug)}>
           <option value="">Seleccione</option>
           {
               opciones.map(opcion => {
                   const val = opcion.value.toString()
                   return <option key={`${val}-${campo.slug}`} value={val}>{val}</option>
               })
           }
       </select>
   </div>

    }

    const renderizarTotalPersonalizado = (name) => {
       let indice = camposPersonalizadosOrden.findIndex(e => e.slug === name)
       if(indice < 0) return "no disponible"

       const campo = camposPersonalizadosOrden[indice]
  
       if(campo.type === "date") return camposPersonalizados[campo.slug] ? 1 : 0

        const defaultSchema = camposPersonalizados
        const schema = defaultSchema ? defaultSchema : {}
        const slug      = campo.slug.toString().toLowerCase()
        const texto = schema[slug] ? schema[slug].toString() : "1"
        const lenght = 13
        return schema[slug] ? <Badge variant='dark'>{texto.length > lenght ? `${texto.substring(0,lenght)}...` : texto }</Badge> : 0

    }

    const mostrarTotalPor = (value) => {
        const opcionesCanales = Object.keys(canales).map(e => ({ value: e, label: canales[e].title }))

        const optionsDefaultChannels = condicion_busqueda.canal ? opcionesCanales.filter(e => condicion_busqueda.canal.$in.includes(e.value)) : []

        const component_localidad = <SelectorZonaAvanzadoFiltro condicion={{ local_name: "region" }} onChangeValue={(data) => handleChangeZona(data)} />           
        
        const mostrarFecha = () => {
            if(formatYMD(selectionRange.startDate) === formatYMD(selectionRange.endDate)) return fechaATextoSimpleCorto(selectionRange.startDate)
                return `${fechaATextoSimpleCorto(selectionRange.startDate)} - ${fechaATextoSimpleCorto(selectionRange.endDate)}`
        }

        const selected_picking_statuses = condicion_busqueda.status_picking ? opcionesPicking.filter(e => condicion_busqueda.status_picking.$in.includes(e.value)) : []

        const data = {
            estado: todosLosEstados.filter(e => filtroEstado.includes(e.codigo_estado)).map(e => ({ value: e.codigo_estado, label: e.titulo })).length,
            vehiculo: Array.isArray(conductor) ? conductor.length : 0,
            // localidad: component_localidad,
            "tipo-logistica": Array.isArray(tipoServicio) ? tipoServicio.length : 0,
            cliente: condicion_busqueda.id_cliente ? 1 : 0,
            canal: optionsDefaultChannels.length,
            fecha: `${tipoFechaBusqueda} ${tipoFechaBusqueda !== "todos" ? `(${mostrarFecha()})` : ''}`,
            picking: selected_picking_statuses.length ? selected_picking_statuses.length : 0,
            // campos_personalizados: component_campos_personalizados
        }

        if(!isNaN(parseInt(data[value]))){
            if(data[value] === 0) return false
            return <Badge variant='dark'>{data[value]}</Badge>
        } else if(typeof data[value] === "string"){
            return <Badge variant='dark'>{data[value]}</Badge>
        } else {
            return renderizarTotalPersonalizado(value)
        }

    }

    const mostrarContenidoPor = (value) => {

        const opcionesCanales = Object.keys(canales).map(e => ({ value: e, label: canales[e].title }))

        const optionsDefaultChannels = condicion_busqueda.canal ? opcionesCanales.filter(e => condicion_busqueda.canal.$in.includes(e.value)) : []


        const component_estado = <div>

            <Form.Group className="mb-1" controlId={"gestionadas"}>
                <Form.Check type="switch" name="gestionadas" label="Sin gestión" onChange={(e) => {
                    const val = e.target.checked
                    if(val === true){
                         setFiltroEstado([""])
                    } else {
                        setFiltroEstado([])
                    }
                    setSinEstado(val)
                }} />
            </Form.Group>
            <select className='form-control mb-2' disabled={sinEstado} value={tipoEstadoBusqueda} onChange={(e) => {
                setTipoEstadoBusqueda(e.target.value)
                obtenerEstados(null, e.target.value)
                if(selectRef?.current) selectRef?.current?.setValue([])
                }}>
                <option value="estados">Estado</option>
                <option value="subestados">Subestado</option>
            </select>

            <Select 
            ref={selectRef}
            value={todosLosEstados.filter(e => filtroEstado.includes(e.codigo_estado)).map(e => ({ value: e.codigo_estado, label: e.titulo }))}
            style={{ marginBottom: 10 }}
            onChange={handleChangeSelectEstado}
            isLoading={loadingEstados}
            isDisabled={disableStatuses || sinEstado}
            isMulti={true}
            options={estados}
            placeholder={key_placeholder[tipoEstadoBusqueda]}
            noOptionsMessage={()=>'Sin opciones'}
            />

            {/* <SelectorEstados defaultValue={null} isMulti={true} key={key_input} tipo_dato={tipoEstadoBusqueda} full_payload={true} titulo="Seleccionar sub estado" onChange={(e) => handleChangeSelectEstado(e)} /> */}

        </div>

        const component_vehiculo = <div>
            <Form.Group className="mb-1" controlId={"gestionadas"}>
                <Form.Check type="switch" name="gestionadas" label="Sin vehículo asignado (Flota)" onChange={(e) => {
                    const val = e.target.checked
                    if(val === true){
                         setConductor(["none"])
                    } else {
                        setConductor(false)
                    }
                    setSinEstado(val)
                }} />
            </Form.Group>
            <BuscadorVehiculos placeholderText="Filtra por vehículos" hideLabel={true} isMulti={true} token={token} onChange={(data) => onChangeConductor(data)} />
            </div>

        const component_localidad = <SelectorZonaAvanzadoFiltro condicion={{ local_name: "region" }} onChangeValue={(data) => handleChangeZona(data)} />   
        const component_tipo_logistica = <TiposServicioSelector multi={true} value={Array.isArray(tipoServicio) ? tipoServicio : null} onChangeValue={(data) => handleChangeTipoServicio(data)} />
        const component_cliente = <SelectorDestinatarios defaultValue={condicion_busqueda.id_cliente ? condicion_busqueda.id_cliente : ""} titulo="Cliente" onChange={handleChangeCliente} />
        const component_canal = <Select className='mb-3' defaultValue={optionsDefaultChannels} options={opcionesCanales} isMulti placeholder="Seleccione" onChange={(data) => {
            setCondicionBusqueda(prev => {
                const actual = { ...prev }
                if(data.length > 0){
                    actual.canal = { $in: data.map(e => e.value) }
                } else {
                    delete actual.canal
                }
                return actual
            })
        }} />
        
        const component_fecha = <div>
            <label className='d-block form-control-label'>Fecha</label>
                    <select name="tipo_fecha" className='form-control mb-4' value={tipoFechaBusqueda} onChange={handleChangeFechaBusqueda}  >
                        <option value="creacion">Creación</option>
                        <option value="actualizacion">Actualización</option>
                        <option value="maxima-entrega">Máxima fecha entrega</option>
                        <option value="todos">Cualquiera</option>
                </select>
                {
                    tipoFechaBusqueda !== "todos" ? <div className="mb-2">
                    <DateRange
                        locale={es}
                        // showSelectionPreview={true}
                        // editableDateInputs={true}
                        onChange={item => cambiarRangoFecha(item)}
                        moveRangeOnFirstSelection={false}
                        ranges={[selectionRange]}
                        // direction="vertical"
                        // scroll={{ enabled: true }}
                        months={1}
                        maxDate={new Date()}
                        // initialFocusedRange={{ startDate: new Date(), endDate: new Date() }}
                    />
                    </div> : false
                }
        </div>

        const component_campos_personalizados = <CamposPersonalizadosSelectores defaultSchema={camposPersonalizados} onChange={(e,name_field) => onChangeCustomField(e, name_field)} />

        const selected_picking_statuses = condicion_busqueda.status_picking ? opcionesPicking.filter(e => condicion_busqueda.status_picking.$in.includes(e.value)) : []
        const component_picking = <div>
            <Select className='mb-3' defaultValue={selected_picking_statuses} options={opcionesPicking} isMulti placeholder="Seleccione" onChange={(data) => {
            setCondicionBusqueda(prev => {
                const actual = { ...prev }
                if(data.length > 0){
                    actual.status_picking = { $in: data.map(e => e.value) }
                } else {
                    delete actual.status_picking
                }
                return actual
            })
        }} />
        </div>
        const data = {
            estado: component_estado,
            vehiculo: component_vehiculo,
            localidad: component_localidad,
            "tipo-logistica": component_tipo_logistica,
            cliente: component_cliente,
            canal: component_canal,
            fecha: component_fecha,
            picking: component_picking
            // campos_personalizados: component_campos_personalizados
        }
        if(data[value]){
            return data[value]
        } else {
            return renderizarCampoPersonalizado(value)
        }
    }

    const mostrarFiltros = () => {

        return [...filterAvailables, ...camposPersonalizadosOrden.map(e => ({value: e.slug, label: e.nombre}))].map(e => {
            if(filtersActives.includes(e.value)){
                return <Dropdown className='p-0 mr-2 mb-2' style={{ display: "inline-block" }}>
                <Dropdown.Toggle variant="light" id={`selector-${e.value}`} size="sm" style={{ textTransform: "uppercase" }} ><FaFilter size={11} /> {e.label} {mostrarTotalPor(e.value)}</Dropdown.Toggle>
                <Dropdown.Menu className='p-3'>
                    <Button size="sm" variant="light" className='w-100 mb-3' style={{ minWidth: 300 }} onClick={() => removerFiltro(e.value)} >REMOVER <IoMdClose style={estilo_last_mile.icon} size={18} className='hover' /></Button>
                    {mostrarContenidoPor(e.value)}
                </Dropdown.Menu>
              </Dropdown>
                return <Card size="sm" className='mr-2 hover' style={{ textTransform: "uppercase", display: "inline-block", verticalAlign: "middle", fontSize: 15, padding: "3px 10px", background: "white" }}>{e.label} <IoMdClose onClick={() => removerFiltro(e.value)} style={estilo_last_mile.icon} size={18} className='hover' /></Card>
            }
        })
    }

    
    const obtenerOrdenesAvanzado = async (valor, key_campo)=>{
        setLoadingEquipos(true)
        return fetch(`${data.urlapi}/ordenes/list/campo-personalizado`,{
            method:'POST',
            body: JSON.stringify({
                valor,
                key_campo,
                pagina: 1
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingEquipos(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingEquipos(false)
            }
            if(Array.isArray(res.datos) !== false){
                setEquipos(res.datos)
                setTotal(res.total)
            }
            return setLoadingEquipos(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingEquipos(false)
        })
    }

    const obtenerPedido = async (id) => {
        setLoadingDetalle(true)
        if(upWhenClick === true) window.scrollTo({top: 0, behavior: 'smooth'})
        setIdSeleccionado(id)
        return fetch(`${data.urlapi}/ordenes/details-full?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
                return setLoadingDetalle(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingDetalle(false)
            } else if(res.orden){
                setPedidoSeleccionado(res)
            }
            return setLoadingDetalle(false)
        })
        .catch(error => {
            setError(`Error al consultar la información: ${error.message}`)
            return setLoadingDetalle(false)
        })
    }

    const paginar = (page, ignorar, condicion) => {
        if (ignorar) return false;
        setPagina(page);
        obtenerOrdenes(page, condicion_busqueda, tipoCondicion, camposPersonalizados);
    }

    const handleClose = () => {
        setOpenModal(false)
        setShowModalBusquedaAvanzada(false)
    }

    const handleChangeSelectEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        console.log({opciones})
        return setFiltroEstado(opciones)
    }
    
    const handleChangeSelectSubEstado = (e) => {
        let opciones = []
        if(e){
            e.map(valor => {
                opciones.push(valor.value)
            })
        }
        return setFiltroSubEstado(opciones)
    }

    const cambiarRangoFecha = (item) => {
        console.log(item)
        return setSelectionRange(item.selection)
    }

    const handleChangeFechaBusqueda = (e) => {
        const { value } = e.target
        const newFecha = value;
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('fecha', newFecha);
        window.history.pushState({}, '', currentUrl.toString())
        return setTipoFechaBusqueda(value)
    }

    const handleChangeTipoServicio = (data) => {
        return setTipoServicio(data);
      }

    const handleChangeZona = (data) => {
        const newZona = data?._id;
        if(!newZona) return setFiltroZona(false);
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('filtro_zona', newZona);
        window.history.pushState({}, '', currentUrl.toString())
        return setFiltroZona(data);
      }

    const filtrar = () => {
        const { desde, hasta } = obtenerRangos(selectionRange.startDate, selectionRange.endDate)
        const fechaDesde = DateTime.fromJSDate(desde).toUTC().toISO()
        console.log(fechaDesde)
        const valor_tipo_servicio = Array.isArray(tipoServicio) ? { $in: tipoServicio.map(e => e.value) } : tipoServicio?._id

        if(tipoFechaBusqueda === 'creacion'){
            const condicion = { createdAt: { $gte: desde, $lte: hasta }, status: 'active', ...condicion_inicial }
            if(filtroEstado.length > 0) {
                condicion[key_search[tipoEstadoBusqueda]] = filtroEstado
                if(filtroEstado.length > 0) if(filtroEstado[0] === "") {
                    delete condicion[key_search[tipoEstadoBusqueda]]
                    condicion.estado_entrega = {  $in: [null,""] }
                }
            }
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            if (tipoServicio) condicion.tiposervicio = valor_tipo_servicio
            if(condicion_busqueda.canal) condicion.canal = condicion_busqueda.canal
            if(condicion_busqueda.id_cliente) condicion.id_cliente = condicion_busqueda.id_cliente
            if(condicion_busqueda.status_picking) condicion.status_picking = condicion_busqueda.status_picking
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion, tipoCondicion, camposPersonalizados)
            console.log("entro en crear")
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'actualizacion'){
            const condicion = { updatedAt: { $gte: desde, $lte: hasta }, status: 'active', ...condicion_inicial }
            if(filtroEstado.length > 0) {
                condicion[key_search[tipoEstadoBusqueda]] = filtroEstado
                if(filtroEstado.length > 0) if(filtroEstado[0] === "") {
                    delete condicion[key_search[tipoEstadoBusqueda]]
                    condicion.estado_entrega = {  $in: [null,""] }
                }
            }
            if(conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            if (tipoServicio) condicion.tiposervicio = valor_tipo_servicio
            if(condicion_busqueda.canal) condicion.canal = condicion_busqueda.canal
            if(condicion_busqueda.id_cliente) condicion.id_cliente = condicion_busqueda.id_cliente
            if(condicion_busqueda.status_picking) condicion.status_picking = condicion_busqueda.status_picking
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion,  tipoCondicion, camposPersonalizados)
            console.log("entro en actalizar")
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'maxima-entrega'){
            const condicion = { $or:[
                { fecha_min_entrega: { $gte: desde, $lte: hasta }, fecha_min_entrega: { $lte: desde }, ...condicion_inicial},
                { fecha_max_entrega: { $lte: hasta, $gte: desde }, fecha_min_entrega: { $lte: desde }, ...condicion_inicial},
                { fecha_max_entrega: { $gte: hasta }, fecha_min_entrega: { $lte: desde }, ...condicion_inicial }               
            ]
            }
            if (tipoServicio) condicion.tiposervicio = valor_tipo_servicio
            if(filtroEstado.length > 0) {
                condicion[key_search[tipoEstadoBusqueda]] = filtroEstado
                if(filtroEstado.length > 0) if(filtroEstado[0] === "") {
                    delete condicion[key_search[tipoEstadoBusqueda]]
                    condicion.estado_entrega = {  $in: [null,""] }
                }
            }
            if (conductor) condicion.idconductor = { $in: conductor.map(c => c._id) }
            if(condicion_busqueda.canal) condicion.canal = condicion_busqueda.canal
            if(condicion_busqueda.id_cliente) condicion.id_cliente = condicion_busqueda.id_cliente
            if(condicion_busqueda.status_picking) condicion.status_picking = condicion_busqueda.status_picking
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion,  tipoCondicion, camposPersonalizados)
            console.log("entro en maxi")
            return setOpenModal(false)
        } else if(tipoFechaBusqueda === 'todos'){
            let condicion = { ...condicion_inicial }
            if(tipoServicio) condicion.tiposervicio = valor_tipo_servicio
            if(filtroEstado.length > 0) {
                condicion[key_search[tipoEstadoBusqueda]] = filtroEstado
                if(filtroEstado.length > 0) if(filtroEstado[0] === "") {
                    delete condicion[key_search[tipoEstadoBusqueda]]
                    condicion.estado_entrega = {  $in: [null,""] }
                }
            }
            if (conductor){
                if(conductor[0] === "none"){
                    condicion.idconductor = { $in: ["",null] }
                } else {
                    condicion.idconductor = { $in: conductor.map(c => c._id) }
                }
            }
            if(condicion_busqueda.canal) condicion.canal = condicion_busqueda.canal
            if(condicion_busqueda.id_cliente) condicion.id_cliente = condicion_busqueda.id_cliente
            if(condicion_busqueda.status_picking) condicion.status_picking = condicion_busqueda.status_picking
            setCondicionBusqueda(condicion)
            setPagina(1)
            obtenerOrdenes(1, condicion,  tipoCondicion, camposPersonalizados)
            return setOpenModal(false)
        }
        const condicion = { ...condicion_inicial }
        if (tipoServicio) condicion.tiposervicio = valor_tipo_servicio

        obtenerOrdenes(1, condicion,  tipoCondicion, camposPersonalizados)
    }

    const handleCheck = (e) => {
        const valor = e.target.checked
        return setIncluyeEstadoExcel(valor)
    }
    
    const onChangeConductor = (e) => {
        setConductor(e)
    }
    const onChangeTipoServicio = (e) => {
        console.log('tipos servicio', e);
        //orden.tiposervicio = e._id
        //return setOrden(orden)
    }

    const handleChangeGestionadas = (e) => {
        setIncluirGestionadas(e.target.checked)
    }

    const modalBusquedaAvanzada = () => {
        const sub_estados = []
        return <Modal show={showModalBusquedaAvanzada} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Selecciona el campo con el cual deseas buscar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                    
            <Form.Group className="mb-1" controlId={"gestionadas"}>
                <Form.Check type="switch" checked={incluirGestionadas} name="gestionadas" label="Incluir órdenes gestionadas" onChange={handleChangeGestionadas} />
            </Form.Group>

            <SelectorCamposPersonalizados onChange={(data) => {
                const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario))
                instancia_esquema.campo_busqueda = data
                dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
                setCampoBusqueda(data)
            }} titulo="Selecciona el campo por el cual deseas buscar" extraOptions={[ { value: "", label: "Referencia" } ]} />

                                        
            <Button variant='link-dark' className='w-100' size="sm" onClick={() => handleClose()} ><BiArrowBack /> VOLVER</Button>
        </Modal.Body>
      </Modal>
    }

    const modalFiltro = () => {
        const sub_estados = []

        return <Modal show={openModal} size="lg" onHide={()=>handleClose()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Filtrar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Row>
                
                
                <Col md={12}>
                    <Button size="sm" variant="success" onClick={()=>filtrar()} >ACTUALIZAR DATOS</Button>
                </Col>
            </Row>
        </Modal.Body>
        
      </Modal>
    }

    const paginacion = (ciclo, total, registros) => {

        const cantidad = Math.ceil(total / ciclo)
        return <div>
            <Row>
                <Col xs={12}><h5 className='m-0' style={{ fontSize:13 }}>{(registros.length * pagina) - registros.length + 1} - {registros.length * pagina} <b style={{ fontWeight:'lighter' }}>de {total}</b></h5></Col>
                <Col xs={12}>
                <Pagination size='sm'>
                {
                    Array.from(Array(cantidad).keys()).map(number => {
                        const active = pagina === (number+1) ? true : false
                        return <Pagination.Item key={number+1} active={active} onClick={()=>paginar(number+1,active)} >{number+1}</Pagination.Item>
                    })
                }
            </Pagination>
                </Col>
            </Row>
        </div>
    }

    const obtenerEstados = async (defaultValue, tipoCampo) => {
        setLoadingEstados(true)
        return fetch(`${data.urlapi}/estadoscarga/details`,{
            method: "GET",
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            },
        })
        .then(pros => pros.json())
        .then(data => { 
            if(!data){
            } else if(data.errorMessage){
                setError(data.errorMessage)
                return this.setState({ loadingFormulario: false })
            } else if( Array.isArray(data.estados) !== false){
                // setEstados(data.estados.map(e => ({ value: e.codigo_estado, label: e.titulo })))
                
                const campoBusqueda = tipoCampo ? tipoCampo : tipoEstadoBusqueda
                const registros = data[campoBusqueda]
                let todas_los_estados = []
                let opciones = data.agrupaciones
                if(campoBusqueda === "subestados"){
                    let opciones_agrupadas = []
                    for( const statuse of data.estados ){
                        const opcion = {
                            label: statuse.titulo,
                            options: data[campoBusqueda].filter(est => est.idparent === statuse._id).map(e => ({ value: e.codigo_estado, label: e.titulo }))
                        }
                        opciones_agrupadas.unshift(opcion)
                        todas_los_estados = [...todas_los_estados, ...opcion.options]
                    }
                    opciones = opciones_agrupadas
                }
                setTodosLosEstados(registros)
                setTimeout(() => {
                    if(defaultValue){
                        if(selectRef.current){
                            if(Array.isArray(defaultValue)){
                                const valores = todas_los_estados.map(e => ({ value: e.codigo_estado, label: e.titulo })).filter(e => defaultValue.includes(e.value))
                                
                                if(valores.length > 0){
                                    setTimeout(() => obtenerOrdenes(1, { [ key_search[campoBusqueda] ]: valores.map(e => e.value) }), 1500);
                                    setDisableStatuses(true)
                                }

                                selectRef.current?.setValue(valores)
                            } else {
                                const pos = registros.findIndex(e => e._id === defaultValue)
                                if(pos > -1){
                                    const value_search = registros[pos].codigo_estado
                                    const i = todas_los_estados.findIndex(e => e.value === value_search)
                                    if( i > -1 ){
                                        selectRef.current.setValue(value_search)
                                    }
                                }
                            }
                        }
                    }
                }, 100);

                setEstados(opciones)
            }
            return setLoadingEstados(false)
        })
        .catch(error => {
            setError("Error al consultar la información")
            return setLoadingEstados(false)
        })
    }

    const recibirNuevaOrden = (nuevo) => {
        setEquipos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(e => e._id === nuevo._id)
            if(i < 0) {
                actual.unshift({ ...nuevo, unread: true })
            }
            return [ ...actual ]
        })
    }

    const cambiarColorEstado = (nuevo) => {
        setEquipos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(e => e._id === nuevo.idrecurso)
            if(i > -1) {
                actual[i].estado_entrega = nuevo?.estado_entrega
                actual[i].sub_estado_entrega = nuevo?.sub_estado_entrega
            }
            return [ ...actual ]
        })
    }

    const canales = {
        "pedidosya": {
            title: "Pedidos Ya",
            logo: `${data.url_front_produccion}/images/pedidos-ya.png`
        },
        "uber": {
            title: "Uber",
            logo: `${data.url_front_produccion}/images/uber.png`
        },
        "rappi": {
            title: "Rappi",
            logo: `${data.url_front_produccion}/images/rappi.png`
        }
    }
    
    const getCanal = (key) => {
        if(canales[key]){
            return canales[key].logo
        }
        return ''
    }

    const cambiarProveedor = (nuevo) => {
        console.log("cambio proveedor", nuevo)
        setEquipos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(e => e._id === nuevo.idrecurso)
            if(i > -1) {
                const logo = getCanal(nuevo.proveedor)
                console.log("logo", logo)
                actual[i].proveedor_logo = logo
            }
            return [ ...[], ...actual ]
        })
    }

    const build_query_accion_slug = (slug) => {
        switch (slug) {
            case "conductor":
                return { idconductor: { $nin: ["", null ]} }
            case "picking-pendiente":
                return { status_picking: { $in: ["PENDIENTE", null, ""]} }
            case "picking-iniciado":
                return { status_picking: "EN PROCESO" }
            case "picking-listo":
                return { status_picking: "LISTO" }
            case "no-gestionadas":
                return { "estado_entrega.titulo": { $exists: false } }
            default:
                return {}
        }
    }

    const construirQueryBasadoEnAccionEspecial = (acciones) => {
        let construir_condicion = {}
        for( const accion of acciones ){
            const { value } = accion
            const query = build_query_accion_slug(value)
            construir_condicion = { ...construir_condicion, ...query }
        }
        return construir_condicion
    }
    const obtenerEspacioTrabajo = async ()=>{
        if(!allowWorkSpace){
            const cond = condicion_default ? condicion_default : condicion_busqueda
            obtenerOrdenes(1, cond, null)
            return obtenerEstados()
        }
        return fetch(`${data.urlapi}/flujos/trabajo/user`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            let statuses = []
            let filters_default = []
            let filtros_especiales = []
            let cond = {}
            let customFields_ = {}
            if(res?._id){
                const i = res.fases.findIndex(f => {
                    const usuarios = Array.isArray(f.usuarios) ? f.usuarios : []
                    const pos = usuarios.findIndex(u => u.email === session?.data?.email)
                    return pos > -1
                })
                if( i > -1 ) {
                    if(res.fases[i].estados) if(Array.isArray(res.fases[i].estados)) if(res.fases[i].estados.length > 0) {
                        statuses = res.fases[i].estados.map(e => e.codigo_estado)
                        filters_default.push("estado")
                        cond["estado_entrega.codigo_estado"] = { $in: statuses }
                    }
                    if(Array.isArray(res.fases[i].tipos_servicio) && res.fases[i].tipos_servicio.length > 0){
                        setTipoServicio(res.fases[i].tipos_servicio)
                        filters_default.unshift('tipo-logistica')
                        cond.tiposervicio = { $in: res.fases[i].tipos_servicio.map(e => e.value) }
                    }
                    setFiltroEstado(statuses)
                    if(res.fases[i].esquema) if(Array.isArray(res.fases[i].esquema)) if(res.fases[i].esquema.length > 0){
                        const fase_actual = res.fases[i]
                        if(Array.isArray(fase_actual.acciones_especiales)) if(fase_actual.acciones_especiales.length > 0){
                            filtros_especiales = fase_actual.acciones_especiales
                            const first_query = construirQueryBasadoEnAccionEspecial(fase_actual.acciones_especiales)
                            cond = first_query
                            if(fase_actual.customFields) {
                                setCamposPersonalizados(fase_actual.customFields)
                                customFields_ = fase_actual.customFields
                            }
                        }
                        setEspacioTrabajo(fase_actual)
                        setFasesTrabajo(res.fases)
                    }
                }
            }
            setFiltersActives(filters_default)
            setCondicionBusqueda(cond)
            obtenerOrdenes(1, cond, null, customFields_)
            obtenerEstados()
        })
        .catch(error => {
            obtenerOrdenes(1, condicion_inicial)
            console.log(error.message)
        })
    }

    const obtenerCamposPersonalizados = async () => {
        return fetch(`${data.urlapi}/ordenes/campos-personalizados`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                setError('Sin datos')
            } else if(res.errorMessage){
                setError(res.errorMessage)
            } else if(Array.isArray(res) !== false){
                setCamposPersonalizadosOrden(res)
            }
            return setLoadingCamposPersonalizados(false)
        })
        .catch(error => {
            setLoadingCamposPersonalizados(false)
            return setError(error.message)
        })
    }


    useEffect(() => {
        obtenerCamposPersonalizados()
        obtenerEspacioTrabajo()
        const id_pedido = query.get("id")
        if(id_pedido){
            obtenerPedido(id_pedido)
        }
        socket.on(identificador, nuevo => {
            return recibirNuevaOrden(nuevo)
        })
        socket.on(identificador_colores_estados, nuevo => {
        return cambiarColorEstado(nuevo)
        })
        socket.on(identificador_proveedor, nuevo => {
            console.log("socket")
        return cambiarProveedor(nuevo)
        })
        return () => {
            socket?.off(identificador)
            socket?.off(identificador_colores_estados)
            socket?.off(identificador_proveedor)
        }

    }, [ refresh ]) // eslint-disable-line react-hooks/exhaustive-deps

    const cargandoScreen = () => {
        return <LoadingOrders />
    }


    const descargarReporteExcel = async () => {
        setLoadingExcel(true)
        return fetch(`${data.urlapi}/reporte/ordenes`,{
            method:'POST',
            body: JSON.stringify({
                condicion: condicion_busqueda,
                incluir_estados: incluyeEstadoExcel
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.blob()
        })
        .then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            const fecha = obtenerFechaHoraZonaHorariaLocal()
            a.download = `reporte-${fecha}.xlsx`;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();    
            a.remove();
            return setLoadingExcel(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingExcel(false)
        })
    }

    
    
    const mostrarConductor = (orden) => {
        if(!orden.conductor) return false
        if(typeof orden.conductor !== "object") return false
        return orden.conductor.nombres
    }
    
    const build_icon_picking = (slug) => {
        switch (slug.toString().toUpperCase()) {
            case "PENDIENTE":
                return <FaRegClock />
            case "EN PROCESO":
                return <FiBox />
            case "LISTO":
                return <FaCheckCircle />
            default:
                return <FaRegClock />
        }
    }

    const build_color_picking = (slug) => {
        switch (slug.toString().toUpperCase()) {
            case "PENDIENTE":
                return estilo_last_mile.color_secondary
            case "EN PROCESO":
                return estilo_last_mile.color_primary
            case "LISTO":
                return estilo_last_mile.color_success
            default:
                return estilo_last_mile.color_gray
        }
    }



    const refrescarEstadoPicking = (data) => {
        const {
            id_orden,
            status
        } = data
        setEquipos(prev => {
            let actual = [ ...prev ]
            const i = actual.findIndex(e => e._id === id_orden)
            if(i > -1){
                actual[i].status_picking = status
            }
            return [ ...actual ]
        })
    }

    const handleShowModal = (imagenes) => {
        setImagenes(imagenes);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const renderizarComponente = (key, esquema, orden, typeView, color_estado, string_estado, a_tiempo, estilo_last_mile) => {
        const renderCustomField = (slug, order) => {
            const estilo_ = { textAlign: "center"}
            if (espacioTrabajo?.allowCustomFields === true) return false;
            let campos = order.custom_fields ? orden.custom_fields : [];
            if (!Array.isArray(campos)) campos = [];
            const i = campos.findIndex(e => e.key === slug);
            const valor = i > -1 ? campos[i].value : false
            const i_detalles_campo = camposPersonalizadosOrden.findIndex(e => e.slug === slug)

            const encontrado = camposPersonalizadosOrden[i_detalles_campo]
            if (encontrado?.type === "select") {
                if (valor) {
                    const options = encontrado.options;
                    const i = options.findIndex(e => e.value.toString().toUpperCase() === valor.toString().toUpperCase());
                    if (i > -1) {
                        if (options[i].image) {
                            return (
                                <div style={estilo_}>
                                    {/* <span className='text-uppercase' style={{fontSize:10}}>{valor}</span> */}
                                    <img src={options[i].image} style={{ width: "100%", maxWidth: 70, maxHeight: 30, objectFit: "contain" }} />
                                </div>
                            );
                        }
                        if (options[i].color) {
                            return <div style={{ height: "100%", color: "white", alignContent: "center", backgroundColor: options[i].color,textTransform: "uppercase", textAlign: "center", padding: "5px 10px" }}>
                               {valor}
                            </div>;
                        }
                    }
                }
            }

            return <div style={{ height: "100%", width: "100%", padding: "5px 10px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", background: i > -1 ? "white" : "#bebebe", color: i > -1 ? "black" : "white" }}>
                {(i > -1 ? campos[i].value : "SIN DATOS")}
                </div>;
        };
    
        const estilo_ = { height: "100%", width: "100%", padding: "5px 10px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" };
        switch (key) {
            case "pedido":
                return <div style={estilo_}><b>{showByType(typeView, orden)}</b></div>;
            case "tipo_servicio":
                return <div style={estilo_}>{orden.tipo_servicio ? orden.tipo_servicio : <TagSinDatos />}</div>;
            case "status":
                return <div style={{...estilo_, backgroundColor: color_estado, color: 'white' }}>{string_estado.toUpperCase()}</div>;
            case "tickets":
                return <div style={estilo_}><DetailFullModalTicket id_orden={orden._id} tamaño={12} /></div>;
            case "destinatario":
                return <div style={estilo_}>{getNombreDestinatario(orden)}</div>;
            case "phone":
                return <div style={estilo_}>{getTelefono(orden)}</div>;
            case "email":
                return <div style={estilo_}>{getEmail(orden)}</div>;
            case "direccion":
                return <div style={estilo_}>{getDireccion1(orden)}</div>;
            case "volumen":
                return <div style={estilo_}>{orden.volumen}</div>;
            case "peso":
                return <div style={{ height: "100%", width: "100%", padding: "5px 10px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", background: orden.peso ? "white" : "#bebebe", color: orden.peso ? "black" : "white" }}>
                {orden.peso ? orden.peso : "SIN DATOS"}
                </div>;
            case "bultos":
                return <div style={{ height: "100%", width: "100%", padding: "5px 10px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", background: orden.bultos ? "white" : "#bebebe", color: orden.bultos ? "black" : "white" }}>
                {orden.bultos ? orden.bultos : "SIN DATOS"}
                </div>
            case "ruta":
                return <div style={estilo_}>{mostrarRuta(orden)}</div>;
            case "proveedor":
                return <div style={estilo_}>{orden.proveedor_logo ? <img src={orden.proveedor_logo} style={{ width: "100%", maxWidth: 70, maxHeight: 30 }} /> : orden.proveedor}</div>;
            case "compromiso":
                return <div style={{ color: a_tiempo ? estilo_last_mile.color_success : estilo_last_mile.color_danger }}>{a_tiempo ? <AiFillCheckCircle /> : <AiFillClockCircle />} {a_tiempo ? "A TIEMPO" : "RETRASADA"}</div>;
            case "fecha_compromiso_desde":
                return <div style={estilo_}>{fechaATextoSimple(orden.fecha_min_entrega)}</div>;
            case "fecha_compromiso_hasta":
                return <div style={estilo_}>{fechaATextoSimple(orden.fecha_max_entrega)}</div>;
            case "fecha_entregado":
                return <div style={estilo_}>{orden.fecha_finalizado ? fechaATextoSimple(orden.fecha_finalizado) : "NO DISPONIBLE"}</div>;
            case "conductor":
                return <div style={estilo_}>{mostrarConductor(orden)}</div>;
            case "conductor_asignado":
                return <div style={estilo_}>{orden.idconductor ? "SI" : "NO"}</div>;
            case "visible":
                return <div style={estilo_}>{orden.show_on_app ? "SI" : "NO"}</div>;
            case "finalizado":
                return <div style={estilo_}>{orden.finalizado ? "SI" : "NO"}</div>;
            case "pais":
                const pais = obtenerGeoValor(orden, 'pais')
                return <div style={{ height: "100%", width: "100%", padding: "5px 10px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", background: pais ? "white" : "#bebebe", color: pais ? "black" : "white" }}>
                {pais ? pais : "SIN DATOS"}
                </div>
            case "region":
                const region = obtenerGeoValor(orden, 'level1')
                return <div style={{ height: "100%", width: "100%", padding: "5px 10px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", background: region ? "white" : "#bebebe", color: region ? "black" : "white" }}>
                {region ? region : "SIN DATOS"}
                </div>
            case "comuna":
                const val = obtenerGeoValor(orden, 'level3')
                return <div style={{ height: "100%", width: "100%", padding: "5px 10px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center", background: val ? "white" : "#bebebe", color: val ? "black" : "white" }}>
                {val ? val : "SIN DATOS"}
                </div>
            case "canal":
                const canal = orden.canal ? orden.canal : "web";
                let record = canales[canal] ? canales[canal] : canales["web"];
                let img_url = record?.logo ? <img src={record?.logo} style={{ width: "100%", minWidth: 80 }} /> : <h5><b>WEB</b></h5>;
                return <div style={estilo_}>{img_url}</div>;
            case "longitud":
                return <div style={estilo_}>{mostrarCoordenadas(orden.location, 1)}</div>;
            case "latitud":
                return <div style={estilo_}>{mostrarCoordenadas(orden.location, 0)}</div>;
            case "reintentos":
                return <div style={estilo_}>{calcularReintentos(orden.intentos)}</div>;
            case "origen_direccion":
                return <div style={estilo_}>{orden?.orden?.origen?.first_name ? orden?.orden?.origen?.first_name : <TagSinDatos />}</div>;
            case "origen_pais":
                return <div style={estilo_}>{obtenerGeoValor(orden, 'pais', 'origen')}</div>;
            case "origen_region":
                return <div style={estilo_}>{obtenerGeoValor(orden, 'level1', 'origen')}</div>;
            case "origen_comuna":
                return <div style={estilo_}>{obtenerGeoValor(orden, 'level3', 'origen')}</div>;
            case "fecha":
                return <div style={estilo_}>
                    <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >{fechaATexto(orden.createdAt)}</Tooltip>
                            }
                            >
                            <Moment fromNow>{orden.createdAt}</Moment>
                            </OverlayTrigger>
                    </div>;
            case "campos_personalizados":
                return <div style={estilo_}><ModalPersonalizado customComponent={<div><FaEdit /> MÁS DATOS</div>} component={<MetaDatosOrden id_orden={orden._id} onSave={(data) => onSaveCustomFields(data)} />}  /> </div>;
            case "picking":
                return <div style={{ ...estilo_, backgroundColor: orden.status_picking ? build_color_picking(orden.status_picking) : "transparent", color: orden.status_picking ? "white" : "black" }}>
                    <ModalPersonalizado title="PICKING" customComponent={<div>{orden.status_picking ? <div style={{ width: "center", textAlign: "center", height: "100%", textTransform: "uppercase" }}>{build_icon_picking(orden.status_picking)} {orden.status_picking}</div> : "PICKING"}</div>}  component={<PickingOrden onRefreshStatus={(data) => refrescarEstadoPicking(data)} id_orden={orden._id} />}  />
                    </div>;
            case "despacho":
                return <div style={estilo_}><ModalPersonalizado customComponent={<div><FaTruckRampBox /> DESPACHO</div>} component={<DespachoOrden id_orden={orden._id} onConfirm={(e) => setFieldToOrder(e, orden._id)} />}  /> </div>;
            default:
                return customColumns.map(esq => {
                    if (esq.slug !== key) return null;
                    return renderCustomField(esq.slug, orden);
                })
        }
    };


    const mostrarRuta = (orden) => {
        if(!orden.ruta) return false
        if(typeof orden.ruta !== "object") return false
        return orden.ruta.id
    }

    const activarDesactivarTabla = (value, column) => {
        const instancia_esquema = JSON.parse( JSON.stringify(interfaz_usuario) )
        const i = instancia_esquema[screen].findIndex(e => e.key === column.key)
        if(i > -1){
            instancia_esquema[screen][i].active = value
        } else {
            instancia_esquema[screen].push({
                ...column,
                active: value
            })
        }
        return dispatch(modificarEsquema(instancia_esquema, session.tokenSession))
    }

    const seleccionarRegistro = data => {
        if(props.onSelect) props.onSelect(data)
    }

    const seleccionarRegistros = () => {
        const orders = equipos.filter(e => selectedOrders.includes(e._id))
        if(props.onSelect) props.onSelect(orders)
    }

    const showByType = (tipo, data) => {
        switch (tipo) {
            case "funcion":
                return <h6 style={{ fontWeight: "bold"}} className='hover' onClick={() => seleccionarRegistro(data)}>{data.pedido}</h6>
            default:
                return <Link to={`/ordenes/${data._id}`}>{data.pedido}</Link>
        }
    }

    const mostrarAcciones = () => {
        if(selectedOrders.length < 1) return false
        return <div style={{ textAlign: 'right' }}>
            <ImprimirMasivo ids_orders={selectedOrders}/>
            <AsignarRutaMasivo ids_orders={selectedOrders} onFinish={() => filtrar()} />
            <GeneradorEtiquetasPersonalizado ids_orders={selectedOrders} />
            { typeView === "funcion" ? <Button variant="secondary" onClick={() => seleccionarRegistros()}>SELECCCIONAR</Button> : false }
        </div>
    }

    const calcularReintentos = (intentos) => {
        let total = 0
        if(intentos) total = parseInt(intentos)
        if(isNaN(intentos)) total = 0
        return Math.max(0, (total-1))
    }
    
    const mostrarCoordenadas = (coord, pos) => {
        const sindatos =  "Sin datos"
        if(!coord) return sindatos
        if(typeof coord !== "object") return sindatos
        if(!coord.coordinates) return sindatos
        if(!Array.isArray(coord.coordinates)) return sindatos
        if(coord.coordinates.length < 2) return sindatos
        return coord.coordinates[pos]
    }

    const setFieldToOrder = (data, id) => {
        return setEquipos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(e => e._id === id)
            if(i > -1){
                actual[i] = { ...actual[i], ...data }
            }
            return [ ...actual ]
        })
    }


    const onSaveCustomFields = ({ id_orden, campos }) => {
        console.log({ id_orden, campos })
        setEquipos(prev => {
            let actual = [...prev]
            const i = actual.findIndex(e => e._id === id_orden)
            if( i > -1 ) actual[i].custom_fields = campos.map(e => ({ key: e.slug, ...e }))
            return [ ...actual ]
        })
    }

    const tablaRegistros = (registros) => {
        if(loadingEquipos===true) return cargandoScreen()

        if(registros.length < 1) return <div style={{ textAlign: "center" }}><HelperModal tipo="ordenes" style="simple" /></div>

        if(typeView === "simple") return <TablaOrdenesStatic typeView={typeView} ordenes={registros} />

        let esquema = []

        if(espacioTrabajo.esquema) if(Array.isArray(espacioTrabajo.esquema)) if(espacioTrabajo.esquema.length > 0) esquema = espacioTrabajo.esquema
        const esquema_interfaz = esquema.length > 0 ? esquema : interfaz_usuario.ordenes

        return <>
        {mostrarAcciones()}
        {paginacion(data.pagina, total, registros)}
        <Card>
        <div style={{ overflowX: 'auto' }}>
        <Table bordered border="1px solid white" style={{ minWidth: '100%', tableLayout: 'auto' }}>
            <thead>
                <tr style={{ backgroundColor: "#f2f1f1", textTransform: "uppercase", color: "#8c8c8c", padding:0 }}>
                    <td>
                        <input 
                            type="checkbox" 
                            onChange={(e) => setSelectedOrders(e.target.checked ? registros.map(orden => orden._id) : [])}
                            checked={registros.length > 0 && selectedOrders.length === registros.length}
                        />
                    </td>
                    {
                        esquema_interfaz.map(esq => {
                            if(!esq.active) return false
                            return <td style={{ border: "none", whiteSpace: 'nowrap' }}>{esq.label ? esq.label : esq.nombre} 
                            <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Toca para ocultar esta columna de la vista</Tooltip>
                            }
                            >
                            <AiFillEyeInvisible onClick={() => activarDesactivarTabla(false, esq)} className='hover ml-2' style={{ fontSize: 12 }} />
                            </OverlayTrigger>
                             </td>
                        })
                    }
                </tr>
            </thead>
            <tbody>
            {
                registros.map(orden => {

                let titulo_estado           = ''
                let titulo_sub_estado       = ''
                let color_estado            = '#bebebe'

                if(orden.estado_entrega){
                    if(typeof orden.estado_entrega === 'object'){
                        if(orden.estado_entrega.titulo) titulo_estado = orden.estado_entrega.titulo
                        if(orden.estado_entrega.color) color_estado = orden.estado_entrega.color
                    }
                }
            
                if(orden.sub_estado_entrega){
                    if(typeof orden.sub_estado_entrega === 'object'){
                        if(orden.sub_estado_entrega.titulo) titulo_sub_estado = orden.sub_estado_entrega.titulo
                    }
                }

                let string_estado = `${titulo_estado} ${titulo_sub_estado}`
                if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"

                const a_tiempo = esimacionFechas(orden)

                return <tr key={orden._id} className="mb-3" style={{ backgroundColor: orden.unread === true ? "#d3e5e7" : "", height: "100%", fontSize: 13 }} onMouseEnter={() => {
                    if(orden.unread === true){
                        setEquipos(prev => {
                            let actual = [...prev]
                            const i = actual.findIndex(e => e._id === orden._id)
                            if( i > -1 ) actual[i].unread = false
                            return [ ...actual ]
                        })
                    }
                }} >
                        <td>
                            <input 
                                type="checkbox" 
                                checked={selectedOrders.includes(orden._id)}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedOrders(prev => [...prev, orden._id]);
                                    } else {
                                        setSelectedOrders(prev => prev.filter(id => id !== orden._id));
                                    }
                                }}
                            />
                        </td>
                        {
                            esquema_interfaz.map(esq => {
                                if(!esq.active) return false
                                return <td style={{ padding: 0, whiteSpace: "nowrap", verticalAlign: "middle", height: "100%", border: "1.5px solid #f8f8f8" }}>{renderizarComponente(esq.key ? esq.key : esq.slug, null, orden, typeView, color_estado, string_estado, a_tiempo, estilo_last_mile)}</td>
                            })
                        }
                    </tr>
                })
            }
        </tbody>
        </Table>
        </div>
        </Card>
        {paginacion(data.pagina, total, registros)}
        </>
    }

    const listadoOrdenes = (registros) => {

        return <div>
            { /** <Joyride styles={{options:virtual_tour_settings.estilo}} continuous={true} showProgress={true} steps={virtual_tour_settings.pasos_ordenes} locale={virtual_tour_settings.locale} /> */ }
            {buscador()}
            {tablaRegistros(registros)}
        </div>
    }

    

    const handleSubmitReferencia = (e) => {
        e.preventDefault()
        if(!pedidoBusqueda){
            const condicion = { status: 'active' }
            setCondicionBusqueda(condicion)
            setPagina(1)
            return obtenerOrdenes(1, condicion)
        }

        if(interfaz_usuario.campo_busqueda){
            if(typeof interfaz_usuario.campo_busqueda === "object" ){
                if(interfaz_usuario.campo_busqueda.value){
                    setPagina(1)
                    return obtenerOrdenesAvanzado(pedidoBusqueda, interfaz_usuario.campo_busqueda.value)
                }
            }
        }


        const condicion = { pedido: pedidoBusqueda.toLowerCase(), status: 'active' }
        setCondicionBusqueda(condicion)
        setPagina(1)
        return obtenerOrdenes(1, condicion)
    }


    const abrirFiltro = () => {
        return setOpenModal(true)
    }

    const handleChangeCliente = (e) => {
        console.log(e)
        // if(!e) if(condicion_busqueda.id_cliente) delete condicion_busqueda.id_cliente
        // if(typeof e !== "object") if(condicion_busqueda.id_cliente) delete condicion_busqueda.id_cliente
        // if(!e.value) if(condicion_busqueda.id_cliente) delete condicion_busqueda.id_cliente
        // if(e.value) condicion_busqueda.id_cliente = e.value
        return setCondicionBusqueda(prev => {
            let actual = { ...prev }
            if(!e) if(actual.id_cliente) delete actual.id_cliente
            if(typeof e !== "object") if(actual.id_cliente) delete actual.id_cliente
            if(!e.value) if(actual.id_cliente) delete actual.id_cliente
            if(e.value) actual.id_cliente = e.value
            console.log(actual)
            return actual
        })
    }
    const handleChangePedido = (e) => {
        const { value } = e.target
        return setPedidoBusqueda(value)
    }


    const onFilter = (nueva_condicion, tipo_condicion) => {
        setCondicionBusqueda(nueva_condicion)
        setPagina(1)
        setTipoCondicion(tipo_condicion)
        obtenerOrdenes(1, nueva_condicion, tipo_condicion)
    }


    const borrarFiltrosUrl = () => {
        clearFilters()
        setFiltroZona(false)
        const condicion = condicion_default ? condicion_default : {}
        return obtenerOrdenes(1, condicion)
    }

    const abrirModalBusquedaAvanzada = () => {
        setShowModalBusquedaAvanzada(true)
    }

    const definirEspacioTrabajo = (titulo) => {
        const i = fasesTrabajo.findIndex(e => e.titulo === titulo)
        if(i > -1){
            setEspacioTrabajo(fasesTrabajo[i])
            let statuses = fasesTrabajo[i].estados.map(e => e.codigo_estado)
            const fase_actual = fasesTrabajo[i]
            const valores = todosLosEstados.map(e => ({ value: e.codigo_estado, label: e.titulo })).filter(e => statuses.includes(e.value))
                                
            if(valores.length > 0){
                setTimeout(() => obtenerOrdenes(1, { [key_search[tipoEstadoBusqueda]]: statuses }), 100);
                setDisableStatuses(true)
            }

            if(fase_actual.customFields) {
                setCamposPersonalizados(fase_actual.customFields)
                obtenerOrdenes(1, null, null, fase_actual.customFields)
            }

            selectRef.current?.setValue(valores)

        }
    }

    const borrarOpcion = i => {
        setEspacioTrabajo(prev => {
            let actual = { ...prev }
            actual.acciones_especiales.splice(i, 1)
            const first_query = construirQueryBasadoEnAccionEspecial(actual.acciones_especiales)
            obtenerOrdenes(1, first_query, null, actual.acciones_especiales)
            return actual
        })
    }
   
    const selectorFasesTrabajo = () => {
        if(fasesTrabajo.length < 1) return false

        let automatizaciones = Array.isArray(espacioTrabajo?.acciones_especiales) ? espacioTrabajo?.acciones_especiales.map(e => e.label) : []

        return <div className='mb-3'>
            <Row>
                <Col md={3}>
                    <label className='form-control-label'>Fase de trabajo <div style={{ display: "inline" }}><ModalFasesTrabajo fases={fasesTrabajo} /></div> </label>
                    <select name="fase_trabajo" className='form-control' value={espacioTrabajo?.titulo} onChange={(e) => definirEspacioTrabajo(e.target.value)} >
                        {
                            fasesTrabajo.map(e => <option key={e.titulo} value={e.titulo}>{e.titulo}</option>)
                        }
                    </select>
                </Col>
                <Col md={9}>
                    <label className='form-control-label d-block'>Automatizaciones definidas para este espacio de trabajo</label>
                    {automatizaciones.length < 1 ? <h4>Sin automatizaciones asignadas</h4> : false }
                    {automatizaciones.map((e,i) => <Badge  key={i} variant="dark" className='mr-2 mb-2' style={{ textTransform: "uppercase" }}>{e} <IoClose onClick={() => borrarOpcion(i)} className='hover' color='white' /> </Badge>)}
                </Col>
            </Row>
        </div>
    }

    const onChangeCustomField = (e , slug_field) => {
        setOpenModal(false)
        setCamposPersonalizados(prev => {
            let actual = { ...prev }
            actual[slug_field] = e
            if(!e) delete actual[slug_field]
            obtenerOrdenes(1, null, null, actual)
            return actual
        })
    }

    const addFilter = (filtro) => {
        setFiltersActives(prev => {
            let actual = [...prev]
            actual.push(filtro)
            setTimeout(() => {
                const selector = document.getElementById(`selector-${filtro}`)
                if(selector){
                    selector.click()
                }
            }, 100)
            return [ ...actual ]
        })
    }
    
    const showMoreFilters = () => {
        return <Dropdown className='p-0 mr-2' style={{ display: "inline-block" }}>
        <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-basic"><FaPlus size={13} /> AGREGAR FILTRO</Dropdown.Toggle>
        <Dropdown.Menu>
          {
            filterAvailables.map(e => {
                return filtersActives.includes(e.value) ? false : <Dropdown.Item key={e.value} style={{ textTransform: "uppercase", fontSize: 14 }} onClick={() => addFilter(e.value)}>{e.label}</Dropdown.Item>
            })
          }
         {
             camposPersonalizadosOrden.filter(e => ["select","date"].includes(e.type)).map(e => ({value: e.slug, label: e.nombre})).map(e => {
                return filtersActives.includes(e.value) ? false : <Dropdown.Item key={e.value} onClick={() => addFilter(e.value)} style={{ textTransform: "uppercase", fontSize: 14 }}>{e.label}</Dropdown.Item>
            })
          }
        </Dropdown.Menu>
      </Dropdown>
    }
    const buscador = () => {
        return showSearch === true ? <Row> 
            
            <Col Col md={modal ? 4 : 3} className='mb-2'>
                <form onSubmit={handleSubmitReferencia}>
                <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip >Toca para configurar tu campo de búsqueda</Tooltip>
                            }
                            >
                <div style={{ position: "absolute", right: 26, top: 1 }} className='hover' onClick={() => abrirModalBusquedaAvanzada()} ><FiSettings /></div>
                </OverlayTrigger>
                <input style={{ height: 30, padding: "2px 10px", fontSize: 13 }} className='mb-3 w-100 delpa-buscador' placeholder={`BUSCAR POR ${interfaz_usuario.campo_busqueda ? interfaz_usuario.campo_busqueda.label.toUpperCase() : "REFERENCIA"}`} onChange={handleChangePedido} />
                </form>
            </Col>
            { espacioTrabajo ? <Col Col md={modal ? 4 : 2} className='mb-2'><Button variant='light' size="sm" className='w-100' disabled ></Button></Col> : <Col Col md={modal ? 4 : 2} className='mb-2'><PersonalizarColumnas screen="ordenes" columns={customColumns} /></Col> }

            {
                showExporter === true ? <Col md={3} className='mb-2'>
                    <GestorProceso 
                        tipo="descarga-excel" 
                        sub_tipo="ordenes"
                        data={{ condicion: { ...condicion_busqueda, propietario: session.data.propietario }, incluir_estados: incluyeEstadoExcel }}
                        component={<Button size="sm" variant='light' disabled={loadingExcel} className='w-100 mb-2' ><i className="fa-solid fa-file-excel"></i> REPORTE EXCEL</Button>}
                    />
                </Col> : false
            }
            <Col xs={12} className='mb-3'>
                {mostrarFiltros()}
                {showMoreFilters()}
                {modal === true ? 
                <Button size="sm" variant="primary" className='mr-2' onClick={()=>filtrar()}>
                     Actualizar
                </Button>
                : 
                <Button size="sm" variant="primary" className='mr-2' onClick={()=>filtrar()}>ACTUALIZAR</Button>
                }

                {modal === true ? 
                <OverlayTrigger
                    placement={'top'}
                    overlay={
                    <Tooltip>Elimina los filtros</Tooltip>
                    }>
                        <Button variant="secondary" size="sm" className='delpa-filtro shadow-sm' onClick={()=>borrarFiltrosUrl()}>Limpiar</Button>
                    </OverlayTrigger>
                    : 
                    <OverlayTrigger
                        placement={'top'}
                        overlay={
                        <Tooltip>Elimina los filtros</Tooltip>
                        }>
                            <Button variant="light" size="sm" className='delpa-filtro shadow-sm' onClick={()=>borrarFiltrosUrl()}><IoReload style={estilo_last_mile.icon} /> LIMPIAR FILTROS</Button>
                        </OverlayTrigger>
                }    
                
            </Col>
            <Col xs={12}>{selectorFasesTrabajo()}</Col>
                    </Row> : false
        }

    const show_menu_top = <div>
        <Navbar bg="light" variant="light" className='mb-3'>
              {/* <Navbar.Brand >Órdenes</Navbar.Brand> */}
              <Nav className="mr-auto">
                <Nav.Link ><Link to={`/${rutas.ordenes_crear.slug}`}><FaPlusCircle /> CREAR NUEVO</Link> </Nav.Link>
                <Nav.Link ><Link to={`/${rutas.rutas_importar_excel.slug}`}><FaFileExcel /> IMPORTAR EXCEL</Link> </Nav.Link>
                <Nav.Link ><Link to={`/${rutas.rutas_importar_avanzado.slug}`}><FaFileExcel /> IMPORTADOR AVANZADO</Link> </Nav.Link>
              </Nav>
              {/* <Form inline>vehiculos_importar_excel
                <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                <Button variant="outline-info">Search</Button>
              </Form> */}
            </Navbar>
    </div>

    const headerBox = () => {
        if(hideHeader === true ) return false
        return <Row>
            <Col>{tituloPagina}</Col>
            <Col><HelperModal tipo="ordenes" style="modal" /></Col>
        </Row>
        return  <Filtros onFilter={(filtro, tipo) => onFilter(filtro, tipo)} />
    }

    const tituloPagina = hide_title === true ? false : <h4>{titulo} </h4>
    const menu_superior = showAddButton === true ? show_menu_top : false
 
    return <div>
        <ErrorBoundary message={error}/>
        {modalFiltro()}
        {modalBusquedaAvanzada()}
        {headerBox()}
        
        {menu_superior}
        {listadoOrdenes(equipos)}
    </div>

}

export default OrdenesListadoV2