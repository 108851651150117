import Moment from 'react-moment'
import 'moment/locale/es';
import { Button, Card, Col, Container, Dropdown, DropdownButton, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from 'react-bootstrap'
import Accordion from 'react-bootstrap/Accordion'
import { useEffect, useRef, useState } from "react";
import { canalEstadosString, getDireccion, getEmail, getNombreDestinatario, getTelefono, obtenerGeoDatosGoogle } from "../../lib/helpers/pedidos/pedidos";
import { fechaATexto, fechaATextoSimple } from "../../lib/helpers/helpers";
import BoxConductor from "../conductores/box_conductor";
import TablaProductos from './tabla_productos'
import EmitirEstadoModal from '../estados_carga/emitir_modal'
import AccionesModal from "./acciones_modal";
import LogsListado from '../integraciones/logs/listado';
import { centerMapCoordinates } from '../../lib/helpers/maps';
import GeneradorEtiquetas from '../etiquetas/generador_etiquetas';
import BoxEditarGeoDatosOrdenIndividual from './box_editar_geodata_individual';
import Header from '../../components/Header';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { urlapi } from '../../lib/backend/data';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import MapaDetallePedido from '../../components/Mapas/mapa_detalle_pedido';
import SinDatos from '../general/sin_registros';
import { formatDateHoy } from '../../lib/helpers/opcionesfecha';
import MetaDatosOrden from './metadata/metadatos_orden';
import { AiFillCheckCircle, AiFillClockCircle } from 'react-icons/ai'
import { Esquema } from '../../lib/esquemas/tablas';
import { cerrarSesion } from '../../redux/actions/session';
import OrdenZonaListado from './ordenes_zonas/list';
import LoadingOrders from '../general/loadingAnimations/loadingOrders';
import SelectorBodega from '../bodega/bodegas/selector_bodega';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { confirmAlert } from 'react-confirm-alert';
import { esNumero } from '../../lib/helpers/main';
import TiposServicioSelector from './tipos_servicio/selector';
import IngresoCourrier from './courriers/ingreso_courrier';
import { rutas } from '../../lib/routes/routes';
import { FaAngleLeft, FaPhoneAlt, FaRegStickyNote } from 'react-icons/fa';
import EtiquetaCourrier from './courriers/etiqueta_courriers';
import OrdenTicketsListado from './ordenes_tickets/list';
import { MdEmail } from 'react-icons/md';
import Barcode from 'react-barcode';
import { url_images } from '../../lib/global/data';
import { useReactToPrint } from 'react-to-print';
import FloatingProgressBar from '../general/floatingProgressBar';



const Estatico = (props) => {
    const {
        orden
    } = props
    const [ pedido, setPedido ] = useState(orden)
    const [ pedidoEditar, setPedidoEditar ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const [ estados, setEstados ] = useState([])
    const [ etiquetas, setEtiquetas ] = useState([])
    const [ marcadores, setMarcadores ] = useState([])
    const [ lineaTiempo, setLineaTiempo ] = useState([])
    const [ cambiosOrden, setCambiosOrden ] = useState({})
    const [ eliminandoEstado, setEliminandoEstado ] = useState(false)
    const [ loadingFicha, setLoadingFicha ] = useState(false)
    const session = useSelector(state => state.miusuario)
    const dispatch = useDispatch()
    const token = session.tokenSession
    const [ mostrarBodegas, setMostrarBodegas ] = useState(false)
    const { id } = useParams()
    const [ loading, setLoading ] = useState(false)
    const [ loadingGuardando, setLoadingGuardando ] = useState(false)
    
    //REACT-TO-PRINT
    // const Recibo = useRef();
    // const pageStyle = `
    //     @page {
    //         size: 62mm;
    //     }
    //     @media print {
    //         .page-break {
    //         page-break-before: always;
    //         }
    //         .main {
    //         padding:20px;
    //         }
    //     }  
    //     `;
    // const handlePrint = useReactToPrint({
    //   content: () => Recibo.current,
    //   pageStyle
    // });

    console.log("PEDIDO:", pedido)
    console.log("pedidoEditar:", pedidoEditar)                     


    const showComponentByTipoFormulario = (campo) =>{
        switch (campo.tipo_accion) {
          case 'campo-texto':

            if(!campo.valor) return false
            const ocultar = esNumero(campo.valor)
            if(ocultar === true) return false

            return <div className="mb-3 text-primary">
              <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'selector':

            if(!campo.valor) return false
            const ocultar_ = esNumero(campo.valor)
            if(ocultar_ === true) return false

            return <div className="mb-3 text-primary">
              <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
              <p className='mb-0'>{campo.valor ? campo.valor : 'Sin información'}</p>
            </div>
          case 'carga-imagenes':
            if(campo.valores.length < 1) return false
            return <div className="mb-3 text-primary">
              <h6 className='mb-2' style={{fontWeight:900}}>{campo.titulo}</h6>
              
              {
                campo.valores.length > 0 ? campo.valores.map((foto,i) => {
                  return <div md={3} key={`foto-estado-${i}`} style={{ display: "inline-block" }}>
                    <a href={foto.url} target="_blank" className='pr-2'>
                    <img src={foto.url} style={{  height: 70 }} ></img>
                    </a>
                    </div>
                }) : <Col md={12}><p className='text-danger' style={{fontWeight:700}}><i className="fa-solid fa-triangle-exclamation text-warning"></i> Sin información</p></Col>
              }
            </div>
          case 'firma-digital':
            if(!campo.valor) return false
            const isNumero = esNumero(campo.valor)
            if(isNumero === true) return false
    
            return <div className="mb-3 text-primary">
                <h6 className='mb-0' style={{fontWeight:900}}>{campo.titulo}</h6>
                <p className='mb-0'>
                    {campo.valor 
                        ? <img src={campo.valor} alt="Firma digital" style={{maxWidth: '25%'}} /> 
                        : 'Sin información'
                    }
                </p>                  
            </div>
          default:
            break;
        }
    }

    const actualizarEstadoListado = (estado_entrega, sub_estado_entrega) => {
      if(props.actualizarEstadoListado) return props.actualizarEstadoListado(estado_entrega, sub_estado_entrega)
    }

    const actualizarEstadoActual = (data) => {
        const actualizar_estado = {
          codigo_estado: data.codigo_estado,
          titulo: data.estado,
          color: data.estado_color ? data.estado_color : '#babbbb',
        }
        
        const actualizar_sub_estado = {
            codigo_estado: data.codigo_subestado,
            titulo: data.subestado,
            color: data.subestado_color ? data.subestado_color : '#babbbb',
        }
        actualizarEstadoListado(actualizar_estado, actualizar_sub_estado)
        return setEstados(prev => [ ...prev, ...[data] ])
    }

    const onSucessChange = (data) => {
      const { direccion, direccion2 } = data
      if(!direccion || !direccion2) return false
      pedido.orden.billing.address_1 = direccion
      pedido.orden.billing.address_2 = direccion2
      return setPedido(prev => ({...{}, ...pedido}))
    }

    const mostrarMetaDatosSubEstado = (metadatos) => {
        if(!metadatos) return false
        if(Array.isArray(metadatos) !== true) return false
        if(metadatos.length < 1) return false
  
        return metadatos.map((meta,i) => {

          const mostrar = showComponentByTipoFormulario(meta)
          if(!mostrar) return false
          return <div key={`meta-${i}`}>
            <hr />
            {mostrar}
          </div>
        })
      }

      const mostrarCamposPersonalizados = () => {
        if(!pedido.meta_data) return <SinDatos />
        if(Array.isArray(pedido.meta_data) !== true ) return <SinDatos />
        if(pedido.meta_data.length < 1) return <SinDatos />
        return <div>
            <Row>
            {
                pedido.meta_data.map((val,pos) => {
                    return <Col className='mb-3' md={3} xs={6} key={`campo-${pos}`}>
                        <label className="form-control-label mb-0 d-block" style={{ fontSize: 14}}><b>{val.key ? val.key.toUpperCase() : "SIN INFORMACIÓN"}</b></label>
                        <input className='form-control' defaultValue={val.value} />
                    </Col>
                  })
            }
            </Row>
        </div>
      }

      const seleccionarBodegaOrigen = async (data) => {
        const geo_informacion = await obtenerGeoDatosGoogle(data ? data.geodata : [] )
        console.log("geoinformacion:", geo_informacion)
        console.log("DATA FUNCION Bodega: ", data)
        let bodegaOrigen = data
        let target = JSON.parse( JSON.stringify(pedidoEditar) )
        target.orden.origen = {
          address_1: bodegaOrigen.direccion ? bodegaOrigen.direccion : "",
          first_name: bodegaOrigen.titulo ? bodegaOrigen.titulo : "",
          ...geo_informacion
      }
        target.orden.geo_datos_origen = {
          level1: geo_informacion.level1 ?  geo_informacion.level1 : "",
          level2: geo_informacion.level2 ?  geo_informacion.level2 : "",
          pais: geo_informacion.pais ? geo_informacion.pais : "",
          level3: geo_informacion.level3 ? geo_informacion.level3 : "",
          address_1: bodegaOrigen.direccion ? bodegaOrigen.direccion : ""}
          
                            
        return setPedidoEditar(target)

      }



      const handleChangeOrdenBilling = (e) => {
        const { name, value } = e.target
        let target = JSON.parse( JSON.stringify(pedidoEditar) )
        return setPedidoEditar(prev => {
          let target = {...prev}
          target.orden.billing[name] = value
          return target
        })
      }

      const handleChangeOrden = (e) => {
        const { name, value } = e.target
        console.log(value)
        // let target = JSON.parse( JSON.stringify(pedidoEditar) )
        // target[name] = value
        return setPedidoEditar(prev => {
          let target = {...prev}
          target[name] = value
          return target
        })
      }



      const guardarCambiosFicha = async () => {
        setLoadingFicha(id)
        setLoading(true)
        return fetch(`${urlapi}/ordenes`,{
            method:'PUT',
            body: JSON.stringify(pedidoEditar),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
          console.log(res)
          if(!res){
            toast.error("Sin respuesta")
          } else if(res.errorMessage){
            toast.error(res.errorMessage)
          } else if(res._id){
            setPedido(res)  
            toast.success("Edición Exitosa!")          
          }
          
          setLoading(false)
          setMostrarBodegas(false)
          return setLoadingFicha(false)
        })
        .catch(error => {
          toast.error(error.message)
          return setLoadingFicha(false)
        })

      }
      
    const agregarProductoCrear = () => {
      const id_orden = pedido._id
        setLoading(true)
        return fetch(`${urlapi}/ordenes/productos/orden`,{
            method:'POST',
            body: JSON.stringify({
                id_orden
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${session.tokenSession}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if((res) !== false){
                     pedido.productos.push(res)           
           toast.success("Creado con exito")
            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    const eliminarEstado = async (idestado) => {
      setEliminandoEstado(true)
      return fetch(`${urlapi}/estadoscarga?id=${idestado}`, {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer: ${token}`
       }
      })
      .then(pros => pros.json())
      .then(async pros => {
          if(!pros){
            toast.error("Sin datos del servidor")
          } else if(pros.errorMessage){
            toast.error(pros.errorMessage)
          } else if(pros._id){
            toast.success('Borrado exitosamente')

            setPedido(prev => {
              let actual = {...prev}
              if(typeof pros.estado_entrega !== "undefined" || typeof pros.sub_estado_entrega !== "undefined"){
                actual.estado_entrega = pros.estado_entrega
                actual.sub_estado_entrega = pros.sub_estado_entrega
              }
              return {...{}, ...actual}
            })

            setEstados(prev => {
              let actual = [...prev]
              const i = actual.findIndex(e => e._id === idestado)
              if(i > -1) actual.splice(i,1)
              return [...[], ...actual]
            })
            
          }
          return setEliminandoEstado(false)
      })
      .catch(async error => {
        setEliminandoEstado(false)
        return toast.error("No pudimos consultar la información, intente de nuevo recargando la página")
      })
    }

      const agregarCampoPerzonalizado = () => {
        if(!pedido.meta_data) pedido.meta_data = []
        pedido.meta_data.push({})
        return setPedido({ ...{}, ...pedido })
      }

      const onDelete = (pos) => {
        pedido.productos.splice(pos,1)

        return setPedido({...{}, ...pedido})
      }

      const onCreate = () => {
        pedido.productos.push()
        return setPedido({...{}, ...pedido})
      }

      const solicitarEliminarEstado = (idestado) => {
        return confirmAlert({
          title: '¿Confirmas esta acción?',
          message: `Estás a punto de eliminar este estado de carga, esta acción no se puede deshacer`,
          buttons: [
            {
              label: 'CONFIRMAR',
              onClick: () => eliminarEstado(idestado)
            },
            {
              label: 'CANCELAR',
              onClick: () => false
            },
          ],
        })
      }

      const mostrarLineaTiempo = () => {
        if(lineaTiempo.length < 1) return false

        return <Card className='py-2 mb-3 text-center shadow'>
            <h5 className='text-center mb-3' style={{fontWeight:900}}><i className="fa-regular fa-clock"></i> Línea de tiempo</h5>
            <Row>
            {
                lineaTiempo.map((evento,i) => {
                    if(evento.opcional_linea_tiempo === true && !evento.emitido) return false
                    return <Col key={evento._id}>
                        {evento.emitido === true ? <i className="fa-solid fa-circle-check text-success" style={{fontSize:20}}></i> : <i className="fa-solid fa-clock text-secondary" style={{fontSize:20}}></i>}
                        <h6 className='mb-0' style={{fontWeight:900}}>{evento.titulo}</h6>
                        <div className='px-3'>
                        { evento.fecha_emitido ? <span style={{ color:"#b2b2b2", fontSize: 10 }} className='d-block'>{fechaATexto(evento.fecha_emitido)}</span> : <span style={{ color:"#b2b2b2", fontSize: 10 }} className='d-block'>Sin información</span> }
                        </div>
                    </Col>
                })
            }
            </Row>
            </Card>
      }
      
      const mostrarInformacion = () => {

        if(loadingDetalle) return <LoadingOrders />

        if(!pedido) return <div className="container-fluid">
        <Row className="justify-content-md-center">
            <Col md={6} className="text-center">
            <img src="images/pedidos.png" style={{ width: "50%" }} />
            <h4>No hay suficientes datos para mostrar esta sección</h4>
            </Col>
        </Row>
        </div>

        let defaultAccordion = false

        if(estados.length > 0) defaultAccordion = `estado-${estados.length}`

        let titulo_estado           = ''
        let titulo_sub_estado       = ''
        let color_estado            = '#bebebe'

        if(pedido.estado_entrega){
            if(typeof pedido.estado_entrega === 'object'){
                if(pedido.estado_entrega.titulo) titulo_estado = pedido.estado_entrega.titulo
                if(pedido.estado_entrega.color) color_estado = pedido.estado_entrega.color
            }
        }
    
        if(pedido.sub_estado_entrega){
            if(typeof pedido.sub_estado_entrega === 'object'){
                if(pedido.sub_estado_entrega.titulo) titulo_sub_estado = pedido.sub_estado_entrega.titulo
            }
        }

        let string_estado = `${titulo_estado} ${titulo_sub_estado}`
        if(!titulo_estado && !titulo_sub_estado) string_estado = "Sin datos"


        const isGuardando = loadingGuardando || loading || loadingFicha || eliminandoEstado 
        
        return <div className='text-primary '>
          <FloatingProgressBar isVisible={isGuardando} />
          {/* <div ref={Recibo} className='pt-2 main'> */}
            <Row className='justify-content-left my-3'>
                    <img className='mb-3' alt="logotipo" src={`${url_images}/logoColor.svg`} style={{ width: 200 }} />
            </Row>
            {/* <Container className="text-left p-0 m-0">
                <p className='mt-2 mb-3' style={{ fontWeight: 700, fontSize: 10 }}>Transformamos la experiencia en software</p>
            </Container> */}
                <h4 style={{fontWeight:900}}>ORDEN {pedido.pedido}</h4>
                    
            <Row>
                

            <Col md={12} className="pt-0">
                <Row>
                    <Col md={12}>
                 
                        <Row>
                          <Col md={12} className='text-primary'>

                            <h4>Detalles generales</h4>
                            <Row>
                                <Col className='mb-3'>
                                <label className='form-control-label' style={{fontWeight:700}}>Origen</label>
                                { pedido.orden.origen?.first_name ? <p className='mb-0' style={{ fontWeight: "bold" }}>{pedido.orden.origen?.first_name}</p> : false}
                                <p className='mb-0'>{pedido.orden.origen?.address_1}</p>
                                { pedido.orden.origen?.address_2 ? <p className='mb-0' style={{ fontWeight: "bold" }}><FaRegStickyNote /> {pedido.orden.origen?.address_2}</p> : false}
                                <p className='mb-0'><MdEmail /> {pedido.orden.origen?.email ? pedido.orden.origen?.email : "Sin información"}</p>
                                <p className='mb-0'><FaPhoneAlt /> {pedido.orden.origen?.phone ? pedido.orden.origen?.phone : "Sin información"}</p>
                                </Col>
                                <Col className='mb-3'>
                                <label className='form-control-label' style={{fontWeight:700}}>Destinatario</label>
                                { pedido.orden.billing.first_name ? <p className='mb-0' style={{ fontWeight: "bold" }}>{pedido.orden.billing.first_name}</p> : false}
                                <p className='mb-0'>{pedido.orden.billing.address_1}</p>
                                { pedido.orden.billing.address_2 ? <p className='mb-0' style={{ fontWeight: "bold" }}><FaRegStickyNote /> {pedido.orden.billing.address_2}</p> : false}
                                <p className='mb-0'><MdEmail /> {pedido.orden.billing.email}</p>
                                <p className='mb-0'><FaPhoneAlt /> {pedido.orden.billing.phone}</p>
                                </Col>
                            </Row>
                            <hr/>
                            <h4>Fechas</h4>
                            <Row>
                                <Col>
                                <label className='form-control-label mb-0'>Fecha mínima de entrega</label>
                                <p className='mb-0'>{formatDateHoy(pedido.fecha_min_entrega)}</p>
                                </Col>
                                <Col>
                                <label className='form-control-label mb-0'>Fecha tope de entrega</label>
                                <p className='mb-0'>{formatDateHoy(pedido.fecha_max_entrega)}</p>
                                </Col>
                            </Row>
                            <hr/>
                            <h4>Datos de la carga</h4>
                            <Row>
                                <Col>
                                <label className='form-control-label mb-0'>Peso</label>
                                <p className='mb-0'>{pedido.peso}</p>
                                </Col>
                                <Col>
                                <label className='form-control-label mb-0'>Volumen</label>
                                <p className='mb-0'>{pedido.volumen}</p>
                                </Col>
                                <Col>
                                <label className='form-control-label mb-0'>Bultos</label>
                                <p className='mb-0'>{pedido.bultos}</p>
                                </Col>
                                <Col>
                                <label className='form-control-label mb-0'>Bultos refrigerados</label>
                                <p className='mb-0'>{pedido.refrigerado}</p>
                                </Col>
                                <Col>
                                <label className='form-control-label mb-0'>Bultos congelados</label>
                                <p className='mb-0'>{pedido.congelado}</p>
                                </Col>
                                
                                
                                
                                
                            </Row>
                            <hr/>
                            <h4>Campos personalizados</h4>
                          <MetaDatosOrden viewType="simple" id_orden={id} onDelete={pos => onDelete(pos)} />
                            <h4>Productos</h4>
                          <TablaProductos viewType="simple" onDelete={pos => onDelete(pos)} productos={pedido.productos} id_orden={id} /> 
                            <h4>Courrier que gestiona</h4>
                            <EtiquetaCourrier viewType="simple" id_pedido={id} />
                          </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Barcode value={pedido._id} />
        </div>
        // </div>
        // </div>
      }

    return mostrarInformacion()
}

export default Estatico