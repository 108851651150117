import { useEffect, useState } from "react"
import { Badge, Button, Card, Col, Dropdown, Row, Spinner } from "react-bootstrap"
import SelectorCamposPersonalizados from "../campos-personalizados/selector"
import Select from 'react-select'
import { FaFilter, FaPlus } from "react-icons/fa";
import CrearAutomatizacion from "./crearAutomatizacion";
import { estilo_last_mile } from "../../lib/global/styles";
import { IoMdClose } from "react-icons/io";
import BuscadorVehiculos from "../conductores/buscador";
import TiposServicioSelector from "../ordenes/tipos_servicio/selector";
import SelectorDestinatarios from "../destinatarios/selector";
import { canales } from "../ordenes/data";
import { toast } from "react-toastify";
import { urlapi } from "../../lib/backend/data";
import { useSelector } from "react-redux";
import { AiFillThunderbolt } from "react-icons/ai";
import { MdOutlineWebhook } from "react-icons/md";
import ErrorBoundary from "../errorsHandler/errorBoundary";
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

const CrearFlujograma = (props) => {
  const {
    actual
  } = props
  const default_flujo = { hitos: [
    { tipo: "trigger", action: "creacion-orden", hitos: [] }
  ] }
  const [ flujo, setFlujo ] = useState(actual ? actual : default_flujo)
  const typesAction = {
    "trigger": "Disparador",
    "accion": "Acción"
  }
  const session = useSelector(state => state.miusuario)
  const token = session.tokenSession
  const [ estados, setEstados ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ loadingEstados, setLoadingEstados ] = useState(true)
  const [ filtersActives, setFiltersActives ] = useState([])
  const [ tipoFlujo, setTipoFlujo ] = useState('')
  const [ error, setError ] = useState('')
  const [removiendo, setRemoviendo] = useState(false)
  const dispatch = useDispatch()
  const tiposFlujos = [
    { value: "creacion-orden", label: "Creación de orden" },
    { value: "fill-data", label: "Llenan un campo de la orden" },
    { value: "start-picking", label: "Inicia el picking de una orden" },
    { value: "end-picking", label: "Termina el picking de una orden" },
  ]
  const filterAvailables = [
      // { value: "estado", label: "Estado de entrega" },
      // { value: "vehiculo", label: "Vehículo" },
      { value: "tipo-logistica", label: "Tipo de logística" },
      { value: "cliente", label: "Cliente" },
      // { value: "canal", label: "Canal" },
  ]
  const requeridos = [
    { value: "nombre", label: "Nombre" }
  ]

  const crearNuevo = async () => {
    let faltantes = []
    requeridos.map(campo => {
        if(!flujo[campo.value]) faltantes.push(campo.label)
        return true
    })
    if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)

    setLoading(true)
    return fetch(`${urlapi}/general/automatizaciones/flujos`, {
        method: flujo._id ? "PUT" : 'POST',
        body: JSON.stringify(flujo),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
      })
      .then(pros => pros.json())
      .then(async pros => {
          if(!pros){
                setError('Hubo un error de servidor')
          } else if(pros.errorMessage){
                setError(pros.errorMessage)
          } else if(pros._id){
                if(!flujo._id) {
                  console.log("aca)")
                  if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    setFlujo(default_flujo)
                }
                toast.success(`Realizado exitosamente`)
          }
          return setLoading(false)
      })
      .catch(async error => {
            setError('No se pudo actualizar el registro')
            return setLoading(false)
      })
}

  const obtenerEstados = () => {
    return fetch(`${urlapi}/estadoscarga/details`,{
        method: "GET",
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        },
    })
    .then(pros => pros.json())
    .then(data => { 
        if(!data){
        } else if(data.errorMessage){
            setLoadingEstados(false)
        } else if( Array.isArray(data.estados) !== false){
            setEstados(data.agrupaciones)
        }
        return setLoadingEstados(false)
    })
    .catch(error => {
        toast.error("Error al consultar la información")
        return setLoadingEstados(false)
    })
}


const confirmarEliminado = async (id) => {
  setRemoviendo(true)
  setLoading(true)
  return fetch(`${urlapi}/general/automatizaciones/flujos?id=${id}`,{
      method:'DELETE',
      headers: {
          'Content-Type':'application/json',
          'Authorization': `Bearer: ${token}`
      }
  })
  .then(res => {
      if(res.status === 401) return dispatch(cerrarSesion())
      return res.json()
  })
  .then(res => {
      console.log(res)
      if(!res){
          toast.error('Sin datos')
          setLoading(false)
          return setRemoviendo(false)
      } else if(res.errorMessage){
          toast.error(res.errorMessage)
          setLoading(false)
          return setRemoviendo(false)
      } else if(res._id){
        
        if(props.onFieldDeleted) props.onFieldDeleted(res._id)
          // if(props.onProveedorNuevo) props.onProveedorNuevo(res)
            setFlujo(default_flujo)
        setRemoviendo(false)
      }
      toast.success(`Realizado exitosamente`)
    
    return setLoading(false)
       
  })
  .catch(error => {
      toast.error("Error al consultar la información, intente nuevamente")
      setLoading(false)
    return setRemoviendo(false)
    
  })
}

  useEffect(() => {
    obtenerEstados()
  }, [])

  const removerFiltro = (val) => {
    setFiltersActives(prev => {
        const actual = [ ...prev ]
        return actual.filter(e => e !== val)
    })
  }

  const handleChangeTipoServicio = (data) => {
    const newService = data._id;
    return console.log(data);
  }


  const handleChangeCliente = (e) => {
    console.log(e)
}

const addFilter = (filtro) => {
  setFiltersActives(prev => {
      let actual = [...prev]
      actual.unshift(filtro)
      return [ ...actual ]
  })
}


  const mostrarContenidoPor = (value, dato, i) => {

    const opcionesCanales = Object.keys(canales).map(e => ({ value: e, label: canales[e].title }))

    const optionsDefaultChannels = [] // dato.canal ? opcionesCanales.filter(e => dato.canal.$in.includes(e.value)) : []

    const component_estado = <Select
        // onChange={handleChangeSelectEstado}
        isLoading={loadingEstados}
        // isDisabled={disableStatuses}
        isMulti={true}
        options={estados}
        placeholder="Filtrar por estados..."
        noOptionsMessage={()=>'Sin opciones'}
    />

    const component_vehiculo = <BuscadorVehiculos placeholderText="Filtra por vehículos" hideLabel={true} isMulti={true} onChange={(data) => console.log(data)} />

    const component_tipo_logistica = <TiposServicioSelector defaultValue={dato.tipoServicio} onChangeValue={(data) => handleChangeTipoServicio(data)} />
    const component_cliente = <SelectorDestinatarios defaultValue={dato.id_cliente ? dato.id_cliente : ""} titulo="Cliente" onChange={handleChangeCliente} />
    const component_canal = <Select className='mb-3' defaultValue={optionsDefaultChannels} options={opcionesCanales} isMulti placeholder="Seleccione" onChange={(data) => {
        // setCondicionBusqueda(prev => {
        //     const actual = { ...prev }
        //     if(data.length > 0){
        //         actual.canal = { $in: data.map(e => e.value) }
        //     } else {
        //         delete actual.canal
        //     }
        //     return actual
        // })
    }} />

    const data = {
        estado: component_estado,
        vehiculo: component_vehiculo,
        "tipo-logistica": component_tipo_logistica,
        cliente: component_cliente,
        canal: component_canal,
    }

    return data[value]
}


  const mostrarFiltros = (dato, i) => {
    return filterAvailables.map(e => {
        if(filtersActives.includes(e.value)){
            return <Dropdown className='p-0 mr-2' style={{ display: "inline-block" }}>
            <Dropdown.Toggle variant="light" size="sm" style={{ textTransform: "uppercase" }} ><FaFilter size={11} /> {e.label} </Dropdown.Toggle>
            <Dropdown.Menu className='p-3'>
                <Button size="sm" variant="light" className='w-100 mb-3' style={{ minWidth: 300 }} onClick={() => removerFiltro(e.value)} >REMOVER <IoMdClose style={estilo_last_mile.icon} size={18} className='hover' /></Button>
                {mostrarContenidoPor(e.value, dato, i)}
            </Dropdown.Menu>
          </Dropdown>
        }
    })
}

  const handleChange = (e, i) => {
    const { value } = e.target
    setFlujo(prev => {
      let actual = { ...prev }
      actual.hitos[i].action = value
      return actual
    })
  }

  const customDataByType = (dato, i) => {
    const type = dato.action
    if(type === "creacion-orden") {
      return <div className="mt-2 mb-2">
        <h5>Puedes condicionar las órdenes</h5>
        <hr className="mt-2 mb-2"/>
        {mostrarFiltros(dato, i)}
        {showMoreFilters()}
        <hr className="mt-2 mb-2"/>
      </div>
    } else if( type === "fill-data") {
      console.log(dato, i)
      return <div>
          <SelectorCamposPersonalizados 
          defaultValue={dato?.value?.value}
          onChange={(e) => {
            setFlujo(prev => {
              let actual = { ...prev }
              actual.hitos[i].value = e
              return actual
            })
          }} />
      </div>
    }
  }

  const agregarSubHito = (i) => {
    setFlujo(prev => {
      let actual = { ...prev }
      actual.hitos[i].hitos.push({ tipo: "accion", action: "" })
      return actual
    })
  }

  const showMoreFilters = () => {
    return <Dropdown className='p-0 mr-2' style={{ display: "inline-block" }}>
    <Dropdown.Toggle variant="secondary" size="sm" id="dropdown-basic"><FaPlus size={13} /> AGREGAR FILTRO</Dropdown.Toggle>
    <Dropdown.Menu>
      {
        filterAvailables.map(e => {
            return filtersActives.includes(e.value) ? false : <Dropdown.Item key={e.value} onClick={() => addFilter(e.value)}>{e.label}</Dropdown.Item>
        })
      }
    </Dropdown.Menu>
  </Dropdown>
}

  const mostrarSubHitos = (hito, i) => {
    if(hito.hitos) {
      return <div>
        <Row>

        {
          hito.hitos.map((subHito, index) => {
            const width = 2
            const height = 20
            return <Col md={6} key={`subhito-${index}`}>
              <div style={{ margin: "0 auto", width, backgroundColor: "#6c757d", height }}></div>
              <div style={{ margin: "0 auto", textAlign: "center", fontWeight: "bold" }}><h3 style={{ fontSize: 20 }} className="mt-1 circle">{(index+1)}</h3></div>
              <div style={{ margin: "0 auto", width, backgroundColor: "#6c757d", height }}></div>
              <Card className="p-2 mb-3">
                <Row>
                  <Col md={6}>
                  <h4><Badge variant="dark"><MdOutlineWebhook /> ACCIÓN</Badge></h4>
                  </Col>
                  <Col md={6} className="text-right">
                      <Button size="sm" variant="outline-danger" onClick={() => {
                      setFlujo(prev => {
                        let actual = { ...prev }
                        actual.hitos[i].hitos.splice(index, 1)
                        return actual
                      })}} >ELIMINAR</Button>
                  </Col>
                </Row>
              <CrearAutomatizacion defaultValue={subHito.action} onChange={(data) => {
                return setFlujo(prev => {
                  let actual = { ...prev }
                  actual.hitos[i].hitos[index].action = data
                  return actual
                })
              }} />
              </Card>
            </Col>
          })
        }
        </Row>
      </div>
    }
  }

  const showElementByType = (elem, i) => {
    if(elem.tipo === "trigger") {
      return <div>
        <Card className="p-2 shadow">
        <label className="form-label"><AiFillThunderbolt /> Disparador</label>
                  <select className="form-control" value={elem.action} onChange={(e) => handleChange(e, i)} >
                    <option value="">Seleccione</option>
                    {
                      tiposFlujos.map((tipo, index) => {
                        return <option key={`tipo-${index}`} value={tipo.value}>{tipo.label}</option>
                      })
                    }
        </select>
        <div className="mt-2">{customDataByType(elem, i)}</div>
        <Button className="w-100" variant="secondary" onClick={() => agregarSubHito(i)} ><FaPlus style={{ verticalAlign: "middle" }} /> AGREGAR ACCIÓN</Button>
        </Card>
        <div className="mt-2">{mostrarSubHitos(elem, i)}</div>
      </div>
    }
  }

const solicitarEliminar = (id) => {
    return confirmAlert({
        title: `¿Estás seguro?`,
        message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
        buttons: [
          {
            label: 'CONFIRMAR',
            onClick: () => confirmarEliminado(id)
          },
          {
            label: 'CANCELAR',
            onClick: () => false
          }
        ]
      })
}

  const handleChangeFlujo = (e) => {
    const { name, value } = e.target
    setFlujo(prev => {
      let actual = { ...prev }
      actual[name] = value
      return actual
    })
  }

  const mostrarHitos = () => {
    let subhitos = []
    let mensajeBoton = flujo._id ? "ACTUALIZAR" : "CREAR"
    return <div>

    {
       removiendo === true ? <Spinner animation="border" /> : false 
    }
    { flujo._id ?  <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(flujo?._id)} >Eliminar</Button> : false
    }

      <h4><b>Automatiza un proceso</b></h4>
      <p>Lo primero que deberás seleccionar es un disparador que gatille las automatizaciones</p>

      <label className="form-label">Nombre</label>
      <input className="form-control mb-3" name="nombre" value={flujo.nombre} onChange={handleChangeFlujo} />

      {
        flujo.hitos.map((hito, index) => {
          if(Array.isArray(hito.hitos)) subhitos = subhitos.concat(hito.hitos)
          return <div key={`hito-${index}`}>
                  {showElementByType(hito, index)}
          </div>
        })
      }
      <Button disabled={subhitos.length > 0 ? false : true || loading === true} className="w-100 mt-3" variant="success" onClick={() => crearNuevo() }>{ loading ? "CARGANDO..." : mensajeBoton }</Button>

    </div>
    }
      return <div>
        {mostrarHitos()}
        <ErrorBoundary message={error} />
    </div>
}

export default CrearFlujograma