import { useCallback, useEffect, useState } from 'react'
import { Row, Col, Tab, Tabs, Spinner, Card, Button, ProgressBar, Modal } from 'react-bootstrap'
import { FaFileUpload, FaRegFile, FaTags } from "react-icons/fa";
import data, { urlapi } from '../../lib/backend/data'
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton'
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { useSelector } from 'react-redux';
import { BsFillTrashFill } from "react-icons/bs";
import { set } from 'date-fns';
import { url_images } from '../../lib/global/data';
import { BiSolidHelpCircle } from 'react-icons/bi';
import { debounce } from '@mui/material';
import { fechaATexto } from '../../lib/helpers/helpers';
import FloatingProgressBar from './floatingProgressBar';

const MediosBox = (props) => {
    const {
        typeView
    } = props
    const [ loadingMedios, setLoadingMedios] = useState(true)
    const [loading, setLoading] = useState(false)
    const [ loadingActualizadoMedios, setLoadingActualizadoMedios ] = useState(false)
    const [ loadingMediosProyecto, setLoadingMediosProyecto ] = useState(true)
    const [ cargandoMas, setCargandoMas ] = useState(false)
    const [ medios, setMedios ] = useState([])
    const [ mediosProyecto, setMediosProyecto ] = useState([])
    const [ totalMedios, setTotalMedios ] = useState(0)
    const [ medioSeleccionado, setMedioSeleccionado ] = useState(false)
    const [ uploading, setUploading ] = useState(false)
    const [ uploadPorcentaje, setUploadPorcentaje ] = useState(0)
    const [ downloading, setDownloading ] = useState(false)
    const [ asignandoMedio, setAsignandoMedio ] = useState(false)
    const [ palabraBusqueda, setPalabraBusqueda ] = useState('')
    const [ condicionBusqueda, setCondicionBusqueda ] = useState({})
    const session = useSelector(state => state.miusuario)
    const [isOpen, setIsOpen] = useState(false);
    const token = session.tokenSession
    const axios = require("axios").default;
    const id_destino = props.id_destino
    const [etiquetas, setEtiquetas] = useState([])

    const handleChangeStatus = ({ meta, remove }, status) => {
    console.log(status, meta);
  };

  const boxUploadingSpinner = () => {
      if(uploading !== true) return false
      return <ProgressBar now={uploadPorcentaje} label={`${uploadPorcentaje}%`} />;
  }

  const handleChange = (e) => {
    const { value } = e.target
    return setPalabraBusqueda(value)
  }

  const buscarArchivo = async () => {
    if(!palabraBusqueda) return toast.error('Ingresa primero un valor')
    if(palabraBusqueda.length < 4) return toast.error("Introduce al menos 4 carácteres")
    setCondicionBusqueda({ $text: { $search: palabraBusqueda } })
    console.log({ $text: { $search: palabraBusqueda } })
    setCargandoMas(true)
    return fetch(`${data.urlapi}/medios/list`,{
        method:'POST',
        body: JSON.stringify({ condicion: { $text: { $search: palabraBusqueda } }, skip: 0 }),
        headers: {
            'Content-Type':'application/json',
            'Authorization': `Bearer: ${token}`
        }
    })
    .then(res => {
        if(res.status === 401) return window.location = '/login'
        return res.json()
    })
    .then(res => {
        console.log(res)
        if(!res){
            toast.error('Sin datos')
            return false
        } else if(res.errorMessage){
            toast.error(res.errorMessage)
            return false
        }
        if(Array.isArray(res.datos) !== false){
            if(res.datos.length > 0){
                console.log(res.datos)
                setMedios(res.datos)
                setTotalMedios(res.total)
                return setCargandoMas(false)
            } else {
                toast.warn("No se encontraron resultados")
                return setCargandoMas(false)
            }
        } else {
            return setCargandoMas(false)
        }
    })
    .catch(error => {
        toast.error("Error al registrar el medio, intente nuevamente")
        return setCargandoMas(false)
    })
  }

  const handleSubmit = async (files) => {
    const f = files[0];
    const name = `${Date.now()}-${f.file.name}`
    const original_name = f.file.name
    const type = f.file.type
    const size = f.file.size
    const medio = {
        nombre: original_name,
        type,
        size,
        nombre_server: name,
    }
    const url_firmada = await obtenerFirmaUrl(name, type)
    if(!url_firmada) return false
    setUploading(true)
    try {
        const result = await axios.put(url_firmada, f["file"], {
            headers: {
              'Content-Type': f["file"].type
            },
            onUploadProgress: (e) => {
                const { loaded, total } = e
                let porcentaje = Math.floor(  (loaded*100) / total )
                setUploadPorcentaje(porcentaje)
            }
            })
            const nuevo_medio = await registrar_medio(medio)
            return setUploading(false)
    } catch (error) {
        setUploading(false)
        console.log(error.message)
        toast.error("No se pudo cargar este archivo")
        }
    }

    useEffect(() => {
        obtenerBibliotecaMedios(false)
        obtenerMediosProyecto(id_destino,'medio')
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const registrar_medio = async (objeto)=>{
        return fetch(`${data.urlapi}/medios/registrar-medio`,{
            method:'POST',
            body: JSON.stringify(objeto),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res._id){
                toast.success("Cargado exitosamente")
                medios.unshift(res)
                return setMedios(medios)
            }
            return false
        })
        .catch(error => {
            console.log(error)
            toast.error("Error al registrar el medio, intente nuevamente")
            return false
        })
    }
    const obtenerFirmaUrl = async (name,type)=>{
        return fetch(`${data.urlapi}/medios/presigne-url-public`,{
            method:'POST',
            body: JSON.stringify({
                name, type
            }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return false
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return false
            } else if(res.url){
                return res.url
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return false
        })
    }

    const obtenerMediosProyecto = async (id, tipo)=>{
        if(!id) return setLoadingMediosProyecto(false)
        setLoadingMediosProyecto(true)
        return fetch(`${data.urlapi}/medios/relaciones?id=${id}&tipo=${tipo}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingMediosProyecto(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingMediosProyecto(false)
            }
            if(Array.isArray(res) !== false) setMediosProyecto(res)
            // setEtiquetas(res[0].tags)
            return setLoadingMediosProyecto(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingMediosProyecto(false)
        })
    }

    const obtenerArchivo = async (id)=> {
        setDownloading(true)
        return fetch(`${data.urlapi}/medios/presigne-url?id=${id}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setDownloading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setDownloading(false)
            } else if(res.url){
                setDownloading(false)
                return window.open(res.url, '_blank').focus();
            }
            return false
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setDownloading(false)
        })
    }
    const obtenerBibliotecaMedios = async (cargarmas)=> {
        if(cargarmas===true){
            setCargandoMas(true)
        } else {
            setLoadingMedios(true)
        }
        return fetch(`${data.urlapi}/medios/list`,{
            method:'POST',
            body: JSON.stringify({ condicion: condicionBusqueda, skip: medios.length }),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoadingMedios(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoadingMedios(false)
            }
            if(cargarmas===true){
                if(Array.isArray(res.datos) !== false){
                    const final_array = medios.concat(res.datos)
                    setTotalMedios(res.total)
                    setMedios(final_array)
                    return setCargandoMas(false)
                } else {
                    return setLoadingMedios(false)
                }
            } else {
                setMedios(res.datos)
                setTotalMedios(res.total)
                return setLoadingMedios(false)
            }
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingMedios(false)
        })
    }

    const seleccionarMedio = (id) => {
        return setMedioSeleccionado(id)
    }

    const cargandoScreen = () => {
        return <Row>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
                <Col md={2} className="mb-4" ><Skeleton height={100} /></Col>
            </Row>
    }

    const mostrarMedios = () => {
        if(loadingMedios === true) return cargandoScreen()
        if(medios.length < 1) return <div className='p-3'>
        <Row className="justify-content-md-center">
            <Col md={5} className="text-center">
            <img src={`${url_images}/animations/6342141.gif`} style={{ maxWidth: 500, width: "50%" }} />
            <h5>No hay archivos</h5>
            <p>Aún no has cargado ningún archivo a la biblioteca de medios</p>
            </Col>
        </Row>
        </div>
        return <div>
            <h4>Se muestran {medios.length} de {totalMedios} Registros</h4>
            <Row>
                <Col md={9}><input className='form-control mb-3' placeholder='Buscar por nombre de archivo' onChange={handleChange} /></Col>
                <Col md={3}><Button size="sm" onClick={()=>buscarArchivo()}>BUSCAR</Button> </Col>
            </Row>
            <Row>
            {
                medios.map(medio => {

                    const tipo = medio.type.split('/')[0]

                    return <Col md={3} key={medio._id} className="mb-3">
                        <Card className="hover" border={medioSeleccionado === medio._id ? 'success' : false } onClick={()=>seleccionarMedio(medio._id)}>
                        {/** <Card.Img variant="top" src={`/${medio.url}`} /> */}
                        <div className='p-3'>
                            {
                                tipo === 'image' ? <img src={medio.url} alt={medio.nombre} style={{ width: '100%' }} /> : <i className="far fa-file-alt mb-2" style={{ fontSize: 25 }}></i>
                            }
                            <p className="mb-0" style={{ fontSize: 10 }}><b>{medio.type}</b></p>
                            <p className="mb-0" style={{ fontSize: 13 }}>{medio.nombre.substring(0,10)}{medio.nombre.length > 10 ? "..." : false }</p>
                        </div>
                        </Card>
                    </Col>
                })
            }
            </Row>
            {
                cargandoMas === true ? <Spinner animation="border" /> : <div>
                    {
                        totalMedios > medios.length ? <Button variant='outline-primary' size="sm" onClick={()=>obtenerBibliotecaMedios(true)}>CARGAR MÁS</Button> : false
                    }
                </div>
            }
        </div>
    }
    const mostrarMediosProyecto = () => {
        if(loadingMediosProyecto === true) return cargandoScreen()
        if(mediosProyecto.length < 1) return <div className='p-3'>
        <Row className="justify-content-md-center">
            <Col md={7} className="text-center">
            {/* <img src={`${url_images}/animations/6342141.gif`} style={{ maxWidth: 500, width: "50%" }} /> */}
            <h5>No hay archivos asignados a este recurso</h5>
            <p>Aún no has cargado ningún archivo a este recurso, agrega tu archivo a la biblioteca de medios y acontinuación, asígnalo a este recurso</p>
            </Col>
        </Row>
        </div>
        return <div>
        <h4>{mediosProyecto.length} Registros</h4>
        <Row>
        {
            mediosProyecto.map(medio => {
                const tipo = medio.type.split('/')[0]
                return <Col md={3} key={medio._id}>
                    <Card className="hover" border={medioSeleccionado === medio._id ? 'success' : false } onClick={()=>seleccionarMedio(medio._id)}>
                    <div className='p-3'>
                            {
                                tipo === 'image' ? <img src={medio.url} alt={medio.nombre} style={{ width: '100%' }} /> : <i className="far fa-file-alt mb-2" style={{ fontSize: 25 }}></i>
                            }
                        <p className="mb-0" style={{ fontSize: 10 }}><b>{medio.type}</b></p>
                        <p className="mb-0" style={{ fontSize: 13 }}>{medio.nombre.substring(0,10)}{medio.nombre.length > 10 ? "..." : false }</p>
                    </div>
                    </Card>
                </Col>
            })
        }
        </Row>
    </div>
    }

    const handleSubmitEtiqueta = (e) => {
        
        if (e.key === 'Enter'){   
            const { value } = e.target
            if(!value) return
            if(!medioSeleccionado) return false
            const i_asignado = mediosProyecto.findIndex(me => me._id === medioSeleccionado)
            if(i_asignado < 0) return false
            setMediosProyecto(prev => {
                let actual = [...prev]
                if(!actual[i_asignado].tags) actual[i_asignado].tags = []
                actual[i_asignado].tags.push(value)
                guardarEtiquetas({
                    _id: actual[i_asignado].idrelacion,
                    tags: actual[i_asignado].tags
                })
                return actual
            })
            if(document.getElementById('etiquetainput')) document.getElementById('etiquetainput').value = ''
        }
    }

    const guardarEtiquetas = (payload) => {
            setLoading(true)
          return fetch(`${data.urlapi}/medios/relaciones`,{
              method:'PUT',
              body: JSON.stringify(payload),
              headers: {
                  'Content-Type':'application/json',
                  'Authorization': `Bearer: ${session.tokenSession}`
              }
          })
          .then(res => {
            if(res.status === 401) return window.location = '/login'
              return res.json()
          })
          .then(res => {
              if(!res){
                  toast.error('Sin datos')
              } else if(res.errorMessage){
                  toast.error(res.errorMessage)
              } else if((res) !== false){

              }
              return setLoading(false)
          })
          .catch(error => {
              toast.error("Error al consultar la información, intente nuevamente")
              return setLoading(false)
          })
              
    }

    const removerTag = (i, i_medio) => {
        setMediosProyecto(prev => {
            let actual = [...prev]
            actual[i_medio].tags.splice(i,1)
            guardarEtiquetas({
                _id: actual[i_medio].idrelacion,
                tags: actual[i_medio].tags
            })
            return actual
        })
    }

    const mostrarEtiquetas = () => {
        const i_asignado = mediosProyecto.findIndex(me => me._id === medioSeleccionado)
        if(i_asignado < 0) return false
        const medio = mediosProyecto[i_asignado] 
        const etiquetas = medio.tags ? medio.tags : []
        
        if(Array.isArray(etiquetas) !== true) return false
        return <div >{  etiquetas?.map((etiqueta,i) => {
            return <Card className='mb-2' key={`etiqueta${i}`} style={{textAlign: 'center', background: "#dcdcdc"}}>
                <h6 style={{textAlign: 'center'}}><FaTags/> {etiqueta}</h6>
                <label className='form-control-label d-block pt-0' style={{fontSize: 12}}><b className="text-danger hover" onClick={() => removerTag(i, i_asignado)}><BsFillTrashFill /> REMOVER</b></label>  
                </Card>  })}           

                </div>
    }

    const handleClose = () => {
        setIsOpen(false);
    };

    const actualizar = useCallback(debounce((data) => actualizarMedio(data), 500), []);

    const handleChangeAdjunto = (e) => {
        const { name, value } = e.target
        return setMedios(prev => {
            let actual = [...prev]
            const i = actual.findIndex(m => m._id === medioSeleccionado)
            if(i > -1) actual[i][name] = value
            const medio_ = actual[i]
            actualizar(medio_)
            return actual
        })
    }
    
    const mostrarMedioSeleccionado = (tipo) => {
        if(!medioSeleccionado) return false
        const i = medios.findIndex(me => me._id === medioSeleccionado)
        const i_asignado = mediosProyecto.findIndex(me => me._id === medioSeleccionado)
        if(i < 0) return false
        const medio = medios[i]
        return <div>
            <h5>Detalles del adjunto</h5>
            <Card className='p-3'>
                {loadingActualizadoMedios === true ? <p className='text-success'><b style={{ fontWeight: "bold" }}>Guardando...</b></p> : <p ><b style={{ fontWeight: "bold" }}>Guardado</b></p> }
                <textarea className='form-control mb-3' style={{ minHeight: 100 }} name="nombre" value={medio.nombre} onChange={handleChangeAdjunto} />
                <Button size="sm" className='mb-2' variant="secondary">{medio.type}</Button>
                <p className='mb-0'>{medio.date_string}, <b>{(medio.size/1000000).toFixed(2)}MB</b></p>

                {
                    tipo === 'medios_asignados' ? <div>
                    <Row>
                        <Col md={12} className="mb-1">
                            { asignandoMedio === true ? <Spinner animation="border" /> : <Button size="sm" variant="outline-danger" onClick={()=>eliminarRelacion(mediosProyecto[i_asignado].idrelacion)}>DESASIGNAR</Button>}
                        </Col>
                        <Col md={12} className="mb-1">
                            { downloading ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>obtenerArchivo(medioSeleccionado)} >DESCARGAR</Button> }
                        </Col>
                        <Col md={12} className="mb-1" style={{display: 'flex', flexDirection: 'column'}}>
                            <label>Etiquetas</label>
                            <input type="text" id="etiquetainput" placeholder='Escriba y presione enter' className="mb-2 form-control" onKeyDown={(e)=>handleSubmitEtiqueta(e)}/>
                            {mostrarEtiquetas()}
                        </Col>
                    </Row>
                    </div> : <div>
                    <Button disabled={asignandoMedio} variant="danger" size="sm" className='mb-1 w-100' onClick={() => eliminarMedioSeleccionado(medioSeleccionado)} >ELIMINAR</Button>
                    <Row>
                        <Col md={12} className="mb-1">
                            { asignandoMedio === true ? <Spinner animation="border" /> : <Button size="sm" className='w-100' onClick={()=>asignarHito(medioSeleccionado)}>ASIGNAR</Button>}
                        </Col>
                        <Col md={12} className="mb-1">
                            { downloading ? <Spinner animation="border" /> : <Button size="sm" variant="success" className='w-100' onClick={()=>obtenerArchivo(medioSeleccionado)} >DESCARGAR</Button> }
                        </Col>
                    </Row>
                    <p className='mt-3' style={{ fontSize: 11 }}>Subido por <b style={{ fontWeight: "bold" }}>{medio?.creador?.nombres ? medio?.creador?.nombres : "No identificado"} el {fechaATexto(medio.createdAt)}</b></p>
                    </div>
                }
                
            </Card>
        </div>
    }

    const eliminarMedioSeleccionado = async (id) => {
        setAsignandoMedio(true)
        return fetch(`${data.urlapi}/medios?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
                toast.success("Este item fue removido exitosamente")
                setMedios(prev => {
                    let actual = [...prev]
                    return actual.filter(elem => elem._id !== id)
                })
                setMediosProyecto(prev => {
                    let actual = [...prev]
                    return actual.filter(elem => elem._id !== id)
                })
                setMedioSeleccionado(false)
            }
            return setAsignandoMedio(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setAsignandoMedio(false)
        })
    }

    const actualizarMedio = async (data) => {
        setLoadingActualizadoMedios(true)
        return fetch(`${urlapi}/medios`,{
            method:'PUT',
            body: JSON.stringify(data),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
            } else if(res._id){
            }
            return setLoadingActualizadoMedios(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoadingActualizadoMedios(false)
        })
    }


    const eliminarRelacion = async (id) => {
        setAsignandoMedio(true)
        return fetch(`${data.urlapi}/medios/relaciones?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setAsignandoMedio(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setAsignandoMedio(false)
            } else if(res._id){
                toast.success("Este item fue removido exitosamente")
                const i = mediosProyecto.findIndex(m => m.idrelacion)
                if(i > -1){
                    mediosProyecto.splice(i,1)
                    setMediosProyecto(mediosProyecto)
                }
                setMedioSeleccionado(false)
                return setAsignandoMedio(false)
            }
            return true
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setAsignandoMedio(false)
        })
    }

    const asignarHito = async(id) => {
        setAsignandoMedio(true)
        const payload = {
            id_destino,
            id_medio: id
        }
        return fetch(`${data.urlapi}/medios/relaciones`,{
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Authorization': `Bearer: ${token}`,
                'Content-Type':'application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            if(!res) toast.error('No se pudo cargar tu archivo')
            if(res.errorMessage) toast.error(res.errorMessage)
            if(res._id){
                mediosProyecto.unshift(res)
                setMediosProyecto(mediosProyecto)
                // setEtiquetas(res.tags)
                toast.success("Asignado exitosamente")
            }
            setMedioSeleccionado(false)
            return setAsignandoMedio(false)
        })
        .catch(error => {
            console.log(error)
            setAsignandoMedio(false)
            return toast.error('No se pudo subir la imagen')
        })
    }
    
    const tabs = <div className='p-2'>
            <Tabs defaultActiveKey={ id_destino ? "archivos-relacionados" : "libreria" } id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="archivos" title={<div><h5><FaFileUpload /> Subir archivo</h5></div>}>
                <div className='p-5'>
                    <Row className="justify-content-md-center">
                        <Col md={7} className="text-center">
                        <h5>Arrastra los archivos para subirlos</h5>
                        {boxUploadingSpinner()}
                        <Dropzone
                            onChangeStatus={handleChangeStatus}
                            onSubmit={handleSubmit}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent="Arrastra un archivo"
                            styles={{
                                dropzone: { width: 'auto', height: 200 },
                                dropzoneActive: { borderColor: "green" },
                            }}
                            submitButtonContent={()=>'Cargar archivo'}
                            />
                        <p>Tamaño máximo de archivo: 3GB</p>
                        </Col>
                    </Row>
                </div>
            </Tab>
            <Tab eventKey="libreria" title={<div><h5><FaRegFile /> Biblioteca de medios</h5></div>}>
                <Row>
                    <Col md={9}>
                    {mostrarMedios()}
                    </Col>
                    <Col md={3}>
                    {mostrarMedioSeleccionado('bibliteca_medios')}
                    </Col>
                </Row>
            </Tab>
            {
                id_destino ? <Tab eventKey="archivos-relacionados" title={<div><h5><FaTags /> Archivos relacionados</h5></div>}>
                <Row>
                    <Col md={9}>
                    {mostrarMediosProyecto()}
                    </Col>
                    <Col md={3}>
                    {mostrarMedioSeleccionado('medios_asignados')}
                    </Col>
                </Row>
            </Tab> : false
            }
            
            </Tabs>
        </div>

    if(typeView === "modal") return <div>
        <Modal show={isOpen} size="lg" onHide={()=>handleClose()} centered >
            <Modal.Header closeButton><h4 className='mb-0'><b>Documentos asociados</b></h4></Modal.Header>
            <Modal.Body>
                {tabs}
            </Modal.Body>
        </Modal>
        <Button size="sm" variant="light" onClick={() => setIsOpen(true)}>DETALLES</Button>
    </div> 

    const isGuardando = loading || uploading || loadingActualizadoMedios || loadingMediosProyecto || cargandoMas || asignandoMedio || downloading

    return <div>
        <FloatingProgressBar isVisible={isGuardando} />
        {tabs}
        </div>
}

export default MediosBox