import React from 'react';
import ProgressBar from './barraProgreso';

const FloatingProgressBar = ({ isVisible }) => {
    const floatingStyle = {
        position: 'fixed',
        top: 0,
        left: '50%',
        width: '100%',
        zIndex: 1000,
        display: isVisible ? 'block' : 'none',
        transform: 'translateX(-50%)'
    };

    return (
        <div style={floatingStyle}>
            <ProgressBar />
        </div>
    );
};

export default FloatingProgressBar;