import { useEffect, useRef, useState } from "react"
import Select from 'react-select';
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { cerrarSesion } from "../../redux/actions/session";
import { useDispatch, useSelector } from "react-redux";
import { MyComponentProps } from "./selector_usuarios.types";
import React from "react";
import { RootState } from "../../redux/store";

const SelectorUsuarios: React.FC<MyComponentProps> = ({
    key,
    full_payload,
    defaultValue,
    key_identifier,
    labelType,
    filterValues = [],
    label = "Usuarios",
    multi = false,
    onChange
}) => {
    const [ usuarios, setUsuarios ] = useState([{ value:'all_access', label:'Todos los permisos'}])
    const [ loading, setLoading ] = useState(true)
    const typeLabel = labelType ? labelType : 'email'
    const session = useSelector((state: RootState) => state.miusuario)
    const token = session.tokenSession
    let default_key_identifier = key_identifier ? key_identifier : "_id"
    const fullPayload = full_payload === true
    const dispatch = useDispatch()
    const selectRef = useRef()

    const createLabel = (item, type) => {
        if(type === "phone") return `${item.phone ? item.phone : "SIN NÚMERO"} · ${item.nombres}`
        return `${item.email} · ${item.nombres}`
    }

    const handleChangeSelect = (e) => {
        let data = e
        if(fullPayload) {
            if(multi === true ){
                if(e){
                    const opciones = []
                    e.map(valor => {
                        const i = usuarios.findIndex(u => u[default_key_identifier] === valor.value)
                        console.log({usuarios, })
                        if(i > -1) opciones.push(usuarios[i])
                    })
                    data = opciones
                }
            } else {
                const i = usuarios.findIndex(u => u[default_key_identifier] === e.value)
                if(i > -1) data = usuarios[i]
            }
        }
        if(onChange) return onChange(data)
        return
    }

    const obtenerUsuarios = async () => {
        return fetch(`${data.urlapi}/usuarios/list-full`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            if(!res){
                toast.error('Sin datos')
                return setLoading(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setLoading(false)
            } else if(Array.isArray(res) !== false){
                setUsuarios(res)
                let opciones = res.map(u => ({ value: u[default_key_identifier], label: createLabel(u, typeLabel) })).filter(e => e.value)
                console.log(opciones)
                setTimeout(() => {
                    if(defaultValue){
                        if(selectRef.current){
                            if(Array.isArray(defaultValue)){
                                const buscar_codigos = opciones.filter(e => defaultValue.includes(e.value))
                                // if(selectRef?.current) selectRef.current.setValue(buscar_codigos)
                            } else {
                                    const pos = opciones.findIndex(e => e.value === defaultValue)
                                    if(pos > -1){
                                        // if(selectRef?.current) selectRef.current.setValue(opciones[pos])
                                    }
                            }
                        }
                    }
                }, 100);

            }
            return setLoading(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setLoading(false)
        })
    }

    useEffect(() => {
        obtenerUsuarios()
    }, [])


    let options_select = usuarios.map(u => ({ value: u[default_key_identifier], label: createLabel(u, typeLabel) })).filter(e => e.value)

    return <div>
        <label className="form-control-label">{label}</label>
        <Select
            ref={selectRef}
            key={`cliente-${key}`}
            onChange={handleChangeSelect}
            options={options_select}
            isMulti={multi}
            defaultValue={defaultValue}
            isLoading={loading}
            noOptionsMessage={()=>'Sin opciones'}
            placeholder="Selecciona un usuario..."
                    />
    </div>
  };
  
  export default SelectorUsuarios