import { useState, useEffect, useCallback } from "react"
import { Badge, Button, Card, Col, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import { nombreDNIPorPais } from "../../lib/helpers/data/internationa";
import CamposObligatoriosLeyenda from "../general/campos_obligatorios";
import { formatoMoneda } from "../../lib/helpers/main";
import { PiWarehouse } from "react-icons/pi";
import { debounce } from "@mui/material";
import ModalProductos from "../productos/modal_productos";
import { fechaATexto } from "../../lib/helpers/helpers";
import SelectorBodega from "../bodega/bodegas/selector_bodega";
import BuscadorBodega from "../bodega/bodegas/buscador";
import SelectorBodegaSimple from "../bodega/bodegas/selector_simple";
import FloatingProgressBar from "../general/floatingProgressBar";
// import DestinatariosBodegasListado from "./destinatarios_bodegas/list";
// import DestinatariosContactosListado from "./destinatarios_contactos/list";

const DetailFull = (props) => {
    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [ loadingInventario, setLoadingInventario ] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({})
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const [codigoValido, setCodigoValido] = useState(false)
    const [estadoCodigo, setEstadoCodigo] = useState('')
    const [ confirmed, setConfirmed ] = useState(false)
    const [loadingEstadoCodigo, setLoadingEstadoCodigo] = useState(false)
    const cm = <Badge variant="dark" >cm</Badge>

    const dispatch = useDispatch()

    useEffect(() => {
        if(proveedor){
            if(proveedor.status === "confirmed") setConfirmed(true)
            setNuevoProveedor({...{}, ...proveedor})
        }
    }, [ proveedor])

    const handleChange = (e) => {
        const { name, value } = e.target
        proveedor[name] = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {

        const requeridos = [
            { value:'tipo', label: 'Tipo'},
            { value:'warehouse', label: 'Bodega'},
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value]) faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: "${faltantes.join(', ')}"`)

        if(!nuevoProveedor.productos) return toast.error('Debes agregar productos')
        if(nuevoProveedor.productos.length === 0) return toast.error('Debes agregar productos')
        
        setLoading(true)
        return fetch(`${data.urlapi}/inventarios`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              
              return setLoading(false)
          })
          .catch(error => {
            toast.error("No se pudo crear el producto es probable que un codigo item ya exista, revisalo")
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        const instancia_proveedor = {...proveedor}
        setLoading(true)
        return fetch(`${data.urlapi}/inventarios`, {
            method: 'PUT',
            body: JSON.stringify(instancia_proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.success){
                if(instancia_proveedor.status === "confirmed") setConfirmed(true)
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Guardado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro, No deben haber letras en campos numericos, ni campos vacios.')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
        nuevoProveedor[name] = value
        return setNuevoProveedor(nuevoProveedor)
    }

    const mostrarTextoEstadoCodigo = () => {
        if (codigoValido === true) return <p style={{ fontSize: 12, fontWeight: 'normal', marginBottom: 10 }} className='text-success'><i className="fa-solid fa-circle-check"></i>Codigo Item * {estadoCodigo}</p>
        if (estadoCodigo) return <p style={{ fontSize: 12, fontWeight: 'normal', marginBottom: 10 }} className='text-danger'><i className="fa-solid fa-triangle-exclamation"></i>Codigo Item * {estadoCodigo}</p>
        return <label className='form-control-label d-block'>Codigo Item *</label>
    }

    const mostrarEstadoCodigo = () => {
        if (loadingEstadoCodigo === true) return <p style={{ position: 'absolute', right: 30, bottom: 13, fontSize: 10 }}><Spinner size="sm" animation='border' /></p>
        return false
    }

    const validarReferencia = async () => {
        if(nuevoProveedor.codigo_item === ""){
            setCodigoValido(false)
            return setEstadoCodigo('Campo vacio')
        }
        if (!nuevoProveedor.codigo_item) {
            setCodigoValido(false)
            return setEstadoCodigo('Codigo inválido')
        }
        setLoadingEstadoCodigo(true)
        return fetch(`${data.urlapi}/productos/validar/codigo?codigo=${nuevoProveedor.codigo_item}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                console.log(res)
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingEstadoCodigo(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingEstadoCodigo(false)
                } else if (typeof res === 'object') {
                    setCodigoValido(res.valido === true ? true : false)
                    setEstadoCodigo(res.valido === true ? 'Válido' : 'Inválido')
                }
                return setLoadingEstadoCodigo(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingEstadoCodigo(false)
            })
    }

    const validarReferenciaEditar = async () => {
        if(proveedor.codigo_item === ""){
            setCodigoValido(false)
            return setEstadoCodigo('Campo vacio')
        }
        if (!proveedor.codigo_item) {
            setCodigoValido(false)
            return setEstadoCodigo('Codigo inválido')
        }
        setLoadingEstadoCodigo(true)
        return fetch(`${data.urlapi}/productos/validar/codigo/editar?codigo=${proveedor.codigo_item}&id=${proveedor._id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
            .then(res => {
                if (res.status === 401) return dispatch(cerrarSesion())
                return res.json()
            })
            .then(res => {
                console.log(res)
                if (!res) {
                    toast.error('Sin datos')
                    return setLoadingEstadoCodigo(false)
                } else if (res.errorMessage) {
                    toast.error(res.errorMessage)
                    return setLoadingEstadoCodigo(false)
                } else if (typeof res === 'object') {
                    setCodigoValido(res.valido === true ? true : false)
                    setEstadoCodigo(res.valido === true ? 'Válido' : 'Inválido')
                }
                return setLoadingEstadoCodigo(false)
            })
            .catch(error => {
                toast.error(`Error al consultar la información: ${error.message}`)
                return setLoadingEstadoCodigo(false)
            })
    }

    const handleChangeOrden = (e) => {
        const { name, value } = e.target
        return setNuevoProveedor(prev => {
            let actual = {...prev}
            actual[name] = value
            return {...{}, ...actual}
        })
    }

    const handleChangePro = (e, i,setFunction) => {
        const { name, value } = e.target
        setFunction(prev => {
            let actual = {...prev}
            actual.productos[i][name] = value
            return {...{}, ...actual}
        })
    }

    const remover = (i, setFunction) => {
        setFunction(prev => {
            let actual = {...prev}
            actual.productos.splice(i, 1)
            return {...{}, ...actual}
        })
    }

    const mostrarProductos = (prov, setFunction, disable) => {
        if(!prov.productos) return false
        return <div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Descripción</th>
                        <th>Código Item</th>
                        <th>Cantidad</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        prov.productos.map((prod, i) => {
                            return <tr key={prod._id}>
                                    <td>{prod.description}<br/>
                                    { !disable ? <Button variant="outline-danger" size="sm" style={{ fontSize: 11 }} onClick={() => remover(i,setFunction)} >REMOVER</Button> : false }
                                    </td>
                                    <td>{prod.codigo_item}</td>
                                    <td><input disabled={disable === true} className="form-control" value={prod.cantidad} name="cantidad" type="number" onChange={(e) => handleChangePro(e, i, setFunction)} /></td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    }

    const agregarProducto = (pro, setFunction) => {
        setFunction(prev => {
            let actual = {...prev}
            if(!actual.productos) actual.productos = []
            const i = actual.productos.findIndex(p => p._id === pro._id)
            if(i >= 0){
                toast.error('Producto ya agregado')
            } else {
                actual.productos.push({ ...pro, cantidad: 1 })
            }
            return {...{}, ...actual}
        })
    }

    const formularioNuevo = () => {

        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear documento de inventario</h4>
                    {/* <p>Los productos forman parte de tu empresa, están relacionados directamente a la rentabilización de la misma en esta plataforma.</p> */}
                    {/* <CamposObligatoriosLeyenda /> */}
                    <Row>
                        <Col>
                        <label className="form-control-label d-block">Estado</label>
                        <select className="form-control mb-3" name="status" defaultValue={nuevoProveedor.status} onChange={handleChangeNuevo} >
                            <option value="pending">Pendiente de confirmación</option>
                            <option value="confirmed">Confirmado</option>
                        </select>
                        </Col>
                        <Col>
                        <label className="form-control-label d-block">Tipo de documento</label>
                        <select className="form-control mb-3" name="tipo" defaultValue={nuevoProveedor.tipo} onChange={handleChangeNuevo} >
                            <option value="">Seleccione</option>
                            <option value="in">Ingreso de inventario</option>
                            <option value="out">Salida de inventario</option>
                        </select>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <label className="form-control-label d-block">Bodega</label>
                            <SelectorBodegaSimple onChange={(data) => handleChangeNuevo({ target: { name: "warehouse", value: data?.value ? data?.value : "" } })} />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12}>
                <ModalProductos title="AGREGAR PRODUCTO" onSelected={(pro) => agregarProducto(pro, setNuevoProveedor)} />
                {/* <Button size='sm' variant='secondary' className='shadow-sm mb-3 mt-3 w-25' onClick={() => agregarProducto()}><b><i className="fa-solid fa-plus"></i> AGREGAR PRODUCTO</b></Button>  */}
                {mostrarProductos(nuevoProveedor, setNuevoProveedor, confirmed)}
                </Col>
                
                {/* <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Código Item</label>
                    <input className="form-control" name="codigo_item" value={nuevoProveedor.codigo_item} onChange={handleChangeNuevo} />
                </Col> */}
                {/* <Col md={3} style={{fontWeight:500}}>
                        {mostrarTextoEstadoCodigo()}
                        {mostrarEstadoCodigo()}
                        <input className='form-control mb-3 shadow-sm' autoComplete='off' style={{ paddingRight: 10 }} name="codigo_item" onBlur={() => setTimeout(() => {
                            return validarReferencia()
                        }, 500)} onChange={handleChangeOrden} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Peso <Badge variant="dark" >Kg</Badge> </label>
                    <input className="form-control" name="peso" type="number" defaultValue={nuevoProveedor.peso} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Alto {cm}</label>
                    <input className="form-control" name="alto" type="number" defaultValue={nuevoProveedor.alto} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Ancho {cm}</label>
                    <input className="form-control" name="ancho" type="number" defaultValue={nuevoProveedor.ancho} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Largo {cm}</label>
                    <input className="form-control" name="largo" type="number" defaultValue={nuevoProveedor.largo} onChange={handleChangeNuevo} />
                </Col>
                <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Precio</label>
                    <input className="form-control" name="price" type="number" defaultValue={nuevoProveedor.price} onChange={handleChangeNuevo} />
                </Col> */}
                <Col md={12}>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
                </Col>
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/productos?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            toast.success(`Eliminado exitosamente`)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        return confirmAlert({
            title: `¿Estás seguro?`,
            message: `Confirma que deseas eliminar definitivamente este registro, esta acción no se puede deshacer`,
            buttons: [
              {
                label: 'CONFIRMAR',
                onClick: () => confirmarEliminado(id)
              },
              {
                label: 'CANCELAR',
                onClick: () => false
              }
            ]
          })
    }

    const actualizarInventario = async (payload) => {
        setLoadingInventario(true)
        return fetch(`${data.urlapi}/productos/inventario`,{
            method:'PUT',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return window.location = '/login'
            return res.json()
        })
        .then(res => {
            if(!res){
            } else if(res.errorMessage){
            } else if(res._id){
                setLoadingInventario(false)
                return true
            }
            return setLoadingInventario(false)
        })
        .catch(error => setLoadingInventario(false))
    }

    const actualizar = useCallback(debounce((data) => actualizarInventario(data), 500), []);

    const handleChangeInventario = (e, i) => {
        const { value } = e.target
        const inv = { ...proveedor.inventarios[i] }
        const objeto = {
            id_bodega: inv._id,
            id_producto: proveedor._id,
            cantidad: parseInt(value)
        }
        if(isNaN( parseInt(objeto.cantidad) )) return
        actualizar(objeto)
    }

    const mostrarInventarios = () => {
        if(!proveedor.inventarios) return false
        if(!Array.isArray(proveedor.inventarios)) return false

        return <div>
            <h4 className='mb-3'><PiWarehouse /> Inventarios</h4>
            { loadingInventario ? <p><Spinner animation="border" size="sm" /> Guardando cambios de inventario </p> : <p className="text-success">Inventarios guardados</p> }
            <Row>
                {
                    proveedor.inventarios.map((inv,i) => {

                        return <Col md={4} key={`inv-${i}`} className='mb-3'>
                            <Card>
                                <Card.Body>
                                    <h5>{inv.titulo}</h5>
                                    <p className='mb-0'><strong>Stock</strong></p>
                                    <input className='form-control' disabled={loadingInventario} type="number" name="stock" defaultValue={inv.stock ? formatoMoneda(inv.stock) : 0} onChange={(e) => handleChangeInventario(e,i)} />
                                    {/* <p className='mb-0'><strong>Stock mínimo:</strong> {inv.stock_minimo}</p> */}
                                    {/* <p className='mb-0'><strong>Stock máximo:</strong> {inv.stock_maximo}</p> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    })
                }
            </Row>
        </div>
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <div>
            <h4 className="mb-0">Detalles del producto <b>{proveedor.description}</b></h4>
            <Button variant="link" disabled={removiendo} className="text-danger p-0 d-block mb-3" onClick={()=>confirmarEliminado(proveedor._id)} >{ removiendo ? <Spinner animation="border" /> : "Eliminar"}</Button>
            <Tabs defaultActiveKey="editar" >
            <Tab eventKey="editar" className="mt-4" title="Ficha informativa ">
                <Row>
        <Col md={12} className="mb-3">
                <h3 className="mb-0">Informe de inventario {proveedor.id}</h3>
                <Badge className="mb-3" variant="dark">Por {proveedor.creador?.nombres} el {fechaATexto(proveedor.createdAt)} </Badge>
                <Row>
                        <Col>
                        <label className="form-control-label d-block">Estado</label>
                        <select className="form-control mb-3" name="status" defaultValue={proveedor.status} onChange={handleChange} >
                            <option value="pending">Pendiente de confirmación</option>
                            <option value="confirmed">Confirmado</option>
                        </select>
                        </Col>
                        <Col>
                        <label className="form-control-label d-block">Tipo de documento</label>
                        <select className="form-control mb-3" name="tipo" defaultValue={proveedor.tipo} disabled >
                            <option value="">Seleccione</option>
                            <option value="in">Ingreso de inventario</option>
                            <option value="out">Salida de inventario</option>
                        </select>
                        </Col>
                    </Row>
                    <div className="mb-3">
                        <label className="form-control-label d-block">Bodega</label>
                        <SelectorBodegaSimple defaultValue={proveedor.warehouse} onChange={(data) => handleChangeNuevo({ target: { name: "warehouse", value: data?.value ? data?.value : "" } })} />
                    </div>
                {
                    proveedor.status !== "confirmed" ? <div>
                        <ModalProductos title="AGREGAR PRODUCTO" onSelected={(pro) => agregarProducto(pro, setProveedor)} />
                        
                    </div> : <h4>Este informe ya no se puede modificar, fue confirmado</h4>
                }
                {mostrarProductos(proveedor, setProveedor, proveedor.status === "confirmed")}

        </Col>
        {/* <Col md={4} style={{fontWeight:500}}>
                        {mostrarTextoEstadoCodigo()}
                        {mostrarEstadoCodigo()}
                        <input className='form-control mb-3 shadow-sm' autoComplete='off' style={{ paddingRight: 10 }} name="codigo_item" defaultValue={proveedor.codigo_item} onBlur={() => setTimeout(() => {
                            return validarReferenciaEditar()
                        }, 500)} onChange={handleChange} />
                </Col> */}
        {/* <Col md={3} className="mb-3">
        <label className="form-control-label d-block">Código Item</label>
            <input className="form-control" name="codigo_item" defaultValue={proveedor.codigo_item}  onChange={handleChange}/>
                </Col> */}
                
        <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button disabled={confirmed} size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
        </Row>
            </Tab>
           {/* <Tab eventKey="bodegas" className="mt-4" title="Bodegas" >
                <DestinatariosBodegasListado id_cliente={proveedor._id} condicion_default={{ id_cliente: proveedor._id }} />
            </Tab>
            <Tab eventKey="contactos" className="mt-4" title="Contactos" >
                <DestinatariosContactosListado id_cliente={proveedor._id} condicion_default={{ id_cliente: proveedor._id }} />
            </Tab> */}
        </Tabs>
        </div>
    }

    const isGuardando = loading || loadingInventario
    
    return <div>
        <FloatingProgressBar isVisible={isGuardando} />
        {View()}
    </div>
}

export default DetailFull