import { useEffect, useState } from 'react'
import { Row, Col, Modal, Card, Badge, Button } from 'react-bootstrap'
import { urlapi } from '../../lib/backend/data'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from 'react-redux';
import { cerrarSesion } from '../../redux/actions/session';
import { IoMailUnreadOutline } from "react-icons/io5";
import { fechaATexto } from '../../lib/helpers/helpers';
import { FaRegClock } from 'react-icons/fa';
import ErrorBoundary from '../errorsHandler/errorBoundary';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { FaWhatsapp } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import { createTheme, StepContent, ThemeProvider, Typography } from '@mui/material';

const Trazabilidad = (props) => {
    const {
        id_orden,
        condicion_default
    } = props
 
    const dispatch = useDispatch()
    const [ error, setError ] = useState(null)
    const [ proveedores, setProveedores ] = useState([])
    const [ loadingProveedores, setLoadingProveedores ] = useState(true)
    const [ notificacionSeleccionada, setNotificacionSeleccionada ] = useState(false)
    const [ loadingDetalle, setLoadingDetalle ] = useState(false)
    const condicion_busqueda_ = {id_source: id_orden}
    const [ condicion_busqueda, setCondicionBusqueda ] = useState(condicion_default ? condicion_default : { ...condicion_busqueda_ })
    const session = useSelector(state => state.miusuario)
    const token = session.tokenSession
    const [showModal, setShowModal] = useState(false);
    const theme = createTheme({})

    const obtenerProveedores = async ()=>{
        setLoadingProveedores(true)

        return fetch(`${urlapi}/ordenes/trazabilidad?id=${id_orden}`,{
            method:'GET',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                setError('Sin datos')
                return setLoadingProveedores(false)
            } else if(res.errorMessage){
                setError(res.errorMessage)
                return setLoadingProveedores(false)
            }
            if(Array.isArray(res) !== false){
                setProveedores(res)
            }
            return setLoadingProveedores(false)
        })
        .catch(error => {
            setError("Error al consultar la información, intente nuevamente")
            return setLoadingProveedores(false)
        })
    }
      

    const obtenerNotificacionPorId = async (data) => {
        setNotificacionSeleccionada(data)
        handleShowModal()
        return
    }

    const handleShowModal = () => setShowModal(true)

    const handleCloseModal = () => setShowModal(false);

    const mostrarSegunTipo = () => {
        if(notificacionSeleccionada?.destination_type === "whatsapp") {
              return <div>
                       <div className="mb-3">
                        <Card className={`p-3 mb-3 w-80 ml-auto shadow-sm`} >                                
                            <Row>
                                  <Col><p className='m-0' style={{ fontSize: 11, textTransform: "uppercase" }}><FaRegClock /> {fechaATexto(notificacionSeleccionada?.createdAt)}</p></Col>
                                  <Col className='text-right'><p className='m-0' style={{ fontSize: 11, textTransform: "uppercase" }}>ENVIADO POR SISTEMA</p></Col>
                            </Row>
                            <hr/>
                              <p className='mb-0' style={{fontWeight:700}}>{notificacionSeleccionada?.content}</p>
                       </Card>
                              </div>
                     </div>
        }

        if(notificacionSeleccionada?.destination_type === "email") {
            return       <div
            dangerouslySetInnerHTML={{ __html: notificacionSeleccionada?.content }}
            className=""
          />
        }
    }


    const modalNotificacion = () => {

        return <Modal show={showModal} size="lg" onHide={()=>handleCloseModal()}
        centered
        >
        <Modal.Header closeButton>
          <Modal.Title>Contenido de la notificación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                 {mostrarSegunTipo()}
        </Modal.Body>
        
      </Modal>
    }


    useEffect(() => {
        obtenerProveedores()
    }, []) 

 
    return <div className='text-primary'>
        <ErrorBoundary message={error} />

        <Row className='mb-2'>
            <Col md={12}>
            <h3 className='pt-2'>Trazabilidad </h3>
            <hr className='mt-0'/>
            </Col>
            {
                proveedores.length === 0 ? <Col md={12} ><h5> Aún no hay trazabilidad para este recurso</h5></Col> : false
            }
            <Col md={12} className='d-flex align-items-center justify-content-center'>
            <Box sx={{ width: '100%' }}>
            <ThemeProvider theme={theme}>
            <Stepper activeStep={proveedores.length} orientation='horizontal'>
                {proveedores.map((label, index) => {
                
                    let tipo_envio = ''
                    if(label.destination_type === "whatsapp") tipo_envio = <FaWhatsapp size={30} style={{ color: "#25D366"}} />
                    if(label.destination_type === "email") tipo_envio = <IoMailOutline size={30} style={{ color: "#fa4236"}} />

                    
                return (
                    <Step key={label}>
                    <StepLabel icon={tipo_envio} className='hover' >
                        <p className='mb-0 pb-0' style={{ fontSize: 10 }} ><b>{fechaATexto(label?.createdAt)}</b></p>
                        <h5  style={{ fontSize: 18 }} className='mt-0 mb-0 pt-0'><b>{label?.nombre}</b></h5>
                        
                    </StepLabel>
                    {/* <StepContent><Button size="sm" style={{ fontSize: 10 }} variant="light" className='mt-2 d-block' >DETALLES</Button></StepContent> */}
                    </Step>
                );
                })}
            </Stepper>
            </ThemeProvider>
    </Box>                                                                        
    </Col>

     </Row>
            {modalNotificacion()}
    </div>

}

export default Trazabilidad