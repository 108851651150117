import { useState } from "react"

const { Button, Modal } = require("react-bootstrap")

const ModalPersonalizado = (props) => {
    const {
        component,
        title,
        customComponent,
        variantButton
    } = props
    const [ showModal, setShowModal ] = useState(false)
    const titulo = title ? title : "MÁS DATOS"
    const variant = variantButton ? variantButton : "light"

    const accion = () => {
        if(customComponent) return <div className="hover" onClick={() => setShowModal(true)}>{customComponent}</div>
        return <Button size="sm" variant={variant} className="w-100" style={{ fontSize: 12 }} onClick={() => setShowModal(true)}>{titulo}</Button>
    }

    return <div>
        {accion()}
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{titulo}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {component}
            </Modal.Body>
        </Modal>
    </div>
}

export default ModalPersonalizado