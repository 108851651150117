import JsonView from "@uiw/react-json-view";
import { Accordion, Card } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";

const ErrorBoundary = (props) => {
    const {
        message,
        jsonData
    } = props

    const mostrarJsonData = () => {
        if(!jsonData) return null
        return <div>
            <Accordion>
            <Card className="p-0 mx-1 shadow-sm text-left">
                        <Accordion.Toggle className="hover" as={Card.Header} style={{ padding: 8, fontSize: 12, backgroundColor:'rgba(27, 54, 101, 0.1)' }} eventKey={`details`}>
                        <p className="mb-0"><b>Más detalles del error</b> <FaChevronDown /></p>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={`details`}>
                        <Card.Body className='text-primary' style={{fontWeight:700}}>
                        <JsonView value={jsonData} />
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>
            </Accordion>
        </div>
    }

    if (!message) {
        return null;
    }

    return <>
        <div 
        className='px-2 py-0 mb-2'
        style={{ 
            color: 'red', 
            fontSize: '13px', 
            backgroundColor: '#f8d7da', 
            border: '1px solid #f5c6cb', 
            borderRadius: '5px',
        }}>
        {message}
        </div>
        <div>
        {mostrarJsonData()}
        </div>
    </>;
    
};

export default ErrorBoundary;