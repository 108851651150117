import { useState } from "react"
import { Button, Col, OverlayTrigger, Row, Spinner, Tab, Tabs, Tooltip } from "react-bootstrap"
import data from '../../lib/backend/data'
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useDispatch, useSelector } from "react-redux";
import { cerrarSesion } from "../../redux/actions/session";
import { nombreDNIPorPais } from "../../lib/helpers/data/internationa";
import CamposObligatoriosLeyenda from "../general/campos_obligatorios";
import ConfiguracionNotificacionesRecurso from "../notificaciones/configuracion_notificacones_recurso";

const DetailFull = (props) => {
    const { 
        habilitar_eliminar 
    } = props

    const [proveedor, setProveedor] = useState(props.proveedor ? props.proveedor : false)
    const [loading, setLoading] = useState(false)
    const [removiendo, setRemoviendo] = useState(false)
    const [nuevoProveedor, setNuevoProveedor] = useState({tipo: "logistica", sub_tipo: "cajas", detalles: {}})
    const session = useSelector(state => state.miusuario)
    const pais = useSelector(state => state.pais)
    const token = session.tokenSession
    const [crear, setCrear] = useState(props.crear ? props.crear : false)
    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value } = e.target
        if(name === "ancho") proveedor.detalles.ancho = parseInt(value)
        if(name === "alto") proveedor.detalles.alto = parseInt(value)
        if(name === "largo") proveedor.detalles.largo = parseInt(value)
        if(name === "peso") proveedor.detalles.peso = parseInt(value)
        if(name === "valor") proveedor.valor = value
        return setProveedor(proveedor)
    }

    const crearNuevo = async () => {
        const requeridos = [
            { value: 'valor', label: 'Nombre'}
        ]
        const requeridos_detalles = [
            { value: 'ancho', label: 'Ancho' },
            { value: 'alto', label: 'Alto' },
            { value: 'largo', label: 'Largo' },
            { value: 'peso', label: 'Peso' },
        ]
        let faltantes = []
        requeridos.map(campo => {
            if(!nuevoProveedor[campo.value])  faltantes.push(campo.label)
                return true
            })
        requeridos_detalles.map(campo => {
            if(!nuevoProveedor.detalles[campo.value])  faltantes.push(campo.label)
            return true
        })
        if(faltantes.length > 0) return toast.error(`Faltan campos: ${faltantes.join(', ')}`)
        setLoading(true)
        return fetch(`${data.urlapi}/configuracion/cajas`, {
            method: 'POST',
            body: JSON.stringify(nuevoProveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                    toast.error('Hubo un error de servidor', this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                    toast.error(pros.errorMessage, this.state.toaststyle)
                    return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                    if(props.onProveedorNuevo) props.onProveedorNuevo(pros)
                    toast.success(`Realizado exitosamente`)
                    setNuevoProveedor({...{}, ...{}})
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const guardarCambios = async () => {
        setLoading(true)
        return fetch(`${data.urlapi}/configuracion/cajas`, {
            method: 'PUT',
            body: JSON.stringify(proveedor),
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
          })
          .then(pros => pros.json())
          .then(async pros => {
              if(!pros){
                toast.error('Hubo un error de servidor', this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros.errorMessage){
                toast.error(pros.errorMessage, this.state.toaststyle)
                return this.setState({ loadingFormulario: false })
              } else if(pros._id){
                  if(props.onProveedorGuardado) props.onProveedorGuardado(proveedor)
                  toast.success(`Realizado exitosamente`)
              }
              return setLoading(false)
          })
          .catch(async error => {
                toast.error('No se pudo actualizar el registro')
                return setLoading(false)
          })
    }

    const handleChangeNuevo = (e) => {
        const { name, value } = e.target
            if(name === "ancho") nuevoProveedor.detalles.ancho = parseInt(value)
            if(name === "alto") nuevoProveedor.detalles.alto = parseInt(value)
            if(name === "largo") nuevoProveedor.detalles.largo = parseInt(value)
            if(name === "peso") nuevoProveedor.detalles.peso = parseInt(value)
            if(name === "valor") nuevoProveedor.valor = value
            return setNuevoProveedor(nuevoProveedor)
    }

    const formularioNuevo = () => {
        return <div>
            <Row>
                <Col md={12}>
                    <h4>Crear nueva caja</h4>
                    <p>Crea cajas personalizadas con las medidas de tu preferencia.</p>
                    <CamposObligatoriosLeyenda />
                </Col>
                <Col md={12} className="mb-3">
            <label className="form-control-label d-block">Nombre*</label>
            <input className="form-control" name="valor" value={nuevoProveedor.valor} onChange={handleChangeNuevo} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Ancho*</label>
                    <input className="form-control" type="number" name="ancho" value={nuevoProveedor.detalles?.ancho} onChange={handleChangeNuevo} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Alto*</label>
                    <input className="form-control" type="number" name="alto" value={nuevoProveedor.detalles?.alto} onChange={handleChangeNuevo} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Largo*</label>
                    <input className="form-control" type="number" name="largo" value={nuevoProveedor.detalles?.largo} onChange={handleChangeNuevo} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Peso*</label>
                    <input className="form-control" type="number" name="peso" value={nuevoProveedor.detalles?.peso} onChange={handleChangeNuevo} />
        </Col>
                    {
                        loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>crearNuevo()} >CREAR NUEVO</Button>
                    }
            </Row>
        </div>
    }

    const confirmarEliminado = async (id) => {
        setRemoviendo(true)
        return fetch(`${data.urlapi}/configuracion?id=${id}`,{
            method:'DELETE',
            headers: {
                'Content-Type':'application/json',
                'Authorization': `Bearer: ${token}`
            }
        })
        .then(res => {
            if(res.status === 401) return dispatch(cerrarSesion())
            return res.json()
        })
        .then(res => {
            console.log(res)
            if(!res){
                toast.error('Sin datos')
                return setRemoviendo(false)
            } else if(res.errorMessage){
                toast.error(res.errorMessage)
                return setRemoviendo(false)
            } else if(res._id){
                if(props.onFieldDeleted) props.onFieldDeleted(res._id)
            }
            setProveedor(false)
            setCrear(true)
            return setRemoviendo(false)
        })
        .catch(error => {
            toast.error("Error al consultar la información, intente nuevamente")
            return setRemoviendo(false)
        })
    }

    const solicitarEliminar = (id) => {
        confirmarEliminado(id)
    }

    const View = () => {
        if(!proveedor) return formularioNuevo()
        return <div>
            <h4 className="mb-2">Detalles de la caja <b>{proveedor.razon_social}</b></h4>
            {
                habilitar_eliminar ? <>
                {
                removiendo === true ? <Spinner animation="border" /> : <Button variant="link" className="text-danger p-0 d-block mb-3" onClick={()=>solicitarEliminar(proveedor._id)} >Eliminar</Button>
            }
                </> : false
            }
            
                <Row>
        <Col md={12} className="mb-3">
            <label className="form-control-label d-block">Nombre</label>
            <input className="form-control" name="valor" defaultValue={proveedor.valor} onChange={handleChange} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Ancho</label>
                    <input className="form-control" type="number" name="ancho" defaultValue={proveedor.detalles?.ancho} onChange={handleChange} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Alto</label>
                    <input className="form-control" type="number" name="alto" defaultValue={proveedor.detalles?.alto} onChange={handleChange} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Largo</label>
                    <input className="form-control" type="number" name="largo" defaultValue={proveedor.detalles?.largo} onChange={handleChange} />
        </Col>
        <Col md={3} className="mb-3">
                    <label className="form-control-label d-block">Peso</label>
                    <input className="form-control" type="number" name="peso" defaultValue={proveedor.detalles?.peso} onChange={handleChange} />
        </Col>
        <Col md={12}>
            {
                loading === true ? <Spinner animation="border" /> : <Button size="sm" variant="success" onClick={()=>guardarCambios()} >GUARDAR CAMBIOS</Button>
            }
        </Col>
        </Row>
        </div>
    }

    return <div>
        {View()}
    </div>
}

export default DetailFull